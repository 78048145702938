import truncate from 'lodash/truncate';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { Store, StoreSpecial } from '@jane/shared/models';
import { Card, Flex, Typography } from '@jane/shared/reefer';
import { getDiscountLabel, getSpecialType } from '@jane/shared/util';

import { JumboDiscountBadge } from './jumboDiscountBadge/jumboDiscountBadge';

interface Props {
  special: StoreSpecial;
  store: Pick<Store, 'store_compliance_language_settings'>;
  titleLimit?: number;
}
export const StoreSpecialCard = ({
  special,
  store,
  titleLimit = 37,
}: Props) => {
  const customBadgeEnabled = useFlag(FLAGS.scSpecialsCustomBadge);
  return (
    <Card
      ariaLabel={special.title}
      key={special.id}
      width="315px"
      border="grays-light"
      height="240px"
    >
      {/* TODO: Should be a conditionalWrapper link if we want to use it on the original specialsRow.tsx */}
      <div>
        <div
          style={{
            height: '160px',
          }}
        >
          {special.photo ? (
            <Card.Image
              height="160px"
              alt={special.title}
              src={special.photo.urls.original || ''}
            />
          ) : (
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="center"
              width="100%"
            >
              <JumboDiscountBadge color="info" textColor="text-info">
                {customBadgeEnabled && special.custom_badge
                  ? special.custom_badge
                  : getDiscountLabel(special, store)}
              </JumboDiscountBadge>
            </Flex>
          )}
        </div>
        <Card.Content>
          <Flex
            alignItems="left"
            flexDirection="column"
            height="100%"
            justifyContent="left"
            width="100%"
          >
            <Typography color="info" variant="mini-bold">
              {' '}
              {getSpecialType(special.special_type, store)}{' '}
            </Typography>
            <Typography variant="body-bold">
              {truncate(special.title, { length: titleLimit })}
            </Typography>
          </Flex>
        </Card.Content>
      </div>
    </Card>
  );
};
