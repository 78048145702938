import { useContext, useMemo, useState } from 'react';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { KioskV2, StoreV2 } from '@jane/business-admin/types';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Button, Card, Flex, Grid, Typography } from '@jane/shared/reefer';
import { duration } from '@jane/shared/util';

import { CardSection } from '../../../../../CardSection';
import { EditableCard } from '../../../../../EditableCard';

const getKioskUrl = (store: StoreV2) => {
  const root = document.location.origin.includes('localhost')
    ? 'http://localhost:4200'
    : document.location.origin.replace('business-v2', 'kiosk');
  return `${root}/stores/${store.id}/landing`;
};

const KioskURL = ({ store }: { store: StoreV2 }) => {
  const [copied, setCopied] = useState(false);

  const kioskUrl = useMemo(() => {
    return getKioskUrl(store);
  }, [store]);

  // TODO: Do we want some sort of confirmation that the text has been copied?
  const copyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <>
      <Typography mb={12} color="grays-mid" variant="caps">
        Kiosk URL
      </Typography>
      <Card mb={24}>
        <Card.Content background="grays-light">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex flexDirection="column" p={12}>
              <Typography>{kioskUrl}</Typography>
            </Flex>
            <Flex>
              <Button
                full
                variant="tertiary"
                label={copied ? 'Copied' : 'Copy'}
                onClick={() => copyUrl(kioskUrl)}
              />
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </>
  );
};

export const KioskCard = ({
  kioskSettings,
  store,
  openKioskModal,
}: {
  kioskSettings: KioskV2;
  openKioskModal: () => void;
  store: StoreV2;
}) => {
  const showAccount = useFlag(FLAGS.goldAchOnKiosk);

  const { canEditStore } = useContext(StoreDetailsContext);

  const allowAuth = store.kiosk_allow_auth;

  const formatTimeout = useMemo(() => {
    const combinedTimeouts = duration(
      (kioskSettings.inactivity_timeout_ms || 0) +
        (kioskSettings.refresh_to_landing_timeout_ms || 0),
      'milliseconds'
    );
    const minutes = combinedTimeouts.minutes();
    const minutesText =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
    const seconds = combinedTimeouts.seconds();
    const secondsText =
      seconds > 0 ? `${seconds} second${seconds > 1 ? 's' : ''} ` : '';
    return (
      minutesText + `${minutes > 0 && seconds > 0 ? ' and ' : ''}` + secondsText
    );
  }, [
    kioskSettings.inactivity_timeout_ms,
    kioskSettings.refresh_to_landing_timeout_ms,
  ]);

  const formatCheckoutSettings = useMemo(() => {
    const options = [];
    if (kioskSettings.show_checkout_message_field) {
      options.push('Allow customers to leave an optional note');
    }
    if (store.kiosk_anonymous_checkout === 'allowed') {
      options.push('Allow customers to checkout anonymously');
    } else if (store.kiosk_anonymous_checkout === 'required') {
      options.push('Require customers to checkout anonymously');
    }
    return options;
  }, [kioskSettings, store]);

  const formatAppearance = useMemo(() => {
    if (kioskSettings.skip_landing_page) {
      return 'Menu';
    } else if (!kioskSettings.skip_landing_page) {
      return 'Landing page';
    }
    return '';
  }, [kioskSettings.skip_landing_page]);

  return (
    <EditableCard
      title="Kiosk"
      onEdit={openKioskModal}
      disabled={!canEditStore}
    >
      <KioskURL store={store} />
      <Grid.Container>
        <Grid.Item xs={4}>
          <CardSection label="Starting screen">{formatAppearance}</CardSection>
        </Grid.Item>
        <Grid.Item xs={4}>
          <CardSection label="Checkout">
            {formatCheckoutSettings.length
              ? formatCheckoutSettings.map((setting, index) => (
                  <Typography key={index}>{setting}</Typography>
                ))
              : 'N/A'}
          </CardSection>
        </Grid.Item>
        <Grid.Item xs={4} style={{ display: showAccount ? 'flex' : 'none' }}>
          <CardSection label="Account">
            {allowAuth ? 'Allow' : 'Do not allow'} customers to sign in and sign
            up
          </CardSection>
        </Grid.Item>
        <Grid.Item xs={4}>
          <CardSection label="Timeout">{formatTimeout || 'None'}</CardSection>
        </Grid.Item>
      </Grid.Container>
    </EditableCard>
  );
};
