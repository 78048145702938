import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginBanner } from '@jane/business-admin/components';
import { useResetPassword } from '@jane/business-admin/data-access';
import type { ResetPasswordData } from '@jane/business-admin/data-access';
import { parseValidationErrors } from '@jane/business-admin/util';
import { Card, Flex, JaneLogo, Link, Typography } from '@jane/shared/reefer';
import { Form, FormValidationError } from '@jane/shared/reefer-hook-form';
import { trackError } from '@jane/shared/util';

const FORM_ERROR_NAME = 'reset-password';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { mutateAsync: resetPassword, isSuccess, isError } = useResetPassword();
  const [basePass, setBasePass] = useState('');

  useEffect(() => {
    if (isSuccess) {
      setBasePass('');
      navigate({
        pathname: '/login',
        search: '?passwordReset=true',
      });
    }
  }, [isSuccess, navigate]);

  const handleValidationErrors = (errors: any) => {
    // Catch errors having to do with missing fields
    if (errors.error === 'Validation Error') {
      trackError('Business admin request validation error', errors.validations);
      throw new FormValidationError(
        FORM_ERROR_NAME,
        parseValidationErrors(errors.validations)
      );
    }
    // Catch errors having to do with incorrect current password etc.
    else {
      throw new FormValidationError(
        FORM_ERROR_NAME,
        parseValidationErrors(errors.validations)
      );
    }
  };

  const onSubmit = async (formData: ResetPasswordData) => {
    try {
      await resetPassword(formData);
    } catch (err: any) {
      if (err.message === 'Api Request Failed') {
        const response = err.response;
        if (response.status === 400 || response.status === 422) {
          const body = await response.json();
          if (
            body.errors?.error === 'The reset password link is no longer valid.'
          ) {
            trackError(
              'The password reset link is no longer valid. Please request a new link.'
            );
            throw new Error(
              'The password reset link is no longer valid. Please request a new link.'
            );
          }
          handleValidationErrors(body.errors);
        }
      }
      trackError('Error resetting password. Please try again.');
      throw new Error('Error resetting password. Please try again.');
    }
  };

  return (
    <Flex flexDirection="column" role="row">
      <Flex justifyContent="center">
        <Card mt={128}>
          <Card.Content background="grays-white">
            <Flex m={96} flexDirection="column" justifyContent="center">
              <Flex role="row" justifyContent="center">
                <JaneLogo size="lg" />
              </Flex>
              <Flex role="row" justifyContent="center" mt={-24} mb={40}>
                <Typography variant="caps-bold">BUSINESS</Typography>
              </Flex>
              <Form name="reset_password" onSubmit={onSubmit}>
                {isError ? (
                  <LoginBanner isError>
                    <Typography color="error" variant="body-bold">
                      The password reset link is no longer valid.{' '}
                      <Link
                        color="error"
                        onClick={() => {
                          navigate('/forgot-your-password');
                        }}
                      >
                        Please request a new link
                      </Link>
                    </Typography>
                  </LoginBanner>
                ) : null}
                <>
                  <Typography variant="body-bold">
                    Choose a new password
                  </Typography>
                  <Typography>
                    Your password will be updated for future logins.
                  </Typography>
                </>
                <Form.TextField
                  mt={20}
                  label="New Password"
                  name="password"
                  type="password"
                  required
                  width={532}
                  onChange={(val) => setBasePass(val)}
                />
                <Form.TextField
                  mt={20}
                  label="Confirm New Password"
                  name="password_confirmation"
                  type="password"
                  required
                  width={532}
                  validate={(val) =>
                    val !== basePass ? "Passwords don't match!" : true
                  }
                />
                <Flex role="row">
                  <Flex role="cell" width="50%" alignContent="left">
                    <Link
                      mt={20}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Return to login
                    </Link>
                  </Flex>
                  <Flex role="cell" width="50%" justifyContent="right">
                    <Form.SubmitButton label="Update my Password" mt={20} />
                  </Flex>
                </Flex>
              </Form>
            </Flex>
          </Card.Content>
        </Card>
      </Flex>
      <Flex pt={40} justifyContent="center">
        <Typography variant="body">
          Not a partner with Jane? Get in touch to get started.
        </Typography>
      </Flex>
      <Flex pt={12} justifyContent="center">
        <Link href="mailto:info@iheartjane.com">Contact us</Link>
      </Flex>
    </Flex>
  );
};
