import { useCallback, useContext, useEffect } from 'react';

import { useSaveStoreNotifications } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  CardNames,
  EventNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Card,
  Flex,
  Skeleton,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { SEND_EMAIL_UPDATES_FIELD } from './form';

const FORM_ERROR_NAME = 'notification-error';

const LoadingSkeleton = () => (
  <Skeleton animate direction="column" height="fit-content" width="100%">
    <Flex mt={32} flexDirection="row">
      <Skeleton.Bone width="56px" />

      <Flex flexDirection="column" width="100%" ml={16}>
        <Skeleton.Bone width="500px" height="14px" mb={4} />
        <Skeleton.Bone width="800px" height="14px" />
      </Flex>
    </Flex>
  </Skeleton>
);

interface Props {
  isFetched: boolean;
  sendEmailUpdates: boolean;
}

const EmailNotificationSettingsForm = ({
  sendEmailUpdates,
}: Pick<Props, 'sendEmailUpdates'>) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating notification settings. Please try again.'
  );

  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const {
    mutateAsync: saveNotifications,
    isSuccess: saveNotificationsSuccess,
  } = useSaveStoreNotifications(storeId);
  const formMethods = useForm({
    defaultValues: {
      [SEND_EMAIL_UPDATES_FIELD]: sendEmailUpdates,
    },
  });
  const { setValue } = formMethods;

  useEffect(() => {
    if (saveNotificationsSuccess) {
      toast.add({
        label: 'Email notification settings updated',
        variant: 'success',
      });
    }
  }, [saveNotificationsSuccess]);

  const onToggle = useCallback(
    (enabled: boolean) => {
      if (enabled === sendEmailUpdates) return;

      const requestData = {
        status_messages: [],
        dismissal_messages: [],
        send_cart_status_updates_via_email: enabled,
      };
      const submitMethod = () => {
        track({
          event: EventNames.EditedStoreSettings,
          card_name: CardNames.EmailNotifications,
          changed_attributes: ['send_cart_status_updates_via_email'],
        });

        return saveNotifications(requestData);
      };

      return catchSubmitErrors({
        submitMethod,
        requestData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            parseValidationErrors(validationErrors)
          );
        },
        callback: () => {
          setValue(SEND_EMAIL_UPDATES_FIELD, !enabled);
          toast.add({
            label: 'Error updating notification settings. Please try again.',
            variant: 'error',
          });
        },
      });
    },
    [sendEmailUpdates]
  );

  return (
    <Form.BaseForm
      name="notification-settings"
      formMethods={formMethods}
      onSubmit={() => null}
    >
      <Flex flexDirection="column" data-testid="email-settings" mt={32}>
        <Form.SwitchField
          label="Send additional notification via email"
          name={SEND_EMAIL_UPDATES_FIELD}
          defaultChecked={sendEmailUpdates}
          onChange={onToggle}
        />
        <Typography color="grays-mid" mb={40} ml={56}>
          Sometimes SMS messages don't always successfully reach the customer.
          Email notifications are a great backup to ensure your customers are
          receiving their order status updates. The email notifications will
          include the same exact message as your SMS.
        </Typography>
      </Flex>
    </Form.BaseForm>
  );
};

export const EmailNotificationSettingsCard = ({
  sendEmailUpdates,
  isFetched,
}: {
  isFetched: boolean;
  sendEmailUpdates: boolean;
}) => {
  return (
    <Card border="grays-light" flat mb={24}>
      <Card.Content
        background={
          !sendEmailUpdates && isFetched ? 'grays-ultralight' : undefined
        }
      >
        <Flex p={24} flexDirection="column">
          <Flex alignItems="center">
            <Typography variant="header-bold">Email notifications</Typography>
          </Flex>

          {isFetched ? (
            <EmailNotificationSettingsForm
              sendEmailUpdates={sendEmailUpdates}
            />
          ) : (
            <LoadingSkeleton />
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};
