import { Modal, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const PageContentSection = () => {
  return (
    <>
      <Typography variant="body-bold">Page Content</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to set customized content.
      </Typography>
      <Form.TextAreaField
        disableMobileInputStyling
        label="Page Head"
        mb={24}
        name="page_head"
        helperText="Any content, e.g. Javascript or CSS, that you want inserted into the page head."
        rows={3}
      />
      <Form.TextAreaField
        disableMobileInputStyling
        label="Header"
        mb={24}
        name="header"
        helperText="Markup that you would like to display above the menu."
        rows={3}
      />
      <Form.TextAreaField
        disableMobileInputStyling
        label="Footer"
        mb={24}
        name="footer"
        helperText="Markup that you would like to display below the menu."
        rows={3}
      />
      <Modal.ContentDivider />
    </>
  );
};
