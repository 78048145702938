import { useMemo } from 'react';

import { useManager } from '@jane/business-admin/data-access';
import { Permission } from '@jane/shared/auth';

export const useHasPermissions = (permissions: Permission[] | undefined) => {
  const { data: currentManager, isFetched } = useManager();

  const hasPermission = useMemo(() => {
    if (!permissions || !isFetched) return true;

    return permissions.some((permission) =>
      (currentManager?.manager?.permissions || []).includes(permission)
    );
  }, [isFetched, currentManager?.manager?.permissions, permissions]);

  return hasPermission;
};

export const useOnlyAnalyticsPermission = () => {
  const { data: currentManager, isFetched } = useManager();

  const hasPermission = useMemo(() => {
    if (!isFetched) return true;

    const permissions = currentManager?.manager?.permissions || [];
    return (
      permissions.length === 1 && permissions.includes(Permission.ViewAnalytics)
    );
  }, [isFetched, currentManager?.manager?.permissions]);

  return hasPermission;
};
