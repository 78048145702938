import styled from '@emotion/styled';
import { useState } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Button, Icon, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import SwitchIcon from '../../../assets/AppSwitcherIcon';
import { RequestFeedbackModal } from '../../feedback/RequestFeedbackModal';

const HeaderWrapper = styled.div({}, ({ theme }) => ({
  display: 'flex',
  ...spacing({ py: 16, pl: 48, pr: 24 }),
  justifyContent: 'space-between',
  alignItems: 'center',
  background: theme.colors.primary.main,
}));

export const AppSwitcherHeader = () => {
  const [requestFeedbackModalOpen, setRequestFeedbackModalOpen] =
    useState(false);

  const hasSunset = useFlag(FLAGS.scBizAdminSunsettingFe);

  const text = hasSunset
    ? 'Welcome to the new Business Dashboard! Please use this version to access all the latest updates. Familiarize yourself today before we remove access to the Classic Business dashboard on April 28th.'
    : 'Welcome to the new Business Dashboard beta! Feel free to poke around and stay awhile.';

  return (
    <>
      <HeaderWrapper>
        <Typography variant="body-bold" color="grays-white" mr={16}>
          {text}
        </Typography>

        <Button
          label="Switch to Classic View"
          onClick={() => setRequestFeedbackModalOpen(true)}
          startIcon={<Icon icon={SwitchIcon} />}
          variant="secondary"
        />
      </HeaderWrapper>
      <RequestFeedbackModal
        requestFeedbackModalOpen={requestFeedbackModalOpen}
        setRequestFeedbackModalOpen={setRequestFeedbackModalOpen}
      />
    </>
  );
};
