import { useContext, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { useHasPermissions } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { Tabs } from '@jane/shared/reefer';

import { SideNav } from '../../../SideNav';

export const StoreSettingsSideNav = () => {
  const { pathname } = useLocation();
  const [selectedPath, setSelectedPath] = useState<string>(pathname);
  const { storeId } = useContext(StoreDetailsContext);
  const match = useMatch(pathname);
  const hasEditPrintersPermission = useHasPermissions([
    Permission.EditPrinters,
  ]);

  const trackNavigation = (path: string) => {
    track({
      event: EventNames.Navigated,
      from_url: normalizePath(pathname, storeId),
      to_url: normalizePath(path, storeId),
      trigger_source_id: NavigationSourceIds.StoreDetailsSidenav,
    });
  };

  const navLinks = [
    { label: 'Details', path: businessPaths.storeSettingsDetails(storeId) },
    { label: 'Checkout', path: businessPaths.storeSettingsCheckout(storeId) },
    {
      label: 'Specials',
      path: businessPaths.storeSettingsSpecials(storeId),
    },
    {
      label: 'URLs & SEO',
      path: businessPaths.storeSettingsSubdomain(storeId),
    },
    {
      label: 'Integrations',
      path: businessPaths.storeSettingsIntegrations(storeId),
    },
    {
      label: 'Fulfillments',
      path: businessPaths.storeSettingsFulfillments(storeId),
    },
    {
      label: 'Notifications',
      path: businessPaths.storeSettingsNotifications(storeId),
    },
    ...(hasEditPrintersPermission
      ? [
          {
            label: 'Hardware',
            path: businessPaths.storeSettingsHardware(storeId),
          },
        ]
      : []),
  ];

  return (
    <SideNav aria-label="Store settings navigation">
      <Tabs
        direction="vertical"
        onChange={setSelectedPath}
        value={match?.pathname || selectedPath}
      >
        {navLinks.map((item) => (
          <Tabs.Tab
            value={item.path}
            key={item.label}
            label={item.label}
            to={item.path}
            onClick={() => trackNavigation(item.path)}
          />
        ))}
      </Tabs>
    </SideNav>
  );
};
