import { useCallback, useEffect, useMemo } from 'react';

import {
  useCreateStorePrintersSettings,
  useUpdateStorePrintersSettings,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  ModalNames,
  SettingNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import type { CloudPrinterSetting as StorePrinterSettingV2 } from '@jane/shared/models';
import { Flex, Modal, Typography, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

const FORM_ERROR_NAME = 'printer-settings-error';

export const PrinterSettingsModal = ({
  open,
  setOpen,
  subtitle,
  printerSettings,
  storeId,
}: {
  open: boolean;
  printerSettings?: StorePrinterSettingV2;
  setOpen: (open: boolean) => void;
  storeId: number;
  subtitle: string;
}) => {
  const isCreateMode = !printerSettings;
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${
      isCreateMode ? 'creating' : 'updating'
    } Order tickets. Please try again.`
  );

  const toast = useToast();
  const formName = 'Printer settings form';
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const {
    mutateAsync: updateStorePrintersSettings,
    isSuccess: isUpdateSuccess,
    isLoading: isSavingUpdate,
  } = useUpdateStorePrintersSettings(storeId.toString());

  const {
    mutateAsync: createStorePrintersSettings,
    isSuccess: isCreateSuccess,
    isLoading: isSavingCreate,
  } = useCreateStorePrintersSettings(storeId.toString());

  const isSuccess = useMemo(
    () =>
      (isCreateMode && isCreateSuccess) || (!isCreateMode && isUpdateSuccess),
    [isUpdateSuccess, isCreateSuccess, isCreateMode]
  );

  useEffect(() => {
    if (isSuccess) {
      toast.add({
        label: `Order tickets ${isCreateMode ? 'created' : 'updated'}`,
        variant: 'success',
      });
      setOpen(false);
    }
  }, [isSuccess, setOpen]);

  const onSubmit = useCallback(
    (formData: NonNullable<StorePrinterSettingV2>) => {
      const submitMethod = () =>
        isCreateMode
          ? createStorePrintersSettings(formData)
          : updateStorePrintersSettings(formData);

      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.OrderTickets,
        changed_attributes: Object.keys(dirtyFields),
      });

      return catchSubmitErrors({
        submitMethod,
        requestData: formData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            parseValidationErrors(validationErrors)
          );
        },
      });
    },
    [dirtyFields]
  );

  const handleCheckboxChange = ({
    name,
    checked,
  }: {
    checked: boolean;
    name: string;
  }) => {
    const eventName = checked
      ? EventNames.SelectedCheckbox
      : EventNames.DeselectedCheckbox;

    track({
      event: eventName,
      checkbox_label: name,
      modal_name: ModalNames.OrderTickets,
    });
  };

  const handleOnToggleChange = ({ isRevert }: { isRevert: boolean }) => {
    track({
      event: EventNames.ModifiedSetting,
      revert: isRevert,
      setting_name: SettingNames.OrderTicketsGroupByCategory,
      modal_name: ModalNames.OrderTickets,
    });
  };

  return (
    <ConfirmDiscardWrapper
      variant="dialogue"
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Order tickets"
          subtitle={subtitle}
          actions={
            <Form.SubmitButton
              variant="primary"
              label="Save"
              loading={isSavingCreate || isSavingUpdate}
            />
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Typography mb={24}>
            Configure what information you would like to print on your order
            tickets. Receipt settings are saved for all printers linked to the
            current store. You can update these anytime by contacting Jane
            Partner Success.
          </Typography>

          <Typography variant="body-bold" mb={24}>
            Order ticket information
          </Typography>
          <Flex flexDirection="row">
            <Flex flexDirection="column" width="50%">
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_store_name}
                label="Store name"
                name="print_store_name"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({ name: 'print_store_name', checked })
                }
              />
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_date_of_birth}
                label="DOB"
                name="print_date_of_birth"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({ name: 'print_date_of_birth', checked })
                }
              />
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_medical_id_number}
                label="Medical ID number"
                name="print_medical_id_number"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'print_medical_id_number',
                    checked,
                  })
                }
              />
            </Flex>
            <Flex flexDirection="column" width="50%">
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_fulfillment_type}
                label="Fulfillment type"
                name="print_fulfillment_type"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'print_fulfillment_type',
                    checked,
                  })
                }
              />
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_pos_identifier}
                label="POS SKU"
                name="print_pos_identifier"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'print_pos_identifier',
                    checked,
                  })
                }
              />
              <Form.CheckboxField
                defaultChecked={printerSettings?.print_pos_product_name}
                label="POS product name"
                name="print_pos_product_name"
                mb={24}
                onChange={(checked) =>
                  handleCheckboxChange({
                    name: 'print_pos_product_name',
                    checked,
                  })
                }
              />
            </Flex>
          </Flex>
          <Typography variant="body-bold" mb={24}>
            Order ticket settings
          </Typography>
          <Form.SwitchField
            defaultChecked={printerSettings?.print_items_grouped_by_kind}
            label="Group items by category"
            name="print_items_grouped_by_kind"
            onChange={(toggle) =>
              handleOnToggleChange({
                isRevert:
                  printerSettings?.print_items_grouped_by_kind !== toggle,
              })
            }
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};
