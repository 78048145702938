import { Modal, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const SeoSection = () => {
  return (
    <>
      <Typography variant="body-bold">SEO</Typography>
      <Typography color="grays-mid" mb={24}>
        These fields can be used to set SEO options.
      </Typography>
      <Form.SwitchField
        label={
          <>
            <Typography variant="body-bold">
              Use my own title and description tags on menu page
            </Typography>
            <Typography color="grays-mid">
              If left blank, Jane will set the title and description tags on
              your base menu page.
            </Typography>
          </>
        }
        name="disable_jane_title_and_description_meta"
        mb={24}
      />
      <Form.TextField
        disableMobileInputStyling
        type="text"
        autocomplete="off"
        label="Custom Product Detail Title Suffix"
        name="custom_product_detail_title_suffix"
        helperText="This is additional text that will be appended to the end of each Product Detail Page title."
      />
      <Modal.ContentDivider />
    </>
  );
};
