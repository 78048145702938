import { useContext } from 'react';

import { GlobalSpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const BulkPricing = ({
  name,
  options,
}: SpecialTypeProps['bulk_pricing']) => {
  const { isDisabled } = useContext(GlobalSpecialsModalContext);
  return (
    <Flex mb={24} width="100%" flexDirection="column">
      <Flex gap={24}>
        <Flex flexDirection="column" width="100%">
          <Form.NumberField
            name={`${name}.max_applications_per_cart`}
            label="Maximum uses per order"
            disabled={isDisabled}
          />

          <Typography color="grays-mid" variant="body" mt={16}>
            Limit the number of times this special can be used per order.
          </Typography>
        </Flex>
        <Flex flexDirection="column" width="100%">
          <Form.SelectField
            name={`${name}.target_weight` || ''}
            label="Weight threshold"
            options={options || []}
            disabled={isDisabled}
          />
          <Typography color="grays-mid" variant="body" mt={16}>
            Select the target weight the customer must reach to activate this
            special.
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
};
