import capitalize from 'lodash/capitalize';
import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { ADDITIONAL_WEIGHTS } from '@jane/business-admin/util';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const BulkPricing = ({
  isCreateMode,
  name,
  options,
}: SpecialTypeProps['bulk_pricing'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();

  const maxApplicationsValue = getValues(`${name}.max_applications_per_cart`);
  const bulkPricingValue = getValues(`${name}.target_weight`);
  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex mb={24} width="100%" flexDirection="column">
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <Flex gap={24}>
          <Flex flexDirection="column" width="100%">
            <Form.NumberField
              name={`${name}.max_applications_per_cart`}
              label="Maximum uses per order"
            />

            <Typography color="grays-mid" variant="body" mt={16}>
              Limit the number of times this special can be used per order.
            </Typography>
          </Flex>
          <Flex flexDirection="column" width="100%">
            <Form.SelectField
              name={`${name}.target_weight` || ''}
              label="Weight threshold"
              options={options || []}
            />
            <Typography color="grays-mid" variant="body" mt={16}>
              Select the target weight the customer must reach to activate this
              special.
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <Flex gap={24}>
          <Flex width="50%" flexDirection="column">
            <Typography
              color="grays-black"
              variant="body-bold"
              data-testid="non-janepos-synced-max-applications"
            >
              Maximum uses per order
            </Typography>
            <Typography color="grays-black" variant="body" mt={4}>
              {maxApplicationsValue}
            </Typography>
          </Flex>
          <Flex width="50%" flexDirection="column">
            <Typography
              color="grays-black"
              variant="body-bold"
              data-testid="non-janepos-synced-weight-threshold"
            >
              Weight threshold
            </Typography>
            <Typography color="grays-black" variant="body" mt={4}>
              {capitalize(
                ADDITIONAL_WEIGHTS.find(
                  (weight) => weight.id === bulkPricingValue
                )?.label
              )}
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
