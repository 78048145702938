import type {
  SpecialTypeConditionsInputMap,
  SpecialTypeConditionsInputMapItem,
} from '@jane/business-admin/types';
import { Flex } from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import { Bundle } from './special_types/conditions/Bundle';

const getSpecialTypeConditionsData: any = ({
  specialTypeKey,
  subKey,
}: {
  specialTypeKey: string;
  subKey: keyof SpecialTypeConditionsInputMapItem;
}) => {
  const specialTypeMap: SpecialTypeConditionsInputMap = {
    bundle: {
      name: 'display.bundle',
      options: [
        {
          rule: 'required_rules',
          value: 'threshold_number_of_items_in_cart',
          label: 'Minimum products requirement',
        },
        {
          rule: 'discounted_rules',
          value: 'max_number_of_discounted_products',
          label: 'Maximum products discounted',
        },
      ],
    },
  };

  return specialTypeMap[specialTypeKey as keyof typeof specialTypeMap]?.[
    subKey
  ];
};

export const SpecialTypeConditionsCriteria = ({
  isCreateMode = false,
  rule,
}: {
  isCreateMode?: boolean;
  rule: string;
}) => {
  const { watch, getValues } = useFormContext();
  const specialTypeWatch = watch('special_type');
  const specialTypeValue = getValues('special_type');

  const specialType = specialTypeWatch || specialTypeValue;

  const specialTypeConditions = getSpecialTypeConditionsData({
    specialTypeKey: specialType,
    subKey: 'options',
  })?.find((option: any) => option.rule === rule);

  return specialType === 'bundle' ? (
    <Flex width={'100%'}>
      <Bundle
        name={getSpecialTypeConditionsData({
          specialTypeKey: specialType,
          subKey: 'name',
        })}
        isCreateMode={isCreateMode}
        options={[specialTypeConditions]}
      />
    </Flex>
  ) : null;
};
