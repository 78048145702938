import styled from '@emotion/styled';
import { useMemo } from 'react';

import { useNavigateAndTrack } from '@jane/business-admin/hooks';
import type { AbbreviatedStoreV2 } from '@jane/business-admin/types';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';
import {
  Card,
  Flex,
  Link,
  PartnerLogo,
  Skeleton,
  Tag,
  Typography,
} from '@jane/shared/reefer';

export const StoreCard = ({
  store,
  isLoading,
  isCountLoading,
}: {
  isCountLoading: boolean;
  isLoading: boolean;
  store?: AbbreviatedStoreV2 | undefined;
}) => {
  const navigate = useNavigateAndTrack();

  const onClickProductNumber = (
    event: React.MouseEvent,
    variant: 'published' | 'hidden' | 'unpublished'
  ) => {
    event.stopPropagation();
    navigate(
      businessPaths.storeProducts(`${store?.id}`, variant),
      NavigationSourceIds.StoreCard,
      store?.id
    );
  };

  const onCardClick = () => {
    track({
      event: EventNames.ChangedStore,
      from_store_id: '',
      to_store_id: store?.id.toString() || '',
    });
    navigate(
      businessPaths.storeSettingsDetails(`${store?.id}`),
      NavigationSourceIds.StoreCard,
      store?.id
    );
  };

  const concatAddress = useMemo(
    () =>
      `${store?.address}${store?.address2 ? `, ${store?.address2}` : ''}, ${
        store?.city
      }, ${store?.state} ${store?.zip}`,
    [store]
  );

  const StyledLink = styled(Link)({
    width: '50%',
    padding: 16,
  });

  const StyledNumberLink = styled(Link)({
    width: '20%',
    display: 'flex',
  });

  return (
    <Card.Group
      direction="row"
      border="grays-light"
      mb={24}
      width="100%"
      ariaLabel="storeLink"
    >
      <StyledLink
        ariaLabel={'Go to ' + (store?.name || '') + ' store details page'}
        to={businessPaths.storeSettingsDetails(`${store?.id}`)}
        onClick={
          isLoading
            ? () => null
            : (e) => {
                e.preventDefault();

                onCardClick();
              }
        }
        variant="minimal"
      >
        <Flex alignItems="center" justifyContent="left" p={8}>
          {isLoading ? (
            <Flex width={'64px'} role="cell" pr={24}>
              <Skeleton.Bone
                height="64px"
                width="64px"
                borderRadius="sm"
              ></Skeleton.Bone>
            </Flex>
          ) : (
            <PartnerLogo
              name={store?.name || ''}
              variant="store"
              image={store?.photo}
              size="sm"
            />
          )}
          <Flex flexDirection="column" ml={24}>
            {isLoading ? (
              <Flex width={100} role="cell" p={8}>
                <Skeleton.Bone height={12}></Skeleton.Bone>
              </Flex>
            ) : (
              <Typography variant="body-bold" mb={8}>
                {store?.name}
              </Typography>
            )}
            {isLoading ? (
              <Flex width={200} role="cell" p={8}>
                <Skeleton.Bone height={12}></Skeleton.Bone>
              </Flex>
            ) : (
              <Flex>
                <Tag label={store?.recreational ? 'Rec' : 'Med'} mr={8} />
                <Typography color="grays-mid">{concatAddress}</Typography>
              </Flex>
            )}
          </Flex>
        </Flex>
      </StyledLink>
      <Card.Content width="50%" height="auto">
        <Flex role="row" height="100%">
          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            role="cell"
          >
            {isCountLoading ? (
              <Flex width={36} role="cell">
                <Skeleton.Bone height={24}></Skeleton.Bone>
              </Flex>
            ) : (
              <StyledNumberLink
                to={businessPaths.storeProducts(`${store?.id}`, 'published')}
                onClick={(e) => onClickProductNumber(e, 'published')}
                ariaLabel={`${store?.visible_products_count} published products, click to view published products`}
              >
                <Typography p={16}>{store?.visible_products_count}</Typography>
              </StyledNumberLink>
            )}
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            role="cell"
          >
            {isCountLoading ? (
              <Flex width={36} role="cell">
                <Skeleton.Bone height={24}></Skeleton.Bone>
              </Flex>
            ) : (
              <StyledNumberLink
                to={businessPaths.storeProducts(`${store?.id}`, 'hidden')}
                onClick={(e) => onClickProductNumber(e, 'hidden')}
                ariaLabel={`${store?.hidden_products_count} hidden products, click to view hidden products`}
              >
                <Typography p={16}>{store?.hidden_products_count}</Typography>
              </StyledNumberLink>
            )}
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            role="cell"
          >
            {isCountLoading ? (
              <Flex width={36} role="cell">
                <Skeleton.Bone height={24}></Skeleton.Bone>
              </Flex>
            ) : (
              <StyledNumberLink
                to={businessPaths.storeProducts(`${store?.id}`, 'unpublished')}
                onClick={(e) => onClickProductNumber(e, 'unpublished')}
                ariaLabel={`${store?.unmapped_products_count} unpublished products, click to view unpublished products`}
              >
                <Typography p={16}>{store?.unmapped_products_count}</Typography>
              </StyledNumberLink>
            )}
          </Flex>
        </Flex>
      </Card.Content>
    </Card.Group>
  );
};
