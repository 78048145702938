import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import type { CustomLineageLabelsV2 } from '@jane/business-admin/types';
import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import type { PriceId } from '@jane/shared/types';
import { allWeightIds, labelForWeightId } from '@jane/shared/util';

import {
  ConditionType,
  EXCLUDE_TYPES,
  FORM_FIELD_DEFAULT_MARGIN,
} from '../../form';

interface Props {
  conditions: string[];
  disableAddButton?: boolean;
  fieldPrefix: string;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: string[], type?: any) => void;
  type:
    | ConditionType.Lineage
    | ConditionType.Weight
    | ConditionType.ExcludeLineages
    | ConditionType.ExcludeWeights;
}

/**
 * Component for each condition line item in building conditions for a Special. This particular component can handle Lineage or Weight
 *
 * @param conditions: Simple list of conditions to display, values from a special should be mapped to match a simple conditions array
 * @param disableAddButton: Disable the "Add Condition" and "Select (condition)" buttons, only allows for removing any existing conditions
 * @param fieldPrefix: Used for input name fields, to prevent duplicates when there are two conditions cards in the case of BOGO specials
 * @param onConditionsUpdate: Callback when item is removed by clicking "X" icon, or user clears all by clicking "Remove condition"
 * @param type: Type of condition
 * @param lastCondition: Removes bottom border if true
 */
export const ConditionByCheckboxes = ({
  conditions: conditionsProp,
  disableAddButton,
  fieldPrefix,
  onConditionsUpdate,
  type,
  lastCondition,
}: Props) => {
  const {
    storeSettings,
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { isDisabled: globalSpecialDisabled } = useContext(
    GlobalSpecialsModalContext
  );
  const [expanded, setExpanded] = useState(!!conditionsProp.length);
  const [conditions, setConditions] = useState(conditionsProp);

  const includesOrExcludes =
    type.indexOf('exclude') >= 0 ? 'excludes' : 'includes';

  useEffect(() => {
    setExpanded(!!conditionsProp.length);
    setConditions(conditionsProp);
  }, [conditionsProp]);

  const weightOptions = useMemo(() => {
    const allWeights = allWeightIds('extract');
    return allWeights.map((weight: PriceId) => ({
      label: labelForWeightId(weight),
      value: weight,
    }));
  }, []);

  // TODO: Move to utils since Product DetailsCard also uses it
  const lineageOptions = (custom_lineage_labels: CustomLineageLabelsV2) => {
    const {
      indica = 'Indica',
      sativa = 'Sativa',
      hybrid = 'Hybrid',
      cbd = 'CBD',
    } = custom_lineage_labels;
    const baseLineageOptions = [
      {
        value: 'indica',
        label: indica,
      },
      {
        value: 'sativa',
        label: sativa,
      },
      {
        value: 'hybrid',
        label: hybrid,
      },
      {
        value: 'cbd',
        label: cbd,
      },
    ];

    return baseLineageOptions;
  };
  const options = useMemo(() => {
    if (
      type === ConditionType.Lineage ||
      type === ConditionType.ExcludeLineages
    ) {
      return lineageOptions(
        storeSettings?.custom_labels || {
          indica: 'Indica',
          sativa: 'Sativa',
          hybrid: 'Hybrid',
          cbd: 'CBD',
          none: 'None',
        }
      );
    } else {
      return weightOptions;
    }
  }, [type, storeSettings?.custom_labels, weightOptions]);

  useEffect(() => {
    setConditions(conditionsProp);
  }, [conditionsProp]);

  const removeCondition = () => {
    setConditions([]);
    setExpanded(false);

    onConditionsUpdate([]);
  };

  const onCheckboxChange = (checked: boolean, value: string) => {
    const filteredConditions = [...conditions];
    if (!checked) {
      filteredConditions.splice(filteredConditions.indexOf(value), 1);
    } else {
      filteredConditions.push(value);
    }

    setConditions(filteredConditions);
    onConditionsUpdate(filteredConditions);
  };

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly || globalSpecialDisabled;

  const conditionButtonTestId = `${expanded ? 'remove' : 'add'}-${type.replace(
    / /g,
    '-'
  )}-condition`;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeCondition() : setExpanded(true);
            }}
            variant={
              expanded
                ? 'tertiary'
                : EXCLUDE_TYPES.includes(type)
                ? 'destructive-secondary'
                : 'secondary'
            }
            disabled={(disableAddButton && !expanded) || isDisabled}
            data-testid={conditionButtonTestId}
          />
        </Flex>
      </Flex>
      {expanded && (
        <>
          <Flex gap={12} flexWrap="wrap">
            {options.map((option) => (
              <CheckboxField
                key={`key_${includesOrExcludes}_${option.value}`}
                mr={24}
                name={`${fieldPrefix}.${includesOrExcludes}.${option.value}`}
                label={option.label}
                onChange={(value) => onCheckboxChange(value, option.value)}
                checked={conditions.includes(option.value)}
                disabled={disableAddButton || isDisabled}
              />
            ))}
          </Flex>
          {!lastCondition && <Modal.ContentDivider />}
        </>
      )}
    </>
  );
};
