import styled from '@emotion/styled';
import * as Emoji from 'quill-emoji';
import { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import type { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Typography } from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import './styles/quill-emoji.css';
import './styles/react-quill.css';

Quill.register('modules/emoji', Emoji);

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    [{ size: ['small', false, 'large'] }],
    ['emoji'],
  ],
  'emoji-toolbar': true,
};

const EditorContainer = styled.div({
  height: '440px',
  paddingBottom: '24px',
});

const ReactQuillWithTheme = (props: ReactQuillProps) => (
  <ReactQuill theme="snow" {...props} />
);

const RichTextEditor = styled(ReactQuillWithTheme)({
  '& .ql-container': {
    '&.ql-snow': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      height: '400px',
    },
  },

  '& .ql-toolbar': {
    '&.ql-snow': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
  },

  '& .ql-editor': {
    '& p': {
      fontFamily: 'inherit',
      fontSize: '14px',
      color: '#333333',
    },

    '& .ql-size-small': {
      fontFamily: 'inherit',
      fontSize: '12px',
    },

    '& .ql-size-large': {
      fontFamily: 'inherit',
      fontSize: '18px',
    },
  },
});

interface RichEditorProps {
  defaultValue?: string;
  onChange?: (htmlValue: string) => void;
}

export const RichEditor = ({ defaultValue, onChange }: RichEditorProps) => {
  const {
    setValue,
    getValues,
    setError,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  useEffect(() => {
    setValue('message', defaultValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, []);

  const handleChange: ReactQuillProps['onChange'] = (htmlValue) => {
    setValue('message', htmlValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    const message = getValues('message');

    if (!message || message === '<p><br></p>') {
      setError('messageField', {
        type: 'onChange',
        message: 'message is required',
      });
    } else {
      clearErrors('messageField');
    }

    onChange && onChange(message);
  };

  return (
    <>
      <EditorContainer>
        <RichTextEditor
          modules={MODULES}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
      </EditorContainer>
      {errors['messageField'] ? (
        <Typography mt={16} color="error">
          Please fill out this field.
        </Typography>
      ) : null}
    </>
  );
};
