import { z } from 'zod';

import {
  zAbbreviatedMenuProduct,
  zMenuProduct,
  zPhoto,
} from '@jane/shared/types';

import { zAbbreviatedMenuRow } from './abbreviatedMenuRow';

export const zFlavorsAndEffectsKind = z.object({
  _destroy: z.boolean().optional(),
  id: z.number(),
  kind: z.string(),
});

export const zMenuProductForProductsTable = zMenuProduct
  .pick({
    amount: true,
    category: true,
    description: true,
    dosage: true,
    effects: true,
    flavors: true,
    image_urls: true,
    percent_thc: true,
    percent_cbd: true,
    photos: true,
  })
  .merge(
    zAbbreviatedMenuProduct.omit({
      kind: true,
    })
  )
  .extend({
    category: zAbbreviatedMenuProduct.shape.kind,
    content_type: z.string(),
    custom_rows: z.array(zAbbreviatedMenuRow),
    default_product_brand_subtype: z.string().nullable(),
    default_product_description: z.string(),
    default_product_lineage: z.string().nullable(),
    default_product_name: z.string(),
    default_product_image_urls: z.array(zPhoto.pick({ urls: true })),
    effects: z.array(zFlavorsAndEffectsKind),
    flavors: z.array(zFlavorsAndEffectsKind),
    full_name: z.string(),
    is_self_published: z.boolean(),
    inventory_count: z.number(),
    lineage: zMenuProduct.shape.category.or(z.literal('')),
    percent_cbd: z.string().nullable(),
    percent_cbda: z.string().nullable(),
    percent_thc: z.string().nullable(),
    percent_thca: z.string().nullable(),
    pos_product_link: z.string().nullable(),
    pos_sku: z.array(z.array(z.string())),
    photos: zMenuProduct.shape.photos.nullable(),
    status: z.string(),
    subcategory: z.string().nullable(),
    unavailable_prices: z.array(
      z.object({
        id: z.number(),
        menu_product_id: z.number(),
        price_id: z.string(),
      })
    ),
    visible: z.boolean().nullable(),
  });

// TODO(adam): add to all_product_ids typing after scAllProductsSearchFrontend FF is disabled
// export const zAllProductIds = z.object({
//   id: z.number(),
//   product_id: z.number(),
//   menu_product_id: z.number(),
// });

export const zProductIndexResponse = z.object({
  products: z.array(zMenuProductForProductsTable),
  meta: z.object({
    page: z.number(),
    page_total: z.number(),
    per_page: z.number(),
    total: z.number(),
    number_of_pages: z.number(),
    all_product_ids: z.array(z.any()),
  }),
});

export const zProductDetailResponse = z.object({
  product: zMenuProductForProductsTable,
});

export type MenuProductForProductsTable = z.infer<
  typeof zMenuProductForProductsTable
>;

export type ProductIndexResponse = z.infer<typeof zProductIndexResponse>;
