import { useParams } from 'react-router-dom';

import { DiscountsCard, StackingCard } from '@jane/business-admin/components';
import { useStoreSettings } from '@jane/business-admin/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex } from '@jane/shared/reefer';

export const StoreSettingsSpecials = () => {
  const { id = '' } = useParams<'id'>();
  const { data: storePayload } = useStoreSettings(id);
  const isPosSyncedStore = storePayload?.has_pos_integration;

  const storeLevelStackingEnabled = useFlag(FLAGS.scStackingSettingsPosStores);
  const specialsBetaAccess = useFlag(FLAGS.scSpecialsBetaAccess);

  return (
    <Flex flexDirection="column">
      {(!specialsBetaAccess ||
        (storeLevelStackingEnabled && isPosSyncedStore)) && <StackingCard />}
      <DiscountsCard />
    </Flex>
  );
};
