import styled from '@emotion/styled';
import difference from 'lodash/difference';
import pluralise from 'pluralise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Button,
  ButtonToggle,
  Flex,
  Loading,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import { NoStoresFound } from './NoStoresFound';
import { StoreRow } from './StoreRow';
import { StoreSelectModalFilters } from './StoreSelectModalFilters';
import { useFilteredStores } from './useFilteredStores';
import { idConversions } from './util/idConversions';

const ButtonToggleStylesOverrideWrapper = styled.div(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.colors.grays.ultralight,
    borderRadius: theme.borderRadius.sm,

    '> button': {
      borderRadius: theme.borderRadius.sm,
      paddingLeft: '24px',
      paddingRight: '24px',
      marginRight: 0,
    },
  },
}));

export const enum SUBMIT_BUTTON_VARIANTS {
  save,
  saveTo,
  select,
}

const SUBMIT_BUTTON_MAPS: { [k in SUBMIT_BUTTON_VARIANTS]: string } = {
  [SUBMIT_BUTTON_VARIANTS.save]: 'Save',
  [SUBMIT_BUTTON_VARIANTS.saveTo]: 'Save to',
  [SUBMIT_BUTTON_VARIANTS.select]: 'Select',
};

export interface Store {
  address: string;
  address2?: string | null;
  cart_limit_policy?: { id: string | number; name: string | null } | null;
  city?: string | null;
  id: string | number;
  name: string;
  recreational: boolean;
  state?: string | null;
  zip?: string | undefined;
}

enum FilterNames {
  StoreState = 'Store State',
  StoreType = 'Store Type',
}

export type StoreSearchMode = 'storeId' | 'storeName';
export interface StoreSelectModalProps {
  closeModal: () => void;
  disableSubmit?: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  initialSearchFilter?: string;
  isFetchingStores: boolean;
  onFilterChange?: (filterName: FilterNames, value?: string) => void;
  onSearchCallback?: (query: string, successful?: boolean) => void;
  onSearchedStoreIdsChange?: (ids: string[]) => void;
  onSubmit: (formDataEnabledIds: number[], isDirty: boolean) => void;
  onToggleAll?: (selected: boolean) => void;
  onToggleStore?: (storeId: string, selected: boolean) => void;
  searchFilterPlaceholder?: string;
  searchedStoreIds?: string[];
  selectedStoreIds?: string[];
  storesData: Store[];
  submitButtonType?: SUBMIT_BUTTON_VARIANTS;
  subtitle?: string;
}

export const StoreSelectModal = ({
  closeModal,
  disableSubmit = false,
  fetchNextPage,
  hasNextPage,
  onFilterChange,
  onSubmit,
  onSearchCallback,
  onToggleAll,
  onToggleStore,
  searchFilterPlaceholder = 'Search name, city or id',
  selectedStoreIds = [],
  submitButtonType = SUBMIT_BUTTON_VARIANTS.saveTo,
  subtitle,
  storesData,
  initialSearchFilter = '',
  isFetchingStores,
  onSearchedStoreIdsChange,
  searchedStoreIds,
}: StoreSelectModalProps) => {
  const { setValue, watch } = useFormContext();
  const storesWatch = watch('stores');

  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);
  const [typeFilter, setTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [searchMode, setSearchMode] = useState<StoreSearchMode>(
    searchedStoreIds && searchedStoreIds?.length > 0 ? 'storeId' : 'storeName'
  );
  const enableSearchByStoreId = !!onSearchedStoreIdsChange;
  const prevSearchFilter = useRef('');
  const handleSearchChange = (value: string) => {
    setSearchFilter((prev) => {
      prevSearchFilter.current = prev;
      return value;
    });
  };

  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView && fetchNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleStateChange = (value: string) => {
    setStateFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreState, value);
  };

  const handleTypeChange = (value: string) => {
    setTypeFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreType, value);
  };

  type StoreSelectionsRecord = Record<number | string, boolean>;

  const [storeSelections, setStoreSelections] = useState<StoreSelectionsRecord>(
    selectedStoreIds.reduce<Record<string, boolean>>((acc, curr) => {
      acc[`${curr}`] = true;
      return acc;
    }, {})
  );

  const stores = useMemo(
    () =>
      storesData?.map(({ id, ...restStore }) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storesData]
  );

  const isStoreSelected = useCallback(
    (store: Store) =>
      storeSelections[idConversions.asString(store.id)] === true,
    [storeSelections]
  );

  const numStoresSelected = useMemo(
    () => Object.values(storeSelections).filter((val) => val).length,
    [storeSelections]
  );

  const submitButtonTypeLabel = SUBMIT_BUTTON_MAPS[submitButtonType];

  const submitLabel =
    submitButtonTypeLabel === 'Save'
      ? 'Save'
      : `${submitButtonTypeLabel} ${numStoresSelected} ${pluralise(
          numStoresSelected,
          'store'
        )}`;

  const selectedLabel = `${numStoresSelected} ${pluralise(
    numStoresSelected,
    'location'
  )} selected`;

  const filteredStores = useFilteredStores({
    stores,
    searchFilter,
    typeFilter,
    stateFilter,
  });

  const lastFilteredStoreId = filteredStores[filteredStores.length - 1]?.id;

  const formDataEnabledIds = useMemo(
    () =>
      Object.entries(storeSelections).map(([id]) => idConversions.asNumber(id)),
    [storeSelections]
  );

  const isDirty = useMemo(() => {
    const hasDifferentFormEnabledIds =
      difference(
        formDataEnabledIds,
        selectedStoreIds.map((id) => idConversions.asNumber(id))
      ).length > 0;

    const hasDifferentSelectedStoreIds =
      difference(
        selectedStoreIds.map((id) => idConversions.asNumber(id)),
        formDataEnabledIds
      ).length > 0;

    return hasDifferentFormEnabledIds || hasDifferentSelectedStoreIds;
  }, [selectedStoreIds, formDataEnabledIds]);

  const allSelected = useMemo(
    () =>
      filteredStores.every(
        ({ id }) => !!storeSelections[idConversions.asNumber(id)]
      ),
    [filteredStores, storeSelections]
  );

  const handleSubmit = () => {
    onSubmit(formDataEnabledIds, isDirty);
  };

  const isTogglingAll = useRef(false);

  const handleToggleAll = (selected: boolean) => {
    const update: { [key: string]: boolean } = { ...storeSelections };
    filteredStores.forEach((store) => {
      update[store.id] = selected;
    });

    isTogglingAll.current = true;
    setStoreSelections(update);
    onToggleAll && onToggleAll(selected);
  };

  useEffect(() => {
    isTogglingAll.current = false;
  }, [storeSelections]);

  useEffect(() => {
    const shouldPreSelectStoresById =
      enableSearchByStoreId && searchedStoreIds && searchedStoreIds.length > 0;
    if (shouldPreSelectStoresById) {
      // Pre-select stores when using By Store IDs search
      setStoreSelections(
        searchedStoreIds.reduce<Record<string, boolean>>((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {})
      );
    }
  }, [enableSearchByStoreId, searchedStoreIds]);

  useEffect(() => {
    if (searchFilter !== prevSearchFilter.current && onSearchCallback) {
      onSearchCallback(searchFilter, filteredStores.length > 0);
    }
  }, [searchFilter, filteredStores, onSearchCallback]);

  const handleSearchModeChange = (searchMode: StoreSearchMode) => {
    // clear previous search filters
    setSearchFilter('');
    setTypeFilter('');
    setStateFilter('');
    onSearchedStoreIdsChange && onSearchedStoreIdsChange([]);
    setStoreSelections({});
    setSearchMode(searchMode);
  };

  const handleIdsChange = useCallback(
    (ids: string) => {
      if (onSearchedStoreIdsChange) {
        const parsedIds = idConversions.fromStrings(ids);
        onSearchedStoreIdsChange(parsedIds.filter(Boolean));
      }
    },
    [onSearchedStoreIdsChange]
  );

  const renderStoreRow = (store: Store) => {
    const storeId = idConversions.asNumber(store.id);
    const isChecked = isStoreSelected(store);

    const isHidden = !filteredStores.find(({ id }) => id === store.id);

    const changeHandler = (checked: boolean) => {
      if (isTogglingAll.current) return;

      setStoreSelections((currentSelections) => ({
        ...currentSelections,
        [storeId]: checked,
      }));

      const updatedStores =
        storesWatch &&
        storesWatch.map((store: any) =>
          store.id === storeId ? { ...store, enabled: checked } : store
        );
      setValue('stores', updatedStores);

      onToggleStore && onToggleStore(storeId.toString(), checked);
    };

    const isLast = store.id === lastFilteredStoreId;

    return (
      <span key={`${store.name}-${store.id}`}>
        <StoreRow
          isHidden={isHidden}
          isChecked={isChecked}
          store={store}
          handleChange={changeHandler}
        />
        {!isHidden && !isLast && <Modal.ContentDivider padding={false} />}
      </span>
    );
  };

  return (
    <Modal
      appId="root"
      onRequestClose={closeModal}
      contentLabel="select stores"
      open
    >
      <>
        <Modal.Header
          title="Select stores"
          subtitle={subtitle}
          actions={
            <Button
              variant="primary"
              data-testid="store-select-submit"
              label={submitLabel}
              ml={16}
              onClick={handleSubmit}
              disabled={disableSubmit}
            />
          }
        />
        <Modal.Content>
          <Flex flexDirection="column" gap={24}>
            {enableSearchByStoreId && (
              <Flex gap={16} alignItems="center">
                <ButtonToggleStylesOverrideWrapper>
                  <ButtonToggle
                    value={searchMode}
                    onChange={(value) => {
                      handleSearchModeChange(value as StoreSearchMode);
                    }}
                    full={false}
                  >
                    <ButtonToggle.Button
                      label="Select stores"
                      value={'storeName'}
                    />
                    <ButtonToggle.Button
                      label="By Store IDs"
                      value={'storeId'}
                    />
                  </ButtonToggle>
                </ButtonToggleStylesOverrideWrapper>
              </Flex>
            )}

            <Flex>
              <StoreSelectModalFilters
                searchMode={enableSearchByStoreId ? searchMode : 'storeName'}
                onIdsChange={handleIdsChange}
                typeFilter={typeFilter}
                stateFilter={stateFilter}
                searchFilter={searchFilter}
                searchFilterPlaceholder={searchFilterPlaceholder}
                setTypeFilter={handleTypeChange}
                setStateFilter={handleStateChange}
                setSearchFilter={handleSearchChange}
                filteredStores={filteredStores}
                isFetchingStores={isFetchingStores}
                disablePadding
              />
            </Flex>

            {!isFetchingStores ? (
              filteredStores.length === 0 ? (
                <NoStoresFound searchMode={searchMode} />
              ) : (
                <>
                  <Flex flexDirection="column">
                    {stores?.map((store) => renderStoreRow(store))}
                  </Flex>
                  <span ref={fetchMoreRef}></span>
                </>
              )
            ) : (
              <Loading />
            )}
          </Flex>
        </Modal.Content>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center" mt={4}>
            <Typography>{selectedLabel}</Typography>
            <Flex gap={16}>
              <Button
                variant="tertiary"
                label="Enable all"
                onClick={() => handleToggleAll(true)}
                disabled={allSelected}
              />
              <Button
                variant="tertiary"
                label="Disable all"
                disabled={!numStoresSelected}
                onClick={() => handleToggleAll(false)}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </>
    </Modal>
  );
};
