import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useProductTypes } from '@jane/business-admin/data-access';
import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { CustomLabel } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SettingNames,
  track,
} from '@jane/business-admin/util';
import type { NuProductType } from '@jane/shared/models';
import { Button, Flex, TrashIcon, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { CategoryAndSubcategorySelect } from '../../../../CategoryAndSubcategorySelect';

export const PRODUCT_LABELS_FIELD_NAME = 'product_labels';
export const SUBCATEGORY_ERRORS_NAME = 'subcat-errors';

const DEFAULT_NEW_PRODUCT_TYPE = 'flower';
const ALL_PRODUCT_SUBTYPE = 'all';
const DEFAULT_NEW_PRODUCT_SUBTYPE = ALL_PRODUCT_SUBTYPE;

const getCustomLabelFieldNamePrefix = (index: number) =>
  `${PRODUCT_LABELS_FIELD_NAME}.${index}` as const;

const CustomLabelRow = ({
  existingLabel,
  index,
  productTypesMap,
  removeItem,
}: {
  existingLabel?: any;
  index: number;
  productTypesMap: ProductTypes;
  removeItem: (index: number) => void;
}) => {
  const debouncedTrack = useDebouncedTrack(1000);
  const fieldNamePrefix = getCustomLabelFieldNamePrefix(index);
  const categoryName = `${fieldNamePrefix}.product_type`;
  const subCategoryName = `${fieldNamePrefix}.product_subtype`;
  const customizableIdName = `${fieldNamePrefix}.customizable_id`;

  const productType = productTypesMap[existingLabel.customizable_id];

  const { control, setValue } = useFormContext();

  const lookupMatchingCustomizableId = useCallback(
    (categoryValue: string, subcategoryValue: string) => {
      const adjustedSubcategoryValue =
        subcategoryValue === ALL_PRODUCT_SUBTYPE ? null : subcategoryValue;

      const customizableId = Object.values(productTypesMap).find(
        ({ product_type: productType, product_subtype: productSubtype }) =>
          productType === categoryValue &&
          productSubtype === adjustedSubcategoryValue
      )?.id;

      return customizableId;
    },
    []
  );

  const defaultCategoryValue =
    productType?.product_type || DEFAULT_NEW_PRODUCT_TYPE;

  const defaultSubCategoryValue =
    productType?.product_subtype || DEFAULT_NEW_PRODUCT_SUBTYPE;

  const categoryNameValue = useWatch({
    control,
    name: categoryName,
    defaultValue: defaultCategoryValue,
  });

  const subCategoryNameValue = useWatch({
    control,
    name: subCategoryName,
    defaultValue: defaultSubCategoryValue,
  });

  const trackEdit = () => {
    track({
      event: EventNames.ModifiedSetting,
      action: 'edit',
      setting_name: SettingNames.ProductSubcategoryLabel,
      revert: false,
      modal_name: ModalNames.FiltersAndLabels,
    });
  };

  useEffect(() => {
    const customizableId = lookupMatchingCustomizableId(
      categoryNameValue,
      subCategoryNameValue
    );

    setValue(customizableIdName, customizableId, { shouldDirty: false });
  }, [categoryNameValue, subCategoryNameValue]);
  return (
    <Flex gap={24} mt={24} alignItems="center" width="full">
      <CategoryAndSubcategorySelect
        mb={24}
        isLoading={false}
        categoryProps={{
          name: categoryName,
          defaultValue: defaultCategoryValue,
          onChange: trackEdit,
        }}
        subcategoryProps={{
          name: subCategoryName,
          defaultValue: defaultSubCategoryValue,
          onChange: trackEdit,
        }}
      >
        <Form.TextField
          name={`${fieldNamePrefix}.custom_label`}
          label="Custom label"
          defaultValue={existingLabel ? existingLabel.custom_label : ''}
          onChange={() => {
            debouncedTrack({
              event: EventNames.ModifiedSetting,
              setting_name: SettingNames.ProductSubcategoryLabel,
              action: 'edit',
              revert: false,
              modal_name: ModalNames.FiltersAndLabels,
            });
          }}
        />
      </CategoryAndSubcategorySelect>
      <Button.Icon
        onClick={() => removeItem(index)}
        label="remove label"
        icon={<TrashIcon />}
        mt={8}
      />
    </Flex>
  );
};

type ProductTypes = { [k: NuProductType['id']]: NuProductType };

export const CustomProductLabels = ({
  customProductLabels,
  storeId,
}: {
  customProductLabels: CustomLabel[];
  storeId: string;
}) => {
  const { data: allProductTypes, isFetched: productTypesFetched } =
    useProductTypes();

  const productTypes = useMemo(
    () => keyBy(allProductTypes, 'id'),
    [allProductTypes]
  );

  const {
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext();
  const {
    fields: productLabels,
    append,
    remove,
  } = useFieldArray({
    control,
    name: PRODUCT_LABELS_FIELD_NAME,
  });

  useEffect(() => {
    let subscription: { unsubscribe: () => void };

    if (errors[SUBCATEGORY_ERRORS_NAME]) {
      subscription = watch((_, { name }) => {
        if (!name?.startsWith(PRODUCT_LABELS_FIELD_NAME)) return;

        clearErrors(SUBCATEGORY_ERRORS_NAME);
      });
    }

    return () => {
      if (!subscription) return;

      subscription.unsubscribe();
    };
  }, [errors, watch, clearErrors]);

  useEffect(() => {
    setValue(PRODUCT_LABELS_FIELD_NAME, customProductLabels, {
      shouldDirty: false,
    });
  }, [customProductLabels]);

  const default_label = {
    customizable_id: 1,
    product_type: 'flower',
    product_subtype: 'all',
    custom_label: undefined,
    customizable_type: 'ProductType',
    store_id: parseInt(storeId, 10),
  };

  const addCustomProductLabel = () => {
    append(default_label);
    track({
      event: EventNames.ModifiedSetting,
      setting_name: SettingNames.ProductSubcategoryLabel,
      action: 'add',
      revert: false,
      modal_name: ModalNames.FiltersAndLabels,
    });
  };

  const removeCustomProductLabel = (index: number) => {
    remove(index);
    track({
      event: EventNames.ModifiedSetting,
      setting_name: SettingNames.ProductSubcategoryLabel,
      action: 'delete',
      revert: false,
      modal_name: ModalNames.FiltersAndLabels,
    });
  };

  return (
    <>
      {productTypesFetched ? (
        <>
          <div>
            <Typography variant="body-bold">
              Product subcategory labels
            </Typography>
            <Typography>
              Customize how product subcategory names appear in your store.
            </Typography>
          </div>
          <Form.ErrorBanner name={SUBCATEGORY_ERRORS_NAME} />
          {productLabels.map((label, index) => (
            <CustomLabelRow
              productTypesMap={productTypes}
              removeItem={removeCustomProductLabel}
              existingLabel={label}
              key={label.id}
              index={index}
            />
          ))}
          <Flex justifyContent="center">
            <Button
              mt={24}
              variant="secondary"
              label="Add custom label"
              onClick={addCustomProductLabel}
            />
          </Flex>
        </>
      ) : null}
    </>
  );
};
