import * as t from 'io-ts';

const tFontFamily = t.type({
  url: t.string,
  font_family: t.string,
});

const tBloomStore = t.type({
  address: t.string,
  city: t.string,
  id: t.number,
  name: t.string,
  recreational: t.boolean,
  state: t.string,
  time_zone_identifier: t.string,
  zip: t.string,
});

const tColorHash = t.type({
  main: t.string,
  contrast: t.string,
});

const tLineageColorHash = t.type({
  cbd: tColorHash,
  hybrid: tColorHash,
  indica: tColorHash,
  sativa: tColorHash,
});

const tMenuLink = t.type({
  destination: t.string,
  label: t.string,
});

const tMenuItem = t.intersection([
  t.type({
    label: t.string,
  }),
  t.partial({
    destination: t.union([t.string, t.undefined]),
    links: t.array(tMenuLink),
  }),
]);

const tBloomTheme = t.partial({
  colors: t.partial({
    primary: tColorHash,
    secondary: tColorHash,
    header: tColorHash,
    deals: tColorHash,
    lineage: tLineageColorHash,
  }),
  fonts: t.partial({
    heading: tFontFamily,
    body: tFontFamily,
  }),
  product_card_size: t.union([
    t.literal('slim'),
    t.literal('standard'),
    t.literal('full'),
  ]),
  age_gate_cookie: t.union([t.string, t.null]),
  age_gate_enabled: t.union([t.boolean, t.null]),
  age_gate_message: t.string,
  main_menu: t.array(tMenuItem),
});

/**
 * The first iteration of the Bloom theme in which you could set values that pass
 * straight through to Reefer's theme provider.
 */
const tReeferTheme = t.partial({
  fonts: t.partial({
    default: t.type({
      url: t.string,
      fontFamily: t.string,
    }),
  }),
  colors: t.partial({
    text: t.partial({
      info: t.string,
      main: t.string,
      inverse: t.string,
    }),
    accent: t.string,
    background: t.string,
    supporting: t.string,
  }),
});

const tLogo = t.type({
  large: t.union([t.string, t.null]),
  medium: t.union([t.string, t.null]),
  small: t.union([t.string, t.null]),
  original: t.union([t.string, t.null]),
});

export const tBloomOperatorConfig = t.type({
  theme: t.intersection([tReeferTheme, t.partial({ bloom: tBloomTheme })]),
  menu_url: t.string,
  logo: t.union([tLogo, t.null]),
  id: t.number,
  stores: t.array(tBloomStore),
  google_sso_client_id: t.union([t.string, t.null]),
  google_sso_client_secret: t.union([t.string, t.null]),
});

export const tBloomMenuTemplateType = t.union([
  t.literal('footer'),
  t.literal('page'),
]);

export const tBloomMenuTemplate = t.type({
  id: t.number,
  source_content_selector: t.string,
  source_url: t.string,
  template_type: tBloomMenuTemplateType,
});

export type BloomOperatorConfig = t.TypeOf<typeof tBloomOperatorConfig>;
export type BloomStore = t.TypeOf<typeof tBloomStore>;
export type BloomTheme = t.TypeOf<typeof tBloomTheme>;
export type BloomFontFamily = t.TypeOf<typeof tFontFamily>;
export type BloomMenuTemplate = t.TypeOf<typeof tBloomMenuTemplate>;
export type BloomMenuTemplateType = t.TypeOf<typeof tBloomMenuTemplateType>;
export type BloomMenuItem = t.TypeOf<typeof tMenuItem>;
export type BloomMainMenuForm = {
  menuItems: BloomMenuItem[];
};
