import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import { ALL_DAYS } from '@jane/business-admin/util';
import { Button, CopyIcon, Flex, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { CopyHoursPopover } from './CopyHoursPopover';
import type { CopyToOtherDaysParams, StoreHourFormItem } from './HoursModal';

type Props = {
  copyPopoverTopAlignment: boolean;
  copyToOtherDays: ({
    tab,
    dayToCopyFrom,
    daysToCopyTo,
  }: CopyToOtherDaysParams) => void;
  day: string;
  hourOptions: { label: string; value: string }[];
  setTempDataOnChange: (
    tab: string,
    value: string | boolean,
    field: string
  ) => void;
  tab: string;
  tempFormData: StoreHourFormItem;
};

export const HourInputRow = ({
  tab,
  day,
  tempFormData,
  hourOptions,
  setTempDataOnChange,
  copyToOtherDays,
  copyPopoverTopAlignment,
}: Props) => {
  const enabledKey = `${day}_enabled` as keyof StoreHourFormItem;
  const startKey = `${day}_hours_start` as keyof StoreHourFormItem;
  const endKey = `${day}_hours_end` as keyof StoreHourFormItem;

  const triggerCopy = (days: string[]) => {
    copyToOtherDays({ tab, dayToCopyFrom: day, daysToCopyTo: days });
  };

  const isEnabled = useMemo(() => {
    if (!tempFormData) return false;
    return tempFormData[enabledKey];
  }, [JSON.stringify(tempFormData), day, enabledKey]);

  const copyHoursOptions = useMemo(() => {
    return ALL_DAYS.filter((item) => item !== day).map((relevantDay) => {
      return {
        value: relevantDay,
        label: capitalize(relevantDay),
      };
    });
  }, [day]);

  return (
    <Flex alignItems="center" mb={24} justifyContent="space-between">
      <Form.CheckboxField
        defaultChecked={isEnabled as boolean}
        label={capitalize(day)}
        name={`${tab}.${enabledKey}`}
        checked={isEnabled as boolean}
        onChange={(value) => setTempDataOnChange(tab, value, enabledKey)}
      />
      {isEnabled ? (
        <Flex width="70%">
          <Form.SelectField
            width="100%"
            labelHidden
            options={hourOptions}
            defaultValue={tempFormData[startKey] as string}
            name={`${tab}.${startKey}`}
            label={`${capitalize(day)} Start Time`}
            mr={24}
            onChange={(value) => setTempDataOnChange(tab, value, startKey)}
            placeholder="Select a start time"
            // TODO: Doesn't really work when switching between tabs, sets isValid to false if day is disabled
            // required={isEnabled as boolean}
          />
          <Form.SelectField
            width="100%"
            labelHidden
            options={hourOptions}
            defaultValue={tempFormData[endKey] as string}
            name={`${tab}.${endKey}`}
            label={`${capitalize(day)} End Time`}
            onChange={(value) => setTempDataOnChange(tab, value, endKey)}
            placeholder="Select an end time"
            // required={isEnabled as boolean}
          />
          <CopyHoursPopover
            target={
              <Button.Icon
                ml={8}
                label={`Copy ${day} hours to other days`}
                icon={<CopyIcon color="grays-mid" />}
              />
            }
            label="Copy hours to other days"
            triggerCopy={triggerCopy}
            options={copyHoursOptions}
            topAlignment={copyPopoverTopAlignment}
          />
        </Flex>
      ) : (
        <Flex width="70%" height={48} alignItems="center">
          <Typography color="grays-mid">Physical store closed</Typography>
        </Flex>
      )}
    </Flex>
  );
};
