import { useEffect, useMemo } from 'react';

import { useSaveStoreSmsSettings } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { SmsProvider } from '@jane/business-admin/types';
import type { SmsSetting, StoreV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { Flex, Modal, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
  useFormContext,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { PROVIDER_DISPLAY_MAPPING } from './SmsSettingsCard';

const FORM_ERROR_NAME = 'sms form errors';
export const SMS_PROVIDERS = Object.values(SmsProvider);

const SettingItem = ({
  children,
  provider,
  setting,
}: {
  children: React.ReactNode;
  provider: string;
  setting: SmsSetting | null;
}) => {
  const tokenKey = `${provider}.token`;
  const phoneKey = `${provider}.from_phone`;
  const twilioSidKey = `${provider}.display.account_sid`;

  const { watch } = useFormContext();
  const formEnabled = watch('enabled');

  return (
    <>
      {children}
      {formEnabled === provider && (
        <Flex mt={24}>
          <Form.TextField
            defaultValue={setting?.token || undefined}
            label="Token"
            name={tokenKey}
            data-testid={tokenKey}
            mr={24}
          />
          <Form.TextField
            defaultValue={setting?.from_phone || undefined}
            label="From phone number"
            name={phoneKey}
            data-testid={phoneKey}
            mr={24}
          />
          {provider === SmsProvider.twilio && (
            <Form.TextField
              defaultValue={setting?.meta?.account_sid || undefined}
              label="Account SID"
              name={twilioSidKey}
              data-testid={twilioSidKey}
              mr={24}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export const SmsSettingsModal = ({
  closeModal,
  open,
  setting,
  store,
}: {
  closeModal: () => void;
  open: boolean;
  setting: SmsSetting | null;
  store: StoreV2;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating sms settings. Please try again.'
  );
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const toast = useToast();
  const { mutateAsync: saveStoreSmsSettings, isSuccess: saveSuccess } =
    useSaveStoreSmsSettings(store.id.toString());

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'SMS settings updated',
        variant: 'success',
      });
      closeModal();
    }
  }, [saveSuccess]);

  const onSubmit = (formData: any) => {
    const formEnabled = formData.enabled;
    const disableAll = formEnabled === 'none';

    let modifiedSetting: SmsSetting | null = null;
    if (disableAll && setting) {
      modifiedSetting = {
        ...setting,
        _destroy: true,
      };
    } else if (!disableAll) {
      const formDataEntry = formData[formEnabled as SmsProvider];
      modifiedSetting = {
        id: setting?.id || null,
        meta: null,
        provider: formEnabled,
        token: formDataEntry.token,
        from_phone: formDataEntry.from_phone,
      };
      if (formDataEntry.display?.account_sid) {
        modifiedSetting['meta'] = {
          account_sid: formDataEntry.display.account_sid,
        };
      }
    }

    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.SmsSettings,
          changed_attributes: disableAll
            ? ['disabled_all']
            : Object.keys(dirtyFields),
        });
        return saveStoreSmsSettings({
          sms_provider: modifiedSetting,
        });
      },
      requestData: {
        sms_provider: modifiedSetting,
      },
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors['sms_provider'])
        );
      },
    });
  };

  const groupedSettings = useMemo(() => {
    return SMS_PROVIDERS.reduce<{ [key: string]: SmsSetting | null }>(
      (groupedData, value) => {
        const foundEntry = setting?.provider === value;
        if (foundEntry) {
          groupedData[value] = setting;
        }
        return groupedData;
      },
      {
        [SmsProvider.cm]: null,
        [SmsProvider.twilio]: null,
      }
    );
  }, [setting]);

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={closeModal}
      hasChanges={isDirty}
      modalName={ModalNames.SmsSettings}
    >
      <Form.BaseForm
        name="SMS settings form"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="SMS settings"
          subtitle={store.name}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />

          <Form.RadioFieldGroup
            name="enabled"
            defaultChecked={setting ? setting.provider : 'none'}
            options={[
              {
                id: 'none',
                label: 'No SMS provider',
                value: 'none',
              },
              ...SMS_PROVIDERS.map((provider) => ({
                id: provider,
                label: PROVIDER_DISPLAY_MAPPING[provider],
                value: provider,
                wrapper: (children: React.ReactNode) => (
                  <>
                    <Modal.ContentDivider />
                    <SettingItem
                      setting={groupedSettings[provider]}
                      provider={provider}
                    >
                      {children}
                    </SettingItem>
                  </>
                ),
              })),
            ]}
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
