import { Flex, Link, Typography } from '@jane/shared/reefer';
import { Form, useFormContext, useWatch } from '@jane/shared/reefer-hook-form';

type DefaultValue = string | { display: string; value: string };

export const RevertableInput = ({
  defaultValue,
  fromPOS,
  initialValue,
  label,
  name,
  onChange,
  required,
  notRevertable,
}: {
  defaultValue: DefaultValue;
  fromPOS?: boolean;
  initialValue?: string;
  label: string;
  name: string;
  notRevertable?: boolean;
  onChange?: (value: string) => void;
  required?: boolean;
}) => {
  const { setValue } = useFormContext();
  // Can't explain it but if you just use "watch" here,
  // you'll get an undefined value initially no matter how you set this field
  const value = useWatch({ name, defaultValue: initialValue });

  const defaultDisplayValue =
    typeof defaultValue === 'object' ? defaultValue?.display : defaultValue;

  const defaultRevertValue =
    typeof defaultValue === 'object' ? defaultValue?.value : defaultValue;

  const isDefaultValue = value === defaultRevertValue;

  const revert = () => {
    setValue(name, defaultRevertValue, { shouldDirty: true });
  };

  return (
    <div style={{ width: '100%' }}>
      <Form.TextField
        placeholder={label}
        label={label}
        name={name}
        defaultValue={initialValue || ''}
        onChange={onChange}
        required={!!required}
      />
      {!isDefaultValue && !notRevertable ? (
        <Flex justifyContent="space-between">
          <Typography variant="body" color="grays-mid" mt={8}>
            {fromPOS ? 'POS d' : 'D'}efault: {defaultDisplayValue}
          </Typography>
          <Link mt={8} onClick={revert}>
            Revert
          </Link>
        </Flex>
      ) : null}
    </div>
  );
};
