import { useFetchBloomMenus } from '@jane/business-admin/data-access';
import { SelectField } from '@jane/shared/reefer';

interface MenuSelectorProps {
  currentMenuId?: string;
  onChange: (newId: string) => void;
}

export const MenuSelector = ({
  currentMenuId,
  onChange,
}: MenuSelectorProps) => {
  const { data: bloomMenus } = useFetchBloomMenus();

  return (
    <SelectField
      mt={24}
      mb={40}
      ml={40}
      mr={40}
      name="bloom-menus"
      label="bloom-menus"
      labelHidden
      required
      value={currentMenuId}
      onChange={onChange}
      options={(bloomMenus ?? []).map(({ menu_url, id }) => ({
        label: `${menu_url}`,
        value: `${id}`,
      }))}
    />
  );
};
