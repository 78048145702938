import styled from '@emotion/styled';
import { useCallback, useContext, useEffect, useState } from 'react';

import { FulfillmentSettingsContext } from '@jane/business-admin/providers';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import type { DeliveryZipcode } from '@jane/shared/models';
import { Button, Flex, TrashIcon, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import {
  Form,
  useFieldArray,
  useFormContext,
} from '@jane/shared/reefer-hook-form';

import { DollarInput } from '../../../../../../../DollarInput';

// Using zip in a field name will make 1password extension show up
const FIELD_ARRAY_NAME = 'delivery_zcodes';

const DEFAULT_NEW_ZIPCODE = {
  zipcode: undefined,
  fee: 0,
  min_amount: 0,
  lead_time_minutes: 0,
  last_call_minutes: 0,
};

// This is needed to make sure the top-level labels line up with the inputs
// Matches the "TrashIcon" button sizing and spacing
const ButtonGap = styled.div({
  width: '100%',
  maxWidth: 48,
  ...spacing({ ml: 12 }),
});

const HeaderWrapper = styled.div({
  width: '100%',
});

const ZipcodeItem = ({
  index,
  setting,
  removeItem,
  usedZipcodes,
  updateUsedZipcodes,
}: {
  index: number;
  removeItem: (index: number) => void;
  setting: DeliveryZipcode;
  updateUsedZipcodes: (value: string, index: number) => void;
  usedZipcodes: string[];
}) => {
  const nameConcat = `${FIELD_ARRAY_NAME}.${index}`;
  const { setValue, watch } = useFormContext();
  const formFee = watch(`display.${nameConcat}.fee`);
  const formMinAmount = watch(`display.${nameConcat}.min_amount`);

  useEffect(() => {
    if (formFee !== undefined) {
      const converted =
        typeof formFee === 'string' ? parseFloat(formFee) : formFee;
      setValue(`${nameConcat}.fee`, converted);
    }
  }, [formFee]);

  useEffect(() => {
    if (formMinAmount !== undefined) {
      const converted =
        typeof formMinAmount === 'string'
          ? parseFloat(formMinAmount)
          : formMinAmount;
      setValue(`${nameConcat}.min_amount`, converted);
    }
  }, [formMinAmount]);

  const validateZipcodeInput = useCallback(
    (zipcodeValue: string) => {
      if (zipcodeValue) {
        const validLength = /^\d{5}$/.test(zipcodeValue);
        const notUnique = usedZipcodes.some(
          (value: string, i: number) => i !== index && value === zipcodeValue
        );
        if (!validLength) {
          return 'Only 5-digit US zipcodes are supported.';
        } else if (notUnique) {
          return 'Please enter a unique zipcode.';
        }
      }
      return true;
    },
    [index, usedZipcodes]
  );

  return (
    <Flex mb={24}>
      <Flex gap={32} width="100%">
        <Form.TextField
          defaultValue={setting.zipcode}
          name={`${nameConcat}.zipcode`}
          label="Zipcode"
          labelHidden
          width="100%"
          onChange={(value: string) => updateUsedZipcodes(value, index)}
          validate={validateZipcodeInput}
          required
        />
        <DollarInput
          defaultValue={parseFloat(setting.fee)}
          name={`display.${nameConcat}.fee`}
          label="Delivery fee"
          labelHidden
          required
        />
        <DollarInput
          defaultValue={parseFloat(setting.min_amount)}
          name={`display.${nameConcat}.min_amount`}
          label="Delivery minimum"
          labelHidden
          required
        />
        <Form.NumberField
          defaultValue={setting.lead_time_minutes}
          name={`${nameConcat}.lead_time_minutes`}
          label="Lead time"
          labelHidden
          endUnit="minutes"
          width="100%"
          required
        />
        <Form.NumberField
          defaultValue={setting.last_call_minutes}
          name={`${nameConcat}.last_call_minutes`}
          label="Last call"
          labelHidden
          endUnit="minutes"
          width="100%"
          required
        />
        <Button.Icon
          onClick={() => removeItem(index)}
          label="remove zipcode setting"
          icon={<TrashIcon />}
          ml={12}
        />
      </Flex>
    </Flex>
  );
};

export const ZipcodeSettings = () => {
  const {
    storePayload: { delivery_zipcodes },
  } = useContext(FulfillmentSettingsContext);

  const { control, setValue } = useFormContext();
  const {
    fields: zipcodeSettings,
    append,
    remove,
  } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME,
  });

  // Track used zipcodes to validate uniqueness as they are entered in the form
  const [usedZipcodes, setUsedZipcodes] = useState<string[]>([]);
  useEffect(() => {
    setUsedZipcodes((zipcodeSettings as any[]).map(({ zipcode }) => zipcode));
  }, [zipcodeSettings]);

  useEffect(() => {
    setValue(FIELD_ARRAY_NAME, delivery_zipcodes);
  }, [delivery_zipcodes, setValue]);

  useEffect(() => {
    if (!delivery_zipcodes.length) {
      addNewZipcode();
    }
  }, [delivery_zipcodes]);

  const addNewZipcode = () => {
    append(DEFAULT_NEW_ZIPCODE);
  };

  const removeZipcode = (index: number) => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: ModalNames.DeliverySettings,
      entity_name: 'zipcodes',
    });
    remove(index);
    removeUsedZipcode(index);
  };

  const removeUsedZipcode = (index: number) => {
    const allZipcodes = [...usedZipcodes];
    allZipcodes.splice(index, 1);
    setUsedZipcodes(allZipcodes);
  };

  const updateUsedZipcodes = (zipcodeValue: string, index: number) => {
    const allZipcodes = [...usedZipcodes];
    allZipcodes[index] = zipcodeValue;

    setUsedZipcodes(allZipcodes);
  };

  return (
    <Flex flexDirection="column" mt={40}>
      <Flex mb={20} gap={32} width="100%">
        <HeaderWrapper>
          <Typography>Zipcode</Typography>
        </HeaderWrapper>
        <HeaderWrapper>
          <Typography>Delivery fee</Typography>
        </HeaderWrapper>
        <HeaderWrapper>
          <Typography>Delivery minimum</Typography>
        </HeaderWrapper>
        <HeaderWrapper>
          <Typography>Lead time</Typography>
        </HeaderWrapper>
        <HeaderWrapper>
          <Typography>Last call</Typography>
        </HeaderWrapper>
        <ButtonGap />
      </Flex>

      {zipcodeSettings.map((setting, index) => (
        <ZipcodeItem
          key={setting.id}
          setting={setting as unknown as DeliveryZipcode}
          index={index}
          removeItem={removeZipcode}
          usedZipcodes={usedZipcodes}
          updateUsedZipcodes={updateUsedZipcodes}
        />
      ))}

      <Flex justifyContent="center" mt={8}>
        <Flex width={200}>
          <Button
            onClick={addNewZipcode}
            full
            label="Add new"
            variant="secondary"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
