import * as t from 'io-ts';

import {
  tBrandCondition,
  tKindCondition,
  tProductThresholdConditions,
  tPublicImage,
  tSchedule,
  tSpecialConditions,
  tWeightCondition,
} from '@jane/shared/models';

import { tId } from './common';

export const SpecialStatus = {
  live: 'live',
  upcoming: 'upcoming',
  expired: 'expired',
  disabled: 'disabled',
  archived: 'archived',
} as const;

type ValueOf<T> = T[keyof T];
export type SpecialStatus = ValueOf<typeof SpecialStatus>;

const tAbbreviatedSpecialV2Optional = t.partial({
  archived: t.boolean,
  // TODO: This can return either a UNIX timestamp (for global) or formatted date string (for store-specific), might want to consolidate
  archived_at: t.union([t.number, t.string, t.null]),
  description: t.union([t.string, t.null]),
  promo_code: t.union([t.string, t.null]),
  enabled: t.boolean,
  promo_code_max_number_of_uses: t.union([t.number, t.null]),
  multiple_use_promo_code: t.union([t.boolean, t.null]),
  reservation_modes: t.union([
    t.partial({
      kiosk: t.union([t.boolean, t.undefined]),
      delivery: t.union([t.boolean, t.undefined]),
      pickup: t.union([t.boolean, t.undefined]),
      curbside: t.union([t.boolean, t.undefined]),
    }),
    t.undefined,
  ]),
  terms: t.union([t.string, t.null]),
  pos_synced: t.boolean,
  pos_source: t.union([t.string, t.null]),
  pos_special_id: t.union([t.string, t.null]),
  pos_special_link: t.union([t.string, t.null]),
  discount_dollar_amount: t.number,
  discount_percent: t.number,
  discount_target_price: t.number,
  store_ids: t.array(tId),
  next_occurrence: t.union([t.number, t.null, t.string]),
});

const tAbbreviatedSpecialV2Required = t.interface({
  id: tId,
  title: t.string,
  discount_type: t.string,
  start_time: t.union([t.string, t.null]),
  end_time: t.union([t.string, t.null]),
  special_type: t.keyof({
    product: null,
    cart_total: null,
    qualified_group: null,
    bundle: null,
    bulk_pricing: null,
    spending_threshold: null,
  }),
  store_specific: t.boolean,
});

export const tAbbreviatedSpecialV2 = t.intersection([
  tAbbreviatedSpecialV2Optional,
  tAbbreviatedSpecialV2Required,
]);

const tSpecialsResponseMeta = t.interface({
  page: t.number,
  page_total: t.number,
  per_page: t.number,
  total: t.number,
  all_ids: t.array(t.number),
  counts_by_status: t.interface({
    live: t.number,
    upcoming: t.number,
    expired: t.number,
    disabled: t.number,
    archived: t.number,
  }),
});

export const tSpecialsIndexResponse = t.interface({
  specials: t.array(tAbbreviatedSpecialV2),
  meta: tSpecialsResponseMeta,
});

export const tSpecialDetailResponse = t.interface({
  special: tAbbreviatedSpecialV2,
});

const tWeightAndPriceCondition = t.type({
  price_id: t.number,
  maximum_price: t.number,
  minimum_price: t.number,
});

export const tSpecialRuleItemV2 = t.partial({
  brands: t.union([t.array(tBrandCondition), t.undefined]),
  product_ids: t.union([t.array(t.number), t.undefined]),
  kinds: t.union([t.array(tKindCondition), t.undefined]),
  lineages: t.union([t.array(t.string), t.undefined]),
  weights: t.union([t.array(tWeightCondition), t.undefined]),
  weights_and_prices: t.union([t.array(tWeightAndPriceCondition), t.undefined]),
});

// NOTE: There is a tSpecialRules in shared/models but that structure is not right
export const tSpecialRulesV2 = t.partial({
  includes: t.union([t.array(tSpecialRuleItemV2), t.undefined]),
  excludes: t.union([t.array(tSpecialRuleItemV2), t.undefined]),
  product_threshold: t.union([tProductThresholdConditions, t.undefined]),
  settings: t.union([t.type({ cart_threshold: t.number }), t.undefined]),
  max_applications_per_cart: t.union([t.number, t.null, t.undefined]),
  max_number_of_discounted_products: t.union([t.number, t.null, t.undefined]),
  threshold_number_of_items_in_cart: t.union([t.number, t.null, t.undefined]),
  dependent: t.union([
    t.partial({
      max_number_of_discounted_products: t.union([
        t.number,
        t.null,
        t.undefined,
      ]),
    }),
    t.undefined,
  ]),
  independent: t.union([
    t.partial({
      threshold_number_of_items_in_cart: t.union([
        t.number,
        t.null,
        t.undefined,
      ]),
    }),
    t.undefined,
  ]),
});

const tStoreSpecialV2 = t.intersection([
  tAbbreviatedSpecialV2,
  t.type({
    conditions: tSpecialConditions,
    custom_badge: t.union([t.null, t.string]),
    photo: t.union([t.null, tPublicImage]),
    rules: tSpecialRulesV2,
    stacking_setting: t.union([
      t.literal('yes'),
      t.literal('no'),
      t.literal('combinable'),
    ]),
    schedule: tSchedule,
    store_specific: t.boolean,
    use_store_stacking_setting: t.union([t.undefined, t.boolean]),
  }),
]);

export type AbbreviatedSpecialV2 = t.TypeOf<typeof tAbbreviatedSpecialV2>;
export type SpecialsIndexResponse = t.TypeOf<typeof tSpecialsIndexResponse>;
export type SpecialRuleItemV2 = t.TypeOf<typeof tSpecialRuleItemV2>;
export type SpecialRulesV2 = t.TypeOf<typeof tSpecialRulesV2>;
export type StoreSpecialV2 = t.TypeOf<typeof tStoreSpecialV2>;

// ----------------- GLOBAL ----------------- \\

export const tGlobalSpecialStore = t.type({
  _key: t.union([t.string, t.undefined]),
  address: t.union([t.string, t.undefined]),
  address2: t.union([t.string, t.null, t.undefined]),
  can_edit: t.union([t.boolean, t.undefined]),
  city: t.union([t.string, t.null, t.undefined]),
  enabled: t.union([t.boolean, t.undefined]),
  id: t.union([t.string, t.number]),
  name: t.string,
  recreational: t.union([t.boolean, t.undefined]),
  state: t.union([t.string, t.null, t.undefined]),
  zip: t.union([t.string, t.undefined]),
});
export type GlobalSpecialStore = t.TypeOf<typeof tGlobalSpecialStore>;

export const tAbbreviatedGlobalSpecialV2Optional = t.partial({
  archived: t.boolean,
  // TODO: This can return either a UNIX timestamp (for global) or formatted date string (for store-specific), might want to consolidate
  archived_at: t.union([t.number, t.string, t.null]),
  description: t.union([t.string, t.null]),
  promo_code: t.union([t.string, t.null]),
  enabled: t.boolean,
  promo_code_max_number_of_uses: t.union([t.number, t.null]),
  multiple_use_promo_code: t.union([t.boolean, t.null]),
  reservation_modes: t.union([
    t.partial({
      kiosk: t.union([t.boolean, t.undefined]),
      delivery: t.union([t.boolean, t.undefined]),
      pickup: t.union([t.boolean, t.undefined]),
      curbside: t.union([t.boolean, t.undefined]),
    }),
    t.undefined,
  ]),
  terms: t.union([t.string, t.null]),
  pos_synced: t.boolean,
  pos_source: t.union([t.string, t.null]),
  pos_special_id: t.union([t.string, t.null]),
  pos_special_link: t.union([t.string, t.null]),
  discount_dollar_amount: t.number,
  discount_percent: t.number,
  discount_target_price: t.number,
  stores: t.array(tGlobalSpecialStore),
  next_occurrence: t.union([t.number, t.null, t.string]),
});

const tAbbreviatedGlobalSpecialsV2 = t.intersection([
  tAbbreviatedGlobalSpecialV2Optional,
  tAbbreviatedSpecialV2Required,
]);

export const tGlobalSpecialDetailResponse = t.interface({
  special: tAbbreviatedGlobalSpecialsV2,
});

export const tGlobalSpecialsIndexResponse = t.interface({
  specials: t.array(tAbbreviatedGlobalSpecialsV2),
  meta: tSpecialsResponseMeta,
});

const tGlobalSpecialV2 = t.intersection([
  tAbbreviatedGlobalSpecialsV2,
  t.type({
    conditions: tSpecialConditions,
    custom_badge: t.union([t.null, t.string]),
    photo: t.union([t.null, tPublicImage]),
    rules: tSpecialRulesV2,
    stacking_setting: t.union([
      t.literal('yes'),
      t.literal('no'),
      t.literal('combinable'),
    ]),
    schedule: tSchedule,
  }),
]);

export type AbbreviatedGlobalSpecialsV2 = t.TypeOf<
  typeof tAbbreviatedGlobalSpecialsV2
>;
export type GlobalSpecialsIndexResponse = t.TypeOf<
  typeof tGlobalSpecialsIndexResponse
>;
export type GlobalSpecialV2 = t.TypeOf<typeof tGlobalSpecialV2>;
