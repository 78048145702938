import styled from '@emotion/styled';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import type { Control, UseFormWatch } from 'react-hook-form';

import { MainMenuUrlInput } from '@jane/business-admin/components';
import type { BloomMainMenuForm } from '@jane/business-admin/types';
import { SortableList } from '@jane/shared/components';
import { Flex, TrashIcon } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

const LabelFieldContainer = styled.div`
  width: 150px;
`;

const LabelField = styled(Form.TextField)`
  div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const UrlFieldContainer = styled.div`
  width: 100%;
  margin-right: 182px;
`;

const StyledWrapper = styled(Flex)(({ theme }) => ({
  position: 'relative',
  paddingLeft: '28px',

  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: '16px',
    bottom: 0,
    width: '2px',
    backgroundColor: theme.colors.grays.light,
  },
}));

const StyledSortableList = styled(SortableList)`
  gap: 4px !important;

  [aria-label='drag handle'] {
    margintop: '24px';
  }

  .sortable-element {
    alignitems: 'flex-start';
  }
`;

export const PremiumSubMenuItems = ({
  parentIndex,
  control,
  addSubItemClicked,
  setAddSubItemClicked,
  setDiscardFunction,
  watch,
}: {
  addSubItemClicked: number | null;
  control: Control<BloomMainMenuForm>;
  parentIndex: number;
  setAddSubItemClicked: Dispatch<SetStateAction<number | null>>;
  setDiscardFunction: React.Dispatch<React.SetStateAction<(() => void) | null>>;
  watch: UseFormWatch<BloomMainMenuForm>;
}) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `menuItems.${parentIndex}.links`,
  });

  const menuItems = watch(`menuItems.${parentIndex}.links`);

  /**
   * Mirrors the `deleteItem` function from the main page.
   * This sets a discard function in state so that the appropriate `remove`
   * function can be called when the user confirms the deletion.
   */
  const deleteItem = useCallback(
    (index: number) => {
      const item = menuItems?.[index];
      if (!item) {
        return;
      }
      const discardFunc = () => {
        remove(index);
      };
      if (item.destination || item.label) {
        setDiscardFunction(() => discardFunc);
      } else {
        discardFunc();
      }
    },
    [setDiscardFunction, menuItems, remove]
  );

  useEffect(() => {
    if (parentIndex === addSubItemClicked) {
      append({ label: '', destination: '' });
      setAddSubItemClicked(null);
    }
  }, [addSubItemClicked, append, parentIndex, setAddSubItemClicked]);

  return (
    <StyledWrapper flexDirection="column" gap={8} ml={24}>
      <StyledSortableList
        direction="vertical"
        items={fields}
        onChange={(_, oldIndex, newIndex) => {
          move(oldIndex, newIndex);
        }}
        renderItem={(item, idx) => {
          return (
            <Flex key={item.id}>
              <Controller
                name={`menuItems.${parentIndex}.links.${idx}.label`}
                control={control}
                rules={{ required: true, pattern: /\S+/ }}
                render={({ field }) => (
                  <LabelFieldContainer>
                    <LabelField
                      label=""
                      defaultValue={field.value}
                      placeholder="Add label"
                      {...field}
                    />
                  </LabelFieldContainer>
                )}
              />
              <Controller
                name={`menuItems.${parentIndex}.links.${idx}.destination`}
                control={control}
                rules={{ required: true, pattern: /\S+/ }}
                render={({ field }) => (
                  <UrlFieldContainer>
                    <MainMenuUrlInput field={field} />
                  </UrlFieldContainer>
                )}
              />
              <TrashIcon
                mt={24}
                size="lg"
                color="grays-mid"
                onClick={() => deleteItem(idx)}
                altText={`Remove sub-item ${parentIndex + 1}:${idx + 1}`}
              />
            </Flex>
          );
        }}
      />
    </StyledWrapper>
  );
};
