import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const QualifiedGroup = ({
  isCreateMode,
  name,
  options,
}: SpecialTypeProps['qualified_group'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { getValues, watch, setValue } = useFormContext();
  const qualifiedGroupValue = getValues('conditions.qualified_group');
  const qualifiedGroupWatch = watch('conditions.qualified_group');
  const specialTypeWatch = watch('special_type');

  const qualifiedGroupType = useMemo(
    () => qualifiedGroupWatch?.type || qualifiedGroupValue?.type,
    [qualifiedGroupWatch?.type, qualifiedGroupValue?.type]
  );

  const isSenior = useMemo(
    () => !!(qualifiedGroupType && qualifiedGroupType === 'senior'),
    [qualifiedGroupType]
  );

  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  useEffect(() => {
    if (
      !qualifiedGroupType &&
      isCreateMode &&
      specialTypeWatch === 'qualified_group'
    ) {
      setValue('conditions.qualified_group.type', 'senior');
    }
  }, []);

  return (
    <Flex justifyContent="space-between" width="100%" gap={24}>
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <Flex width="100%" gap={24} mb={24}>
          <Form.SelectField
            width={isSenior ? '100%' : '50%'}
            name={`${name}.type` || ''}
            label="Qualified group"
            options={options || []}
          ></Form.SelectField>
          {isSenior ? (
            <Form.NumberField
              name={`${name}.required_age`}
              label="Age requirement"
              required={isCreateMode}
              width="100%"
            ></Form.NumberField>
          ) : null}
        </Flex>
      ) : (
        <>
          <Flex width="50%" flexDirection="column" mb={24}>
            <Typography color="grays-black" variant="body-bold">
              Qualified group
            </Typography>
            <Typography color="grays-black" variant="body" mt={4}>
              {capitalize(qualifiedGroupType)}
            </Typography>
          </Flex>
          {isSenior ? (
            <Flex width="50%" flexDirection="column">
              <Typography color="grays-black" variant="body-bold">
                Age requirement
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {qualifiedGroupValue.required_age}
              </Typography>
            </Flex>
          ) : null}
        </>
      )}
    </Flex>
  );
};
