import { Controller } from 'react-hook-form';

import type { UnpublishedProduct } from '@jane/business-admin/types';
import { MODAL_CARD_WIDTH } from '@jane/business-admin/util';
import {
  Card,
  Flex,
  Grid,
  Image,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import {
  defaultProductPhotoUrl,
  unpublishedProductType,
} from '@jane/shared/util';

const LoadingImages = () => {
  return (
    <>
      {Array(1)
        .fill(null)
        .map((_, index) => {
          return (
            <Skeleton animate width="25%" key={`loading_${index}`}>
              <Skeleton.Bone
                height={'214px'}
                my={16}
                width="100%"
                borderRadius="xs"
              />
            </Skeleton>
          );
        })}
    </>
  );
};

interface ImagesProps {
  isLoading: boolean;
  unpublishedProduct: UnpublishedProduct;
}

export const ImagesCard = ({ isLoading, unpublishedProduct }: ImagesProps) => {
  const { category, image_urls, kind, root_subtype } =
    unpublishedProduct.pos_data;

  const stockPhoto =
    (kind &&
      defaultProductPhotoUrl({
        category: category,
        kind: kind,
        productRootSubtype: root_subtype,
        productType: unpublishedProductType(kind, category),
      })) ||
    '';

  const productImages =
    image_urls && image_urls.length > 0
      ? { photoUrls: image_urls, text: 'POS Images' }
      : { photoUrls: [stockPhoto], text: 'Jane Default Images' };

  const getProductImages = ({ defaultValue }: { defaultValue: any }) => {
    return (
      <Grid.Container direction="row" spacing={16}>
        {defaultValue.photoUrls.map((imageUrl: string) => (
          <Grid.Item xs={4}>
            <Image
              altText="product image"
              border
              borderRadius="rounded"
              src={imageUrl}
            />
          </Grid.Item>
        ))}
      </Grid.Container>
    );
  };

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex flexDirection="column" p={24} gap={16}>
          <Flex justifyContent="space-between" flexDirection="column">
            <Typography variant="header-bold" mb={20}>
              Images
            </Typography>
          </Flex>
          <Controller
            defaultValue={[stockPhoto]}
            name="photos"
            render={({ field: { onChange, value } }) => {
              return isLoading ? (
                <Flex justifyContent={'space-around'} width={'calc(100% + 3%)'}>
                  <LoadingImages />
                </Flex>
              ) : (
                getProductImages({
                  defaultValue: { ...productImages },
                })
              );
            }}
          />
        </Flex>
      </Card.Content>
    </Card>
  );
};
