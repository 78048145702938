import { useEffect, useState } from 'react';

import { useEmailContactUs } from '@jane/business-admin/data-access';
import type { ContactUsFormData } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { EventNames, track } from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Flex, InfoIcon, Modal, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

const FORM_ERROR_NAME = 'contact-us-error';
const options = [
  {
    id: 'account-issue',
    label: 'Your account',
    value: 'account',
  },
  {
    id: 'reservation-issue',
    label: 'A reservation',
    value: 'reservation',
  },
  {
    id: 'other-issue',
    label: 'Something else',
    value: 'other',
  },
];

export const ContactUsModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error contacting support. Please try again.'
  );
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const formName = 'Contact Support Form';

  const { mutateAsync: emailContactUs, isSuccess: emailSuccess } =
    useEmailContactUs();

  useEffect(() => {
    if (emailSuccess) {
      track({
        event: EventNames.ContactedUs,
      });
      // todo - add toast!
      setOpen(false);
    }
  }, [emailSuccess]);

  const onSubmit = (data: ContactUsFormData) => {
    return catchSubmitErrors({
      submitMethod: () => emailContactUs(data),
      requestData: data,
      onValidationError: () => null,
    });
  };

  return (
    <ConfirmDiscardWrapper
      open={open}
      setOpen={setOpen}
      hasChanges={formIsDirty}
    >
      <Form
        name={formName}
        onDirty={setFormIsDirty}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Contact support"
          actions={<Form.SubmitButton variant="primary" label="Send" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex flexDirection="column">
            <Flex mb={32} flexDirection="column">
              <Form.RadioFieldGroup
                name="reason"
                options={options}
                legend="What are you having an issue with?"
                required
              />
            </Flex>
            <Form.TextAreaField
              label="Describe your issue"
              name="description"
              width="100%"
              required
            />
            <Flex mt={24}>
              <InfoIcon />
              <Typography ml={12}>
                This message will be sent to support@iheartjane.com
              </Typography>
            </Flex>
          </Flex>
        </Modal.Content>
      </Form>
    </ConfirmDiscardWrapper>
  );
};
