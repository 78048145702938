import styled from '@emotion/styled';
import type { MaskitoOptions } from '@maskito/core';
import { useMaskito } from '@maskito/react';
import snakeCase from 'lodash/snakeCase';

import { alwaysStartsWith } from '@jane/business-admin/util';
import { Card, Flex, Image, Typography } from '@jane/shared/reefer';
import type { LabeledOption } from '@jane/shared/types';

import colorWheel from './color-wheel.png';

export interface ColorPickerProps extends LabeledOption {
  hasError?: boolean;
  onBlur?: () => void;
  onChange: (hex: string) => void;
}

const ColorPreview = styled(Card.Content)<{ hexColor: string }>(
  ({ hexColor }) => ({
    cursor: 'crosshair',
    backgroundColor: hexColor,
    minHeight: '88px',
    minWidth: '147px',
  })
);

const HexField = styled('input')<{ hasError?: boolean }>(
  ({ theme, hasError }) => ({
    border: `solid ${hasError ? '#F33' : theme.colors.grays.light} 1px`,
    borderRadius: '12px',
    padding: '12px',
  })
);

const ColorField = styled('input')({
  position: 'absolute',
  left: '-20%', // somewhat centers the Chromium native color picker beneath the card
  visibility: 'hidden',
});

const ColorPickerContainer = styled(Flex)(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const ColorIconContainer = styled(Flex)(({ theme }) => ({
  cursor: 'pointer',
  height: '48px',
  width: '46px',
  paddingLeft: '11px',
  paddingRight: '11px',
  paddingTop: '12px',
  paddingBottom: '12px',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: theme.colors.grays.ultralight,
  },
  justifyContent: 'center',
  alignItems: 'center',
}));

const ColorContentContainer = styled(Flex)(({ theme }) => ({
  borderTop: `1px solid ${theme.colors.grays.ultralight}`,
  paddingTop: '12px',
  paddingBottom: '16px',
  paddingLeft: '8px',
  paddingRight: '16px',
  gap: '8px',
}));

const hexColorMask: MaskitoOptions = {
  mask: /^#[0-9a-fA-F]{0,6}$/,
  preprocessors: [alwaysStartsWith('#')],
};

export const ColorPicker = ({
  label,
  onBlur,
  onChange,
  value,
  hasError,
}: ColorPickerProps) => {
  const inputRef = useMaskito({ options: hexColorMask });
  const inputId = snakeCase(label);
  return (
    <label htmlFor={inputId}>
      <Flex alignItems="center" flexDirection="column" mr={24}>
        <Typography variant="mini-bold" color="grays-mid" mb={8}>
          {label}
        </Typography>
        <Card.Group width="177px" height="136px">
          <ColorPreview hexColor={value}>&nbsp;</ColorPreview>
          <ColorContentContainer>
            <ColorPickerContainer>
              <ColorIconContainer>
                <Image
                  altText="color-wheel"
                  src={colorWheel}
                  width="24px"
                  height="24px"
                />
              </ColorIconContainer>
              <HexField
                ref={inputRef}
                type="text"
                onInput={(e) => onChange(e.currentTarget.value)}
                onBlur={onBlur}
                value={value}
                maxLength={7}
                size={9}
                hasError={hasError}
              />
            </ColorPickerContainer>
            <ColorField
              id={inputId}
              type="color"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </ColorContentContainer>
        </Card.Group>
      </Flex>
    </label>
  );
};
