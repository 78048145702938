import { useCallback, useEffect, useState } from 'react';

import type { LeadTimeUnit } from '@jane/business-admin/util';
import { updateDurationOnChange } from '@jane/business-admin/util';
import { Flex, Grid, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';
import { duration } from '@jane/shared/util';

const MAX_MINUTES_MIN_LEAD_TIME = 24 * 60;
const MAX_MINUTES_MAX_LEAD_TIME = 24 * 60 * 7;

export const InputGroup = ({
  minutes,
  isMin,
  hideValidationDescriptionText,
  handleLeadTimeTracking,
}: {
  handleLeadTimeTracking?: (settingUnit: string, isMin: boolean) => void;
  hideValidationDescriptionText?: boolean;
  isMin: boolean;
  minutes: number | null | undefined;
}) => {
  const { setValue } = useFormContext();
  const [leadTime, setLeadTime] = useState(duration(minutes || 0, 'minutes'));

  useEffect(() => {
    // This is what we'll actually save on the backend
    // the "display" fields are just for separating hour/minute/second
    setValue(
      `${isMin ? 'min' : 'max'}_lead_time_minutes`,
      leadTime.asMinutes()
    );
  }, [JSON.stringify(leadTime)]);

  const title = isMin ? 'Minimum' : 'Maximum';

  const onChange = (unitToUpdate: LeadTimeUnit) => (value: number) => {
    const newDurationInMinutes = updateDurationOnChange(
      unitToUpdate,
      value,
      leadTime
    );

    handleLeadTimeTracking && handleLeadTimeTracking(unitToUpdate, isMin);

    setLeadTime(duration(newDurationInMinutes, 'minutes'));
  };

  const positiveWholeAndZeroNumberRegex = /^\d+$/;

  const inputInvalid = useCallback(
    (value: string) => {
      const maxMinutes = isMin
        ? MAX_MINUTES_MIN_LEAD_TIME
        : MAX_MINUTES_MAX_LEAD_TIME;
      if (leadTime.asMinutes() > maxMinutes) {
        return `Please enter less than ${
          isMin ? '1 day' : '7 days'
        } for lead time.`;
      } else if (value && !positiveWholeAndZeroNumberRegex.test(value)) {
        return 'Please enter a whole, numeric value greater than or equal to 0.';
      } else {
        return true;
      }
    },
    [JSON.stringify(leadTime)]
  );

  return (
    <Flex flexDirection="column" mb={hideValidationDescriptionText ? 0 : 24}>
      <Typography mb={16}>{`${title} Lead Time`}</Typography>
      <Grid.Container spacing={40}>
        <Grid.Item xs={4}>
          <Form.NumberField
            defaultValue={leadTime.days()}
            width="100%"
            // TODO: This name should be `display.${variant}_days`, but validate has issues with that and will end up in a permanent invalid state https://app.clickup.com/t/8669kqtxf
            name={`display_${isMin ? 'min' : 'max'}_days`}
            labelHidden
            label={`${title} Lead Time Days`}
            endUnit="days"
            onChange={onChange('days')}
            max={isMin ? 1 : 7}
            placeholder="0"
            validate={(value: string) => inputInvalid(value)}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Form.NumberField
            defaultValue={leadTime.hours()}
            width="100%"
            labelHidden
            // TODO: This name should be `display.${variant}_days`, but validate has issues with that and will end up in a permanent invalid state https://app.clickup.com/t/8669kqtxf
            name={`display_${isMin ? 'min' : 'max'}_hours`}
            label={`${title} Lead Time Hours`}
            endUnit="hours"
            onChange={onChange('hours')}
            max={23}
            placeholder="0"
            validate={(value: string) => inputInvalid(value)}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <Form.NumberField
            defaultValue={leadTime.minutes()}
            width="100%"
            labelHidden
            // TODO: This name should be `display.${variant}_days`, but validate has issues with that and will end up in a permanent invalid state https://app.clickup.com/t/8669kqtxf
            name={`display_${isMin ? 'min' : 'max'}_minutes`}
            label={`${title} Lead Time Minutes`}
            endUnit="minutes"
            onChange={onChange('minutes')}
            max={59}
            placeholder="0"
            validate={(value: string) => inputInvalid(value)}
          />
        </Grid.Item>
      </Grid.Container>
      {!hideValidationDescriptionText && (
        <Typography color="grays-mid" mt={16}>
          {`Up to ${isMin ? '1 day' : '7 days'}`}
        </Typography>
      )}
    </Flex>
  );
};
