import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { useFetchMultipleGlobalProductDetails } from '@jane/business-admin/data-access';
import type { GlobalSpecialProduct } from '@jane/business-admin/types';

// TODO: Would this be better as a bulk endpoint?
export const useGetGlobalProductDetails = (
  product_ids: number[],
  enabled: boolean
) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<GlobalSpecialProduct[]>();
  const productsQueries = useFetchMultipleGlobalProductDetails(
    product_ids || [],
    enabled
  );

  useEffect(() => {
    const totalQueries = product_ids?.length || 0;
    let finishedQueries = 0;
    const returnedProducts = productsQueries.flatMap((query) => {
      if (query.isError) {
        const statusCode = get(query, 'failureReason.response.status', 0);
        // Only show error if server error rather than missing/auth error
        if (statusCode.toString().startsWith('5')) {
          setHasError(true);
        }
      }
      if (query.isLoading) {
        setIsLoading(true);
      }
      if (query.isFetched) {
        finishedQueries++;
        if (finishedQueries === totalQueries) {
          setIsLoading(false);
        }
        if (!query?.data?.data) {
          return [];
        }
        const product = (query.data as any)?.data;
        return {
          ...product,
          product_id: product.id,
        };
      }
      return [];
    });
    setData(returnedProducts);
  }, [JSON.stringify(productsQueries), product_ids?.length]);

  return { data, hasError, isLoading };
};
