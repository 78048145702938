import isEmpty from 'lodash/isEmpty';
import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useFetchUnpublishedProducts } from '@jane/business-admin/data-access';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import { useToast } from '@jane/shared/reefer';

import { ProductModal } from './ProductModal';
import { UnpublishedProductModal } from './unpublished/UnpublishedProductModal';

/*
  TODO: Ideally this doesn't exist and we load the product data within the modal,
  but then we'll need loading states while there is no product data
**/
export const ModalDecider = () => {
  const { type = 'published' } = useParams<'type'>();
  const { product_id = '' } = useParams<'product_id'>();

  const { storeId } = useContext(StoreDetailsContext);
  const navigate = useNavigate();

  const toast = useToast();

  const isUnpublished = useMemo(() => {
    return type === 'unpublished';
  }, [type]);

  const { data: unpublishedData, isLoading: unpublishedLoading } =
    useFetchUnpublishedProducts(
      storeId,
      {
        unmapped_product_id: product_id,
      },
      !isUnpublished
    );

  const unpublishedProductData = useMemo(
    () =>
      unpublishedData?.pages?.flatMap((page) => page.unpublished_products) ??
      [],
    [JSON.stringify(unpublishedData)]
  );

  const isUnpublishedEmpty = useMemo(
    () => isEmpty(unpublishedProductData),
    [JSON.stringify(unpublishedProductData)]
  );

  if (
    (isUnpublished && unpublishedLoading) ||
    (isUnpublished && !unpublishedProductData)
  ) {
    return null;
  }

  if (isUnpublished && isUnpublishedEmpty) {
    toast.add({
      label: 'Product could not be found.',
      variant: 'error',
    });
    if (isUnpublished) {
      navigate(`/stores/${storeId}/products/published`);
    } else {
      navigate(`/stores/${storeId}/products/unpublished`);
    }
    return null;
  }

  if (isUnpublished) {
    return <UnpublishedProductModal product={unpublishedProductData[0]} />;
  }
  return <ProductModal tableType={type} />;
};
