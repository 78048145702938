import { useEffect, useMemo } from 'react';

import { useManager, useUpdateProfile } from '@jane/business-admin/data-access';
import type { UpdateProfileParams } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { EventNames, track } from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Flex, Modal } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

const FORM_ERROR_NAME = 'edit-profile-error';

export const EditProfileModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating profile. Please try again.',
    [['first_name'], ['last_name'], ['phone']]
  );
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const formName = 'Edit profile form';

  const { data: profile, isFetching } = useManager();
  const {
    mutateAsync: updateProfile,
    isSuccess,
    isLoading: isSaving,
  } = useUpdateProfile();

  const onSubmit = (data: UpdateProfileParams) => {
    return catchSubmitErrors({
      submitMethod: () => updateProfile(data),
      requestData: data,
      onValidationError: () => null,
    });
  };

  const disableFields = useMemo(
    () => isSaving || isFetching,
    [isSaving, isFetching]
  );

  useEffect(() => {
    if (isSuccess) {
      track({
        event: EventNames.EditedUserProfile,
        changed_attributes: Object.keys(dirtyFields),
      });
      setOpen(false);
    }
  }, [isSuccess, dirtyFields]);

  return (
    <ConfirmDiscardWrapper
      open={open}
      setOpen={setOpen}
      hasChanges={isDirty}
      variant="standard-dialogue"
    >
      <Form.BaseForm
        formMethods={formMethods}
        name={formName}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Edit profile"
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex flexDirection="column">
            <Flex mb={24}>
              <Form.TextField
                label="First name"
                name="first_name"
                defaultValue={profile?.manager?.first_name}
                width="100%"
                mr={12}
                required
                disabled={disableFields}
              />
              <Form.TextField
                label="Last name"
                name="last_name"
                defaultValue={profile?.manager?.last_name}
                width="100%"
                ml={12}
                required
                disabled={disableFields}
              />
            </Flex>
            <Flex>
              <Form.TextField
                label="Email"
                name="email"
                type="email"
                defaultValue={profile?.manager?.email}
                width="100%"
                mr={12}
                required
                disabled={disableFields}
              />
              <Form.TextField
                label="Phone number"
                name="phone"
                type="tel"
                defaultValue={profile?.manager?.phone}
                width="100%"
                ml={12}
                required
                disabled={disableFields}
              />
            </Flex>
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};
