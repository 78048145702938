import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const Bundle = ({
  isCreateMode,
  name,
}: SpecialTypeProps['bundle'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();
  const bundleValue = getValues('conditions.bundle');

  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      gap={24}
      flexDirection="column"
    >
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <Flex width="100%" gap={24} mb={24}>
          <Form.NumberField
            width={'100%'}
            name="display.bundle.max_applications_per_cart"
            label={'Maximum uses per order'}
          />
        </Flex>
      ) : (
        <Flex gap={24} mb={12}>
          <Flex width="50%" flexDirection="column" mb={12}>
            <Typography
              color="grays-black"
              variant="body-bold"
              data-testid="non-janepos-synced-bundle-max-uses"
            >
              Maximum uses per order
            </Typography>
            <Typography color="grays-black" variant="body" mt={4}>
              {bundleValue.max_applications_per_cart || 'N/A'}
            </Typography>
          </Flex>
          <Flex width="50%" flexDirection="column" mb={12}>
            <Typography
              color="grays-black"
              variant="body-bold"
              data-testid="non-janepos-synced-bundle-allow-discounts"
            >
              Allow qualifying products to be discounted
            </Typography>
            <Typography color="grays-black" variant="body" mt={4}>
              {bundleValue.settings.allow_discounts_on_required_products
                ? 'Allowed'
                : 'N/A'}
            </Typography>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
