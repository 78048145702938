import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import type { RowSelectionState, SortingState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  fetchProductQueryKey,
  useFetchProducts,
  useUpdateProductVisibility,
} from '@jane/business-admin/data-access';
import { useHasPermissions } from '@jane/business-admin/hooks';
import { ProductsTableContext } from '@jane/business-admin/providers';
import type {
  MenuProductForProductsTable,
  ProductIndexResponse,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Box, useToast } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { BaseProductTable } from './BaseProductTable';
import { EmptyState } from './EmptyStates';
import { buildPublishedAndHiddenColumns } from './PublishedAndHiddenColumns';
import { filterParams, sortParams } from './PublishedProductTable';

export const HiddenProductTable = ({
  columnFiltersParent,
  isBulkEditTable = false,
  selectedProducts,
  setSelectedProducts,
  setColumnFiltersParent,
  bulkEditModalOpen,
}: {
  bulkEditModalOpen: boolean;
  columnFiltersParent: any;
  isBulkEditTable?: boolean;
  selectedProducts: RowSelectionState;
  setColumnFiltersParent?: any;
  setSelectedProducts: any;
}) => {
  const userCanEditProducts = useHasPermissions([Permission.EditProducts]);
  const { setCanEditProducts } = useContext(ProductsTableContext);

  const allProductsSearchFlagEnabled = useFlag(
    FLAGS.scAllProductsSearchFrontend
  );

  useEffect(() => {
    setCanEditProducts(userCanEditProducts);
  }, [userCanEditProducts]);

  const formMethods = useForm();

  const { id = '' } = useParams<'id'>();
  const toast = useToast();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { pathname } = useLocation();
  const openUnpublishedModal = (row: MenuProductForProductsTable) => {
    track({
      event: EventNames.OpenedModal,
      modal_name: ModalNames.UpdateMenuProduct,
      url: normalizePath(pathname, id),
    });
    navigate(`${row.id}`);
  };

  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useFetchProducts(id, {
    status: 'hidden',
    ...filterParams(columnFiltersParent),
    ...sortParams(sorting),
  });
  const {
    mutateAsync,
    isSuccess: visibilitySuccess,
    isError: visibilityError,
  } = useUpdateProductVisibility(id);

  const updateProductVisibility = useCallback(
    (productId: string, visible: boolean) => {
      mutateAsync({ data: { visible }, productId });
    },
    []
  );

  const totalRowCount = data?.pages?.[0]?.meta?.total ?? 0;

  const columns = useMemo(
    () =>
      buildPublishedAndHiddenColumns({
        isBulkEditTable,
        isFetched,
        updateProductVisibility,
        totalRowCount,
        sorting,
      }),
    [
      JSON.stringify(data),
      isBulkEditTable,
      isFetched,
      updateProductVisibility,
      totalRowCount,
      sorting,
    ]
  );

  const allRows = useMemo(
    () =>
      data?.pages
        ?.flatMap((page) => page.meta.all_product_ids)
        .reduce(
          (a, v) => ({
            ...a,
            [allProductsSearchFlagEnabled ? v.menu_product_id.toString() : v]:
              true,
          }),
          {}
        ) ?? {},
    [JSON.stringify(data)]
  );

  const productData = useMemo(
    () => data?.pages?.flatMap((page) => page.products) ?? [],
    [JSON.stringify(data)]
  );

  const queryClient = useQueryClient();
  const totalRowsFromCache =
    queryClient.getQueryData<InfiniteData<ProductIndexResponse>>(
      fetchProductQueryKey(id, {
        status: 'hidden',
      })
    )?.pages?.[0]?.meta?.total ?? 0;

  const reactTable = useReactTable({
    data: productData,
    columns,
    state: {
      columnFilters: columnFiltersParent,
      sorting,
      rowSelection: selectedProducts,
    },
    onColumnFiltersChange: setColumnFiltersParent,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedProducts,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.id.toString(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  useEffect(() => {
    if (visibilitySuccess) {
      toast.add({
        label: 'Product visibility successfully changed',
        variant: 'success',
      });
    }

    if (visibilityError) {
      toast.add({
        label: 'Product visibility change failed',
        variant: 'error',
      });
    }
  }, [visibilitySuccess, visibilityError]);

  useEffect(() => {
    if (!bulkEditModalOpen) {
      setColumnFiltersParent([]);
    }
  }, [id]);

  const navigate = useNavigate();
  const handleRowClick = (row: any) => {
    if (isBulkEditTable || !userCanEditProducts) {
      return;
    }

    openUnpublishedModal(row);
  };

  return (
    <Box height="100%">
      {isFetched && totalRowsFromCache === 0 ? (
        <EmptyState variant="hidden" />
      ) : (
        <Form.BaseForm
          formMethods={formMethods}
          onSubmit={() => null}
          name="test"
        >
          <BaseProductTable
            allIds={allRows}
            table={reactTable}
            productData={productData}
            fetchNextPage={fetchNextPage}
            isBulkEditTable={isBulkEditTable}
            isFetched={isFetched}
            isFetching={isFetching && !isFetchingNextPage}
            isSuccess={isSuccess}
            handleRowClick={handleRowClick}
            hasNextPage={hasNextPage || false}
            tableType="hidden"
          />
        </Form.BaseForm>
      )}
    </Box>
  );
};
