import { Fragment, useEffect, useMemo } from 'react';

import type { StoreDismissalMessage } from '@jane/business-admin/types';
import { EventNames, ModalNames, track } from '@jane/business-admin/util';
import { Button, Flex } from '@jane/shared/reefer';
import { useFieldArray, useFormContext } from '@jane/shared/reefer-hook-form';

import { DismissalMessageFormFields } from './DismissalMessageFormFields';
import type { NotificationSettingsForm } from './form';
import { DISMISSAL_MESSAGES_FIELD } from './form';

interface Props {
  messages: StoreDismissalMessage[];
}

export const DismissalMessageSettings = ({ messages }: Props) => {
  const { control, setValue } = useFormContext<NotificationSettingsForm>();
  const {
    append,
    update,
    remove,
    fields: dismissalMessages,
  } = useFieldArray({
    control,
    name: DISMISSAL_MESSAGES_FIELD,
    keyName: '_key',
  });

  useEffect(() => {
    setValue(DISMISSAL_MESSAGES_FIELD, messages);

    if (!messages.length) {
      append({ message: '' });
    }
  }, [setValue, messages]);

  const visibleMessages = useMemo(
    () => dismissalMessages.filter((message) => !message._destroy),
    [dismissalMessages]
  );

  const addMoreLabel = useMemo(
    () =>
      visibleMessages.length
        ? 'Add another dismissal message'
        : 'Add a dismissal message',
    [visibleMessages.length]
  );

  const deleteMessage = (message: StoreDismissalMessage, index: number) => {
    track({
      event: EventNames.DeleteStoreEntity,
      modal_name: ModalNames.AdditionalDismissalMessages,
      entity_name: 'dismissal_notifications',
    });
    if (!message.id) {
      // remove from client side field array if not persisted
      remove(index);
      return;
    }
    // otherwise, mark for destruction on the server
    update(index, {
      ...message,
      _destroy: true,
    });
  };

  const addMessage = () => {
    append({
      message: '',
    });
  };

  return (
    <>
      {visibleMessages.map((message, index) => (
        <Fragment key={message._key}>
          <DismissalMessageFormFields
            message={message}
            index={index}
            onDelete={deleteMessage}
          />
        </Fragment>
      ))}
      <Flex width="100%" justifyContent="center">
        <Button variant="secondary" label={addMoreLabel} onClick={addMessage} />
      </Flex>
    </>
  );
};
