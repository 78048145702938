import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeConditionsInputProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const Bundle = ({
  isCreateMode,
  name,
  options,
}: SpecialTypeConditionsInputProps['bundle'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();
  const bundleValue = getValues('display.bundle');

  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      gap={24}
      flexDirection="column"
    >
      {options?.map((option) => {
        return isCreateMode || (!isCreateMode && showInputs) ? (
          <Flex width="100%" gap={24} mb={24} key={option.value}>
            <Form.NumberField
              width={'100%'}
              name={`${name}.${option.value}`}
              label={option.label}
              defaultValue={getValues(`${name}.${option.value}`)}
              min={1}
              required
            />
          </Flex>
        ) : (
          <Flex gap={24} key={option.value}>
            <Flex width="50%" flexDirection="column">
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid={`non-janepos-synced-bundle-min-products-${
                  option.rule === 'required_rules' ? 'required' : 'discounted'
                }`}
              >
                {option.label}
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {bundleValue?.[option.value] || 'N/A'}
              </Typography>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
