import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import pluralise from 'pluralise';
import { useEffect, useState } from 'react';

import type {
  BulkUpdateResponse,
  BulkUpdateSpecialParams,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { ModalNames } from '@jane/business-admin/util';
import { type StackingOptions } from '@jane/shared/models';
import { Banner, Flex, Modal, Typography, useToast } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../ConfirmWrapperWithTracking';

const FORM_ERROR_NAME = 'stacking-settings-errors';

type FormData = {
  bulk_stacking_setting: StackingOptions;
};
export const StackingSettingsModal = ({
  bulkSelectedSpecials,
  closeBulkEditModal,
  onClose,
  bulkUpdate,
  bulkUpdateSuccess,
  bulkUpdateLoading,
}: {
  bulkSelectedSpecials: number[];
  bulkUpdate: UseMutateAsyncFunction<
    BulkUpdateResponse,
    unknown,
    BulkUpdateSpecialParams,
    unknown
  >;
  bulkUpdateLoading: boolean;
  bulkUpdateSuccess: boolean;
  closeBulkEditModal: () => void;
  onClose: () => void;
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const toast = useToast();

  const catchSubmitErrors = useCatchErrorsWithManager('');

  const [showStackingError, setShowStackingError] = useState(false);

  useEffect(() => {
    if (bulkUpdateSuccess) {
      onClose();
      closeBulkEditModal();
      toast.add({
        label: `You have successfully updated settings for ${
          bulkSelectedSpecials.length
        } ${pluralise(bulkSelectedSpecials.length, 'special')}.`,
        variant: 'success',
      });
    }
  }, [bulkUpdateSuccess]);

  const onSubmit = (data: FormData) => {
    const params = {
      special_ids: bulkSelectedSpecials,
      attributes: {
        stacking_setting: data.bulk_stacking_setting,
      },
    };
    catchSubmitErrors({
      submitMethod: () => bulkUpdate(params),
      requestData: params,
      callback: () => setShowStackingError(true),
      onValidationError: () => null,
    });
  };

  return (
    <ConfirmWrapperWithTracking
      appId={'app'}
      open={true}
      setOpen={() => onClose()}
      hasChanges={isDirty}
      background="grays-ultralight"
      modalName={ModalNames.BulkEditStackingSettings}
      variant={'standard-dialogue'}
    >
      <Form
        onDirty={(dirty: boolean) => setIsDirty(dirty)}
        name="special settings"
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={'Specials stacking'}
          actions={
            <Form.SubmitButton
              label="Save"
              variant="primary"
              data-testid="special-bulk-save"
              disabled={bulkUpdateLoading}
            />
          }
        />
        <Modal.Content>
          {showStackingError && (
            <Banner
              full
              variant="error"
              label="Error updating stacking settings. Please try again."
            />
          )}
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Typography color="grays-black" variant="body-bold" maxWidth={250}>
              Can this special be used while other specials are present in the
              cart?
            </Typography>
            <Flex alignItems="center" mt={24}>
              <Form.RadioFieldGroup
                defaultChecked="yes"
                row
                name="bulk_stacking_setting"
                onChange={() => setShowStackingError(false)}
                disabled={bulkUpdateLoading}
                options={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: 'no',
                  },
                  {
                    id: 'combinable',
                    label: 'Only with other stackable specials',
                    value: 'combinable',
                  },
                ]}
              />
            </Flex>
          </Flex>
        </Modal.Content>
      </Form>
    </ConfirmWrapperWithTracking>
  );
};
