import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import type { ProductThresholdConditions } from '@jane/shared/models';
import {
  Button,
  Flex,
  Modal,
  NumberField,
  Typography,
} from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import type { ConditionType } from '../form';
import { FORM_FIELD_DEFAULT_MARGIN } from '../form';

interface Props {
  conditions: ProductThresholdConditions;
  fieldPrefix: string;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: ProductThresholdConditions) => void;
  type: ConditionType.Price;
}

/**
 * Component for each condition line item in building conditions for a Special. This component is hardcoded with two inputs for price conditions.
 *
 * @param conditions: Min and max price conditions
 * @param fieldPrefix: Used for input name fields, to prevent duplicates when there are two conditions cards in the case of BOGO specials
 * @param onConditionsUpdate: Callback when price conditions are changed in the inputs
 * @param type: Type of condition
 * @param lastCondition: Removes bottom border if true
 */
export const PriceConditions = ({
  conditions: conditionsProp,
  fieldPrefix,
  onConditionsUpdate,
  type,
  lastCondition,
}: Props) => {
  const { setError, clearErrors, trigger } = useFormContext();
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { isDisabled: globalSpecialDisabled } = useContext(
    GlobalSpecialsModalContext
  );

  const [conditions, setConditions] = useState(conditionsProp);
  const [validationError, setValidationError] = useState<string>();

  useEffect(() => {
    setExpanded(!emptyConditions);
    setConditions(conditionsProp);
  }, [JSON.stringify(conditionsProp)]);

  const emptyConditions = useMemo(() => {
    return (
      isEmpty(conditionsProp) ||
      (conditionsProp.maximum_price === 0 && conditionsProp.minimum_price === 0)
    );
  }, [conditionsProp]);

  const [expanded, setExpanded] = useState(!emptyConditions);

  const removeCondition = () => {
    setValidationError(undefined);
    clearErrors('price-error');
    trigger();

    setConditions({});
    setExpanded(false);

    onConditionsUpdate({});
  };

  const validateFields = (
    type: 'maximum_price' | 'minimum_price',
    value: number | string | undefined,
    conditions: ProductThresholdConditions
  ) => {
    setValidationError(undefined);
    clearErrors('price-error');
    trigger();

    const oppositeType =
      type === 'maximum_price' ? 'minimum_price' : 'maximum_price';

    if (Number(value) === 0 && Number(conditions[oppositeType]) === 0) {
      trigger();
    } else if (
      type === 'minimum_price' &&
      Number(value) > Number(conditions[oppositeType])
    ) {
      setError('price-error', { message: 'Price error' });
      setValidationError('Minimum price must be less than maximum price');
    } else if (
      type === 'maximum_price' &&
      Number(value) < Number(conditions[oppositeType])
    ) {
      setError('price-error', { message: 'Price error' });
      setValidationError('Maximum price must be greater than minimum price');
    }
  };

  const onChange = useCallback(
    (
      type: 'maximum_price' | 'minimum_price',
      value: number | string | undefined
    ) => {
      validateFields(type, value, conditions);

      let updatedValues = Object.create({
        ...conditions,
      });
      if (value === undefined || value === '') {
        updatedValues = omit(updatedValues, type);
      } else {
        updatedValues = {
          ...conditions,
          [type]: value,
        };
      }
      onConditionsUpdate(updatedValues);
    },
    [JSON.stringify(conditions)]
  );

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly || globalSpecialDisabled;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeCondition() : setExpanded(true);
            }}
            variant={expanded ? 'tertiary' : 'secondary'}
            disabled={isDisabled}
          />
        </Flex>
      </Flex>
      {expanded && (
        <>
          <Flex gap={24}>
            {/* TODO: For some reason DollarInput works better here with allowedDecimalPlaces,
                but it also contains a Form.NumberField which we don't want here */}
            <NumberField
              startUnit="$"
              width="100%"
              step={0.01}
              allowedDecimalPlaces={2}
              name={`${fieldPrefix}.rules.price_minimum`}
              label="Price minimum"
              defaultValue={conditions.minimum_price || undefined}
              onChange={(value) => onChange('minimum_price', value)}
              disabled={isDisabled}
            />
            {/* TODO: For some reason DollarInput works better here with allowedDecimalPlaces,
                but it also contains a Form.NumberField which we don't want here */}
            <NumberField
              startUnit="$"
              width="100%"
              step={0.01}
              allowedDecimalPlaces={2}
              name={`${fieldPrefix}.rules.price_maximum`}
              label="Price maximum"
              defaultValue={conditions.maximum_price || undefined}
              onChange={(value) => onChange('maximum_price', value)}
              disabled={isDisabled}
            />
          </Flex>
          {validationError && (
            <Typography color="error" mt={12}>
              {validationError}
            </Typography>
          )}
          <Modal.ContentDivider />
        </>
      )}
    </>
  );
};
