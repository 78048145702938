import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import take from 'lodash/take';
import { useCallback, useContext, useMemo, useState } from 'react';

import type { ToggleFulfillmentsParams } from '@jane/business-admin/data-access';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type {
  FulfillmentConfig,
  MaxOrderSetting,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import {
  CardNames,
  EventNames,
  formatDuration,
  getStoreDeliveryOptions,
  track,
} from '@jane/business-admin/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import type { ReservationModeV2 } from '@jane/shared/models';
import { Flex, Link, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { Form, useForm } from '@jane/shared/reefer-hook-form';
import { formatCurrency, formatUtcTime } from '@jane/shared/util';

import { CardSection } from '../../../../../../CardSection';
import { EditableCard } from '../../../../../../EditableCard';
import { GeofencesList } from './GeofencesList';
import { ZipcodesTable } from './ZipcodesTable';

type StoreDeliveryStrategy = {
  geofence: boolean;
  radius: boolean;
  zipcode: boolean;
};

const ColumnCardSection = styled(CardSection)({
  width: '33%',
  ...spacing({ pr: 12, mb: 24 }),
});

const WideColumnCardSection = styled(CardSection)({
  width: '66%',
  ...spacing({ pr: 12, mb: 24 }),
});

const DeliveryDetails = ({
  configStrategy,
  deliveryFee,
  deliveryRadius,
  minimumAmount,
}: {
  configStrategy: StoreDeliveryStrategy;
  deliveryFee: number;
  deliveryRadius: number | null;
  minimumAmount: number;
}) => {
  const displayDeliveryType = (configStrategy: StoreDeliveryStrategy) => {
    if (configStrategy.radius) return 'Radius';
    if (configStrategy.zipcode) return 'Zipcodes';
    return 'Geofence';
  };

  return (
    <>
      <ColumnCardSection label="Type">
        <Typography>{displayDeliveryType(configStrategy)}</Typography>
      </ColumnCardSection>
      {configStrategy.radius && (
        <>
          <ColumnCardSection label="Radius">
            <Typography>
              {deliveryRadius ? `${deliveryRadius} mile radius` : '-'}
            </Typography>
          </ColumnCardSection>
          <ColumnCardSection label="Minimum">
            <Typography>{`${formatCurrency(
              minimumAmount
            )} minimum`}</Typography>
          </ColumnCardSection>
          <ColumnCardSection label="Fee">
            <Typography>
              {deliveryFee ? `${formatCurrency(deliveryFee)}` : 'None'}
            </Typography>
          </ColumnCardSection>
        </>
      )}
    </>
  );
};

const MaxOrdersDetails = ({
  ordersSettings,
  fulfillmentType,
  onClickSeeAll,
}: {
  fulfillmentType: string;
  onClickSeeAll: () => void;
  ordersSettings: MaxOrderSetting[] | null;
}) => {
  if (!ordersSettings) return <Typography>None</Typography>;

  const orderEntries = ordersSettings.filter(
    ({ enabled, reservation_mode }) =>
      reservation_mode === fulfillmentType && enabled
  );

  if (!orderEntries || !orderEntries.length)
    return <Typography>None</Typography>;

  const firstTwoEntries = take(orderEntries, 2);
  const entryLength = orderEntries.length;

  return (
    <>
      {firstTwoEntries.map((order_setting: MaxOrderSetting, index) => {
        const formattedTime = formatUtcTime(
          order_setting.start_time,
          'hh:mm A',
          undefined
        );
        return (
          <Typography key={index}>
            {`${order_setting.max_order_limit} on ${order_setting.day_of_week}s at ${formattedTime}`}
            {entryLength > 2 && index === 1 && (
              <>
                &nbsp;and{' '}
                <Link onClick={onClickSeeAll}>{entryLength - 2} more</Link>
              </>
            )}
          </Typography>
        );
      })}
    </>
  );
};

const LeadTimesSection = ({ config }: { config: any }) => (
  <ColumnCardSection label="Lead Times">
    <Typography>
      {formatDuration(config.min_lead_time_minutes, 'minimum')}
    </Typography>
    <Typography>
      {formatDuration(config.max_lead_time_minutes, 'maximum')}
    </Typography>
  </ColumnCardSection>
);

type FulfillmentSwitchProps = Pick<
  FulfillmentSettingsCardProps,
  'isToggleOn' | 'isToggleDisabled' | 'type' | 'onToggle'
> & {
  curbsideEnabled: boolean;
};
const FulfillmentSwitchForm = ({
  isToggleOn,
  isToggleDisabled,
  curbsideEnabled,
  type,
  onToggle,
}: FulfillmentSwitchProps) => {
  const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      [`${type}_switch`]: isToggleOn,
    },
  });
  const { setValue } = formMethods;

  const handleToggle = useCallback(
    (switchEnabled: boolean) => {
      // Curbside only works when pickup is also turned on, so warn user if they try to disable pickup
      if (type === 'pickup' && curbsideEnabled && !switchEnabled) {
        setConfirmChangeModalOpen(true);
        return;
      }
      // This check is needed otherwise it constantly submits the API
      // due to onChange being triggered by setValue
      if (switchEnabled !== isToggleOn) {
        track({
          event: EventNames.EditedStoreSettings,
          card_name: CardNames.FulfillmentSettings,
          changed_attributes: [type],
        });
        onToggle({
          [type]: switchEnabled,
        });
      }
    },
    [isToggleOn, type]
  );

  const onConfirmToggle = () => {
    setConfirmChangeModalOpen(false);
    // This modal only shows when pickup is being disabled
    onToggle({
      pickup: false,
      curbside: false,
    });
  };

  const onCancelToggle = () => {
    setConfirmChangeModalOpen(false);
    // Set switch back to true if user cancels disabling pickup
    setValue(`${type}_switch`, true);
  };

  return (
    <>
      <Form.BaseForm
        name={`${type} switch form`}
        onSubmit={() => null}
        formMethods={formMethods}
      >
        <Form.SwitchField
          label={capitalize(type)}
          name={`${type}_switch`}
          onChange={handleToggle}
          labelHidden
          disabled={isToggleDisabled}
        />
      </Form.BaseForm>

      <ConfirmChangeModal
        appId="root"
        onConfirm={onConfirmToggle}
        onCancel={onCancelToggle}
        open={confirmChangeModalOpen}
        subtitle="Disabling pickup orders will also disable curbside orders."
      />
    </>
  );
};

interface FulfillmentSettingsCardProps {
  isToggleDisabled: boolean;
  isToggleOn: boolean;
  onEdit: () => void;
  onToggle: (data: ToggleFulfillmentsParams) => void;
  storePayload: StoreSettingsPayload;
  toggleDisabledMessage?: string;
  type: ReservationModeV2;
}

export const FulfillmentSettingsCard = ({
  onEdit,
  onToggle,
  isToggleOn,
  isToggleDisabled = false,
  toggleDisabledMessage,
  storePayload,
  type,
}: FulfillmentSettingsCardProps) => {
  const { canEditStore } = useContext(StoreDetailsContext);

  const {
    store_curbside_pickup_setting: curbsideSetting,
    delivery_config: deliveryConfig,
    delivery_geofences: deliveryGeofences,
    delivery_zipcodes: deliveryZipcodes,
    store_reservation_slot_max_order_settings: maxOrderSettings,
    store,
  } = storePayload;
  const config = storePayload[
    `${type}_fulfillment_config` as keyof StoreSettingsPayload
  ] as FulfillmentConfig;

  const formatLastCall = (last_call_seconds: number) => {
    const inMinutes = last_call_seconds / 60;
    const pluralize = `minute${inMinutes > 1 ? 's' : ''}`;
    return last_call_seconds === 0 ? 'None' : `${inMinutes} ${pluralize}`;
  };
  const configStrategy = getStoreDeliveryOptions(deliveryConfig);

  /* Only display lead and last call for:
    - Pickup
    - Curbside
    - Delivery with radius (otherwise it will be set for specific zipcodes/geofences)
  */
  const displayLeadAndLastCall = useMemo(() => {
    if (type !== 'delivery') {
      return true;
    }

    return configStrategy.radius;
  }, [type, configStrategy]);

  return (
    <EditableCard
      title={capitalize(type)}
      startAdornment={
        canEditStore && (
          <FulfillmentSwitchForm
            isToggleOn={isToggleOn}
            isToggleDisabled={isToggleDisabled}
            curbsideEnabled={
              curbsideSetting?.setting === 'allowed' ||
              curbsideSetting?.setting === 'mandatory'
            }
            type={type}
            onToggle={onToggle}
          />
        )
      }
      onEdit={onEdit}
      disabled={!isToggleOn || !canEditStore}
    >
      {toggleDisabledMessage}
      {isToggleOn && (
        <>
          <Flex justifyContent="space-between">
            <Flex flexWrap="wrap" width="100%">
              {config.fulfillment_type === 'delivery' && (
                <DeliveryDetails
                  configStrategy={configStrategy}
                  deliveryFee={store.delivery_fee_amount}
                  deliveryRadius={store.delivery_radius}
                  minimumAmount={config.cart_minimum}
                />
              )}
              <LeadTimesSection config={config} />

              <ColumnCardSection label="Scheduling">
                {config.display_windows_by_day ? (
                  <Typography>Daily window</Typography>
                ) : (
                  <>
                    <Typography>{`${config.window_minutes} minute window`}</Typography>
                    <Typography>{`${config.interval_minutes} minute interval`}</Typography>
                  </>
                )}
              </ColumnCardSection>

              {displayLeadAndLastCall && (
                <ColumnCardSection label="Last call">
                  <Typography>
                    {formatLastCall(config.last_call_interval_seconds)}
                  </Typography>
                </ColumnCardSection>
              )}

              <ColumnCardSection label="Max orders per window">
                <Typography>
                  {config.max_orders_per_window || 'None'}
                </Typography>
              </ColumnCardSection>

              <ColumnCardSection label="Max orders per reservation slot">
                <MaxOrdersDetails
                  onClickSeeAll={onEdit}
                  ordersSettings={maxOrderSettings}
                  fulfillmentType={config.fulfillment_type}
                />
              </ColumnCardSection>
            </Flex>
          </Flex>
          {config.fulfillment_type === 'curbside' && (
            <Flex flexWrap="wrap" width="100%">
              <ColumnCardSection label="Customer arrival notification">
                <Typography>
                  {curbsideSetting?.form_enabled ? 'Enabled' : 'Disabled'}
                </Typography>
              </ColumnCardSection>
              <WideColumnCardSection label="Note to customer">
                <Typography>
                  {curbsideSetting?.instructions || 'N/A'}
                </Typography>
              </WideColumnCardSection>
            </Flex>
          )}
          {config.fulfillment_type === 'delivery' && configStrategy.zipcode && (
            <ZipcodesTable
              onClickSeeAll={onEdit}
              zipcodeData={deliveryZipcodes}
            />
          )}
          {config.fulfillment_type === 'delivery' &&
            configStrategy.geofence && (
              <GeofencesList
                onClickSeeAll={onEdit}
                geofenceData={deliveryGeofences}
                config={config}
              />
            )}
        </>
      )}
    </EditableCard>
  );
};
