import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  useStoreSettings,
  useToggleFulfillmentModes,
} from '@jane/business-admin/data-access';
import type { ToggleFulfillmentsParams } from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useLastToggled,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type {
  FulfillmentConfig,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import {
  BODY_LINE_HEIGHT,
  ModalNames,
  RESERVATION_MODES,
} from '@jane/business-admin/util';
import type { ReservationModeV2 } from '@jane/shared/models';
import {
  Flex,
  Link,
  Skeleton,
  SwitchField,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { CardSection } from '../../../../CardSection';
import { EditableCard } from '../../../../EditableCard';
import { FulfillmentSettingsCard } from './fulfillmentSettings/card/FulfillmentSettingsCard';
import { FulfillmentSettingsModal } from './fulfillmentSettings/modal/FulfillmentSettingsModal';
import { KioskCard } from './kiosk/KioskCard';
import { KioskSettingsModal } from './kiosk/KioskSettingsModal';

const LoadingFulfillmentCard = ({ mode }: { mode: string }) => {
  return (
    <EditableCard
      isLoading={true}
      title={capitalize(mode)}
      startAdornment={
        <SwitchField disabled={true} label="" labelHidden name="Loading" />
      }
    >
      <Flex flexWrap="wrap" width="100%">
        {Array(5)
          .fill(null)
          .map((_v, i) => (
            <CardSection
              key={i}
              width="33%"
              mb={16}
              label="Loading"
              isLoading={true}
            >
              <Flex mt={4}>
                <Skeleton animate>
                  <Skeleton.Bone width={140} height={BODY_LINE_HEIGHT} />
                </Skeleton>
              </Flex>
              <Flex mt={4}>
                <Skeleton animate>
                  <Skeleton.Bone width={140} height={BODY_LINE_HEIGHT} />
                </Skeleton>
              </Flex>
            </CardSection>
          ))}
      </Flex>
    </EditableCard>
  );
};

export const FulfillmentCards = () => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating fulfillment settings. Please try again.'
  );
  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching } = useStoreSettings(storeId);
  const { lastToggled, setLastToggled, clearLastToggled } =
    useLastToggled<ToggleFulfillmentsParams>();

  const {
    mutateAsync: toggleFulfillmentModes,
    isLoading: isSaving,
    isSuccess: toggleFulfillmentModeSuccess,
    isError: toggleFulfillmentModeError,
  } = useToggleFulfillmentModes(storeId, setLastToggled);

  const [selectedConfig, setSelectedConfig] = useState<FulfillmentConfig>();

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    `${capitalize(selectedConfig?.fulfillment_type)} Settings`
  );
  const {
    modalOpen: kioskModalOpen,
    openModal: openKioskModal,
    closeModal: closeKioskModal,
  } = useModalActionsWithTracking(ModalNames.KioskSettings);

  const enabledTypes = useMemo(() => {
    const curbsideSetting =
      storePayload?.store_curbside_pickup_setting?.setting;
    return {
      delivery: storePayload?.store.delivery || false,
      pickup: storePayload?.store.pickup || false,
      curbside:
        curbsideSetting === 'allowed' || curbsideSetting === 'mandatory',
    };
  }, [
    storePayload?.store.delivery,
    storePayload?.store.pickup,
    storePayload?.store_curbside_pickup_setting?.setting,
  ]);

  const onToggle = async (requestData: ToggleFulfillmentsParams) => {
    catchSubmitErrors({
      submitMethod: () => toggleFulfillmentModes(requestData),
      requestData,
      onValidationError: () => null,
      // TODO: Maybe use this to show error toast?
      callback: () => null,
    });
  };

  useEffect(() => {
    if (toggleFulfillmentModeSuccess && lastToggled !== null) {
      const [[type, value]] = Object.entries(lastToggled);
      toast.add({
        label: `${capitalize(type)} successfully toggled ${
          value ? 'on' : 'off'
        }.`,
        variant: 'success',
      });
      clearLastToggled();
    }

    if (toggleFulfillmentModeError && lastToggled !== null) {
      const [[type, value]] = Object.entries(lastToggled);
      toast.add({
        label: `${capitalize(type)} could not be toggled ${
          value ? 'on' : 'off'
        }. Please try again.`,
        variant: 'error',
      });
    }
  }, [toggleFulfillmentModeSuccess, toggleFulfillmentModeError]);

  const onEdit = (type: ReservationModeV2) => {
    if (storePayload) {
      setSelectedConfig(
        storePayload[
          `${type}_fulfillment_config` as keyof StoreSettingsPayload
        ] as FulfillmentConfig
      );
      openModal(`${capitalize(type)} Settings`);
    }
  };

  const curbsideAndPickupDisabled = useMemo(() => {
    return !enabledTypes['curbside'] && !enabledTypes['pickup'];
  }, [enabledTypes]);

  return (
    <>
      <>
        {RESERVATION_MODES.map((type) =>
          isFetching || isSaving || !storePayload ? (
            <LoadingFulfillmentCard key={`loading_card_${type}`} mode={type} />
          ) : (
            <FulfillmentSettingsCard
              key={`settings_card_${type}`}
              onEdit={() => onEdit(type)}
              onToggle={onToggle}
              isToggleOn={enabledTypes[type as keyof typeof enabledTypes]}
              isToggleDisabled={
                type === 'curbside' && curbsideAndPickupDisabled
              }
              toggleDisabledMessage={
                type === 'curbside' && curbsideAndPickupDisabled
                  ? 'Pickup must be enabled in order to enable curbside'
                  : undefined
              }
              storePayload={storePayload}
              type={type}
            />
          )
        )}
      </>
      {storePayload && (
        <>
          {modalOpen && selectedConfig && (
            <FulfillmentSettingsModal
              storePayload={storePayload}
              config={selectedConfig}
              setOpen={() =>
                closeModal(
                  `${capitalize(selectedConfig.fulfillment_type)} Settings`
                )
              }
            />
          )}
          {storePayload.kiosk && storePayload.kiosk.enabled ? (
            <>
              <KioskCard
                openKioskModal={() => openKioskModal()}
                kioskSettings={storePayload.kiosk}
                store={storePayload.store}
              />
              {kioskModalOpen && (
                <KioskSettingsModal
                  setOpen={() => closeKioskModal()}
                  kioskSettings={storePayload.kiosk}
                  storeKioskSettings={{
                    kiosk_allow_auth: storePayload.store.kiosk_allow_auth,
                    kiosk_anonymous_checkout:
                      storePayload.store.kiosk_anonymous_checkout,
                  }}
                  storeName={storePayload.store.name}
                />
              )}
            </>
          ) : (
            <EditableCard title="Kiosk" disabled>
              <Typography>
                Kiosk settings have not been activated for this store. Please
                reach out to{' '}
                <Link href="mailto:partnersuccess@iheartjane.com">
                  Jane Partner Success
                </Link>{' '}
                to set this up.
              </Typography>
            </EditableCard>
          )}
        </>
      )}
    </>
  );
};
