import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as t from 'io-ts';

import type {
  AbbreviatedStoreV2,
  AeropayIntegrationV2,
  DeleteObject,
  JanePayIntegration,
  OwnershipIdentification,
  PaymentOptionV2,
  SmsSetting,
  SquareIntegration,
  StoreDismissalMessage,
  StoreImagesPayload,
  StoreSchedule,
  StoreSettingsPayload,
  StoreV2,
  StrongholdIntegrationV2,
} from '@jane/business-admin/types';
import {
  tAbbreviatedStore,
  tStoreImagesPayload,
  tStoreSettingsPayload,
} from '@jane/business-admin/types';
import type { TipVariant } from '@jane/business-admin/util';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type {
  AnalyticsIntegration,
  CanPayV2RemotePayIntegration,
  CanpayV2Integration,
  MedicalStoreDocumentationRequirement,
  MonerisIntegration,
  PayfirmaIntegration,
  StatusMessageV2,
  StoreChatSetting,
  StoreCrmIntegration,
  StoreDiscountSettings,
  TipSetting,
} from '@jane/shared/models';
import { tStoreDiscountSettings } from '@jane/shared/models';

export const STORES_URL = `/business/stores`;

interface StoresParams {
  count?: number;
  includeCounts?: boolean;
  paginationId?: number;
}
const fetchStores = async (params: StoresParams | undefined): Promise<any> => {
  const urlParams = new URLSearchParams();
  params?.count && urlParams.append('count', params.count.toString());
  params?.paginationId &&
    urlParams.append('pagination_id', params.paginationId.toString());
  params?.includeCounts && urlParams.append('include_product_counts', 'true');

  const urlWithParams = `${STORES_URL}?${urlParams.toString()}`;

  const tPayload = t.interface({
    stores: t.array(tAbbreviatedStore),
  });
  const data = await fetchWithDecode(
    janeApiV2.get(urlWithParams),
    tPayload,
    urlWithParams
  );
  return data.stores;
};
export const useStores = (params?: StoresParams | undefined) =>
  useQuery<AbbreviatedStoreV2[]>({
    queryFn: () => fetchStores(params),
    queryKey: ['stores', params],
    useErrorBoundary: true,
  });
export const useStoresWithCounts = (params?: StoresParams | undefined) =>
  useQuery<AbbreviatedStoreV2[]>({
    queryFn: () =>
      fetchStores({
        ...params,
        includeCounts: true,
      }),
    // Needs to use a different QueryKey, otherwise it'll just use cached data from the first request
    queryKey: ['stores_with_counts', params],
    useErrorBoundary: true,
  });

const fetchStoreSettings = async (
  storeId?: string
): Promise<StoreSettingsPayload> => {
  const url = `${STORES_URL}/${storeId}/settings`;
  const tPayload = t.interface({
    settings: tStoreSettingsPayload,
  });
  const data = await fetchWithDecode(
    janeApiV2.get<{ settings: StoreSettingsPayload }>(url),
    tPayload,
    url
  );
  // const data = await janeApiV2.get<{ settings: StoreSettingsPayload }>(url);
  return data.settings;
};
export const useStoreSettings = (storeId?: string) => {
  return useQuery<StoreSettingsPayload>({
    queryFn: () => fetchStoreSettings(storeId),
    queryKey: ['stores', storeId],
    staleTime: Infinity,
    useErrorBoundary: true,
  });
};

interface SaveStoreDetailsParams {
  ownership_identifications: Omit<OwnershipIdentification, 'id'>;
  store: Partial<StoreV2>;
}
const saveStoreDetails = async (
  storeId: string,
  data: SaveStoreDetailsParams
): Promise<null> => {
  return await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/details`, {
    details: data,
  });
};

export const useSaveStoreDetails = (storeId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreDetailsParams) =>
      saveStoreDetails(storeId.toString(), data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

export interface SavePaymentOptionsParam {
  aeropay_integration: Partial<AeropayIntegrationV2>;
  canpay_v2_integration: CanpayV2Integration;
  canpay_v2remotepay_integration: CanPayV2RemotePayIntegration;
  jane_pay_integration?: JanePayIntegration;
  moneris_integration: MonerisIntegration;
  payfirma_integration: PayfirmaIntegration;
  square_integration?: Omit<SquareIntegration, 'authorized'>;
  store: Partial<StoreV2>;
  store_payment_options: (Omit<PaymentOptionV2, 'id'> | DeleteObject)[];
  stronghold_integration: StrongholdIntegrationV2;
}

const savePaymentOptions = async (
  storeId: string,
  data: SavePaymentOptionsParam
): Promise<null> =>
  await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/payment_options`, data);

export const useSavePaymentOptions = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SavePaymentOptionsParam) => {
      return savePaymentOptions(storeId.toString(), data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

interface SaveStoreSchedulesParams {
  curbside: StoreSchedule;
  delivery: StoreSchedule;
  pickup: StoreSchedule;
  retail: StoreSchedule;
}

const saveStoreSchedules = async (
  storeId: string,
  data: SaveStoreSchedulesParams
): Promise<null> => {
  return await janeApiV2.post<null>(`${STORES_URL}/${storeId}/schedules`, data);
};

export const useSaveStoreSchedules = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreSchedulesParams) =>
      saveStoreSchedules(storeId, data),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

interface SaveStoreTaxAndFeesParams {
  sales_tax_rate?: number;
  service_fee_amount?: number;
  tax_included?: boolean;
}

const saveStoreTaxAndFees = async (
  storeId: string,
  data: SaveStoreTaxAndFeesParams
): Promise<null> =>
  await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/taxes_fees`, data);

export const useSaveStoreTaxAndFees = (storeId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreTaxAndFeesParams) => {
      return saveStoreTaxAndFees(storeId.toString(), data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

interface SaveStoreCheckoutOptionsParams {
  medical_store_documentation_requirement: Partial<MedicalStoreDocumentationRequirement>;
  store: Pick<
    StoreV2,
    | 'id_required'
    | 'delivery_id_required'
    | 'pickup_id_required'
    | 'birth_date_required'
    | 'customer_paperwork'
    | 'show_paperwork'
  >;
  tip_settings: {
    _destroy?: boolean;
    custom_amounts?: Array<number | undefined>;
    enabled_for?: TipSetting['enabled_for'];
    id?: number;
    variant?: TipVariant;
  }[];
}

const saveStoreCheckoutOptions = async (
  storeId: string,
  params: SaveStoreCheckoutOptionsParams
): Promise<null> => {
  const checkout_options = {
    ...params,
    medical_store_documentation_requirement: {
      ...params.medical_store_documentation_requirement,
      id: params.medical_store_documentation_requirement.id?.toString(),
    },
    tip_settings: params.tip_settings.map((setting) => ({
      ...setting,
      id: setting.id?.toString(),
    })),
  };

  return await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/checkout_options`,
    {
      checkout_options,
    }
  );
};

export const useSaveStoreCheckoutOptions = (storeId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreCheckoutOptionsParams) => {
      return saveStoreCheckoutOptions(storeId.toString(), data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

export type CrmIntegrationParams = Partial<
  Pick<
    StoreCrmIntegration,
    | 'automatic_redemption'
    | 'bypass_redemption'
    | 'crm_provider'
    | 'enabled'
    | 'max_redemption_count'
    | 'token'
  >
>;
interface SaveStoreIntegrationsParams {
  analytics_integration?: Partial<
    Pick<AnalyticsIntegration, 'enabled' | 'provider_id'>
  >;
  crm_integrations?: CrmIntegrationParams[];
}

const saveStoreIntegrations = async (
  data: SaveStoreIntegrationsParams,
  storeId?: string
): Promise<null> =>
  await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/settings/integrations`,
    data
  );

export const useSaveStoreIntegrations = (storeId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreIntegrationsParams) => {
      return saveStoreIntegrations(data, storeId);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId?.toString()]);
    },
  });
};

interface SaveStoreSubdomainParams {
  home_url?: string;
  hostname?: string;
}

const saveStoreSubdomain = async (
  storeId: string,
  data: SaveStoreSubdomainParams
): Promise<null> =>
  await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/subdomain`, data);

export const useSaveStoreSubdomain = (storeId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreSubdomainParams) => {
      return saveStoreSubdomain(storeId.toString(), data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

const deleteStoreSubdomain = async (storeId: string): Promise<null> =>
  await janeApiV2.delete(`${STORES_URL}/${storeId}/subdomain`);

export const useDeleteStoreSubdomain = (storeId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return deleteStoreSubdomain(storeId.toString());
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};

const fetchStoreImages = async (
  storeId?: string
): Promise<StoreImagesPayload> => {
  const url = `${STORES_URL}/${storeId}/images`;
  return fetchWithDecode(
    janeApiV2.get<StoreImagesPayload>(url),
    tStoreImagesPayload,
    url
  );
};
export const useStoreImages = (storeId?: string) =>
  useQuery<StoreImagesPayload>({
    queryFn: () => fetchStoreImages(storeId),
    queryKey: ['stores', storeId, 'images'],
    staleTime: Infinity,
    useErrorBoundary: true,
  });

type SaveStoreImagesParams = {
  image: string;
  image_type: string;
};
const saveStoreImages = async (
  storeId: string,
  data: SaveStoreImagesParams
): Promise<null> => {
  return await janeApiV2.post<null>(`${STORES_URL}/${storeId}/images`, {
    images: data,
  });
};
export const useSaveStoreImages = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreImagesParams) => {
      return saveStoreImages(storeId.toString(), data);
    },
    mutationKey: ['save-store-images', storeId],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['stores', storeId.toString(), 'images'],
        refetchType: 'all',
      });
    },
  });
};

const deleteStoreImage = async (
  storeId: string,
  type: 'photo' | 'primary_gallery_image' | 'comms_banner_image'
) => janeApiV2.delete<null>(`${STORES_URL}/${storeId}/images/${type}`);
export const useDeleteStoreImage = (
  storeId: string,
  type: 'photo' | 'primary_gallery_image' | 'comms_banner_image'
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => deleteStoreImage(storeId, type),
    mutationKey: ['delete-store-image', storeId, type],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['stores', storeId.toString(), 'images'],
        refetchType: 'all',
      });
    },
  });
};

export interface SaveStoreNotificationsParams {
  chat_setting?: StoreChatSetting;
  dismissal_messages: StoreDismissalMessage[];
  send_cart_status_updates_via_email?: boolean;
  status_messages: StatusMessageV2[] | [];
}
const saveStoreNotifications = async (
  storeId: string,
  data: SaveStoreNotificationsParams
): Promise<null> => {
  return await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/notifications`, {
    store: data,
  });
};
export const useSaveStoreNotifications = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreNotificationsParams) =>
      saveStoreNotifications(storeId.toString(), data),
    mutationKey: ['save-store-notifications', storeId],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId]);
    },
  });
};

interface SaveStoreDiscountSettingsParams {
  discount_settings: {
    bulk_pricing_specials_stacking_setting?: 'yes' | 'no' | 'combinable';
    bundle_specials_stacking_setting?: 'yes' | 'no' | 'combinable';
    cart_total_specials_stacking_setting?: 'yes' | 'no' | 'combinable';
    group_specials_stacking_setting?: 'yes' | 'no' | 'combinable';
    loyalty_points_stacking?: boolean;
    max_specials_per_cart?: number | null;
    product_specials_stacking_setting?: 'yes' | 'no' | 'combinable';
  };
}

const fetchStoreDiscountSettings = async (
  storeId?: string
): Promise<StoreDiscountSettings> => {
  const url = `${STORES_URL}/${storeId}/discount_settings`;
  const tPayload = t.interface({
    store_discount_setting: tStoreDiscountSettings,
  });
  const data = await fetchWithDecode(
    janeApiV2.get<{ store_discount_setting: StoreDiscountSettings }>(url),
    tPayload,
    url
  );
  return data.store_discount_setting;
};

export const useStoreDiscountSettings = (storeId: string) =>
  useQuery<StoreDiscountSettings>({
    queryFn: () => fetchStoreDiscountSettings(storeId),
    queryKey: ['store_discount_settings', storeId],
    staleTime: Infinity,
    useErrorBoundary: true,
  });

const saveStoreDiscountSettings = async (
  storeId: string,
  data: SaveStoreDiscountSettingsParams
): Promise<null> =>
  await janeApiV2.patch<null>(
    `${STORES_URL}/${storeId}/discount_settings`,
    data
  );

export const useSaveStoreDiscountSettings = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreDiscountSettingsParams) => {
      return saveStoreDiscountSettings(storeId, data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['store_discount_settings', storeId]);
    },
  });
};

interface SaveStoreSmsSettingsParams {
  sms_provider: SmsSetting | null;
}

const saveStoreSmsSettings = async (
  storeId: string,
  data: SaveStoreSmsSettingsParams
): Promise<null> =>
  await janeApiV2.patch<null>(`${STORES_URL}/${storeId}/sms_provider`, data);

export const useSaveStoreSmsSettings = (storeId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveStoreSmsSettingsParams) => {
      return saveStoreSmsSettings(storeId.toString(), data);
    },
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['stores', storeId.toString()]);
    },
  });
};
