import { useEffect, useMemo } from 'react';

import type { FulfillmentConfig } from '@jane/business-admin/types';
import { Flex, Grid } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { InputGroup } from '../../../../../../InputGroup';

export const TimingSettingsSection = ({
  config,
}: {
  config: FulfillmentConfig;
}) => {
  const { setValue, watch } = useFormContext();
  const lastCallIntervalForm = watch('display.last_call_interval_minutes');

  const lastCallIntervalMinutes = useMemo(
    () => config.last_call_interval_seconds / 60,
    [config.last_call_interval_seconds]
  );

  useEffect(() => {
    // This is what we'll actually save on the backend
    // the "display" fields are just for displaying minutes
    const value =
      lastCallIntervalForm !== null
        ? lastCallIntervalForm
        : lastCallIntervalMinutes;
    setValue('last_call_interval_seconds', value * 60);
  }, [lastCallIntervalForm, lastCallIntervalMinutes]);

  return (
    <>
      <InputGroup minutes={config.min_lead_time_minutes} isMin={true} />
      <InputGroup minutes={config.max_lead_time_minutes} isMin={false} />

      <Flex mb={24}>
        <Grid.Container spacing={40}>
          <Grid.Item xs={4}>
            <Form.NumberField
              defaultValue={lastCallIntervalMinutes}
              width="100%"
              name="display.last_call_interval_minutes"
              label="Last call before closing"
              endUnit="minutes"
            />
          </Grid.Item>
          <Grid.Item xs={4}></Grid.Item>
        </Grid.Container>
      </Flex>
    </>
  );
};
