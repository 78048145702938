import styled from '@emotion/styled';
import { useMaskito } from '@maskito/react';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { UpsertBloomMenuTemplateArgs } from '@jane/business-admin/data-access';
import {
  useFetchBloomMenuTemplate,
  useFetchCurrentBloomMenu,
  useUpsertBloomMenuTemplate,
} from '@jane/business-admin/data-access';
import {
  businessPaths,
  normalizeUrl,
  urlMask,
} from '@jane/business-admin/util';
import {
  Box,
  Flex,
  MegaphoneIcon,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { ModalPageWrapper } from './components/ModalPageWrapper';

const SubtextFlex = styled(Flex)({
  borderRadius: '16px',
});

export const PremiumFooter = () => {
  const navigate = useNavigate();
  const urlRef = useMaskito({ options: urlMask });
  const toast = useToast();
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const bloomMenuTemplate = useFetchBloomMenuTemplate('footer');
  const { mutate: updateBloomMenuTemplate, isLoading } =
    useUpsertBloomMenuTemplate({
      menuId: bloomMenuQuery.data?.id,
    });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<UpsertBloomMenuTemplateArgs>({
    defaultValues: {
      source_content_selector: bloomMenuTemplate.data?.source_content_selector,
      source_url: bloomMenuTemplate.data?.source_url,
    },
  });

  useEffect(() => {
    if (bloomMenuTemplate.data) {
      reset({
        source_content_selector:
          bloomMenuTemplate.data?.source_content_selector ?? 'footer',
        source_url: bloomMenuTemplate.data?.source_url ?? 'https://',
      });
    }
  }, [bloomMenuTemplate.data]);

  const importFooter = useCallback(
    ({ source_content_selector, source_url }: UpsertBloomMenuTemplateArgs) => {
      updateBloomMenuTemplate(
        {
          source_content_selector,
          source_url: normalizeUrl(source_url),
          template_type: 'footer',
        },
        {
          onSuccess: () => {
            toast.add({
              label: 'Footer imported!',
              variant: 'success',
            });
            reset({ source_content_selector, source_url });
            navigate(businessPaths.premium());
          },
          onError: (error) => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
        }
      );
    },
    [toast, updateBloomMenuTemplate, navigate, reset]
  );

  return (
    <ModalPageWrapper
      hasUnsavedChanges={isDirty}
      publish={{
        onClick: handleSubmit(importFooter),
        disable: isLoading || !isDirty,
        loading: isLoading,
      }}
      title="Footer"
    >
      <Form name="footer_form" onSubmit={noop}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          py={24}
        >
          <SubtextFlex
            background="grays-ultralight"
            p={16}
            maxWidth={600}
            alignItems="center"
          >
            <Box mr={16}>
              <MegaphoneIcon size="md" />
            </Box>
            <Typography variant="body-bold">
              To import your website's footer, please contact your Partner
              Success rep. They will assist you with the technical setup. Once
              your footer is imported, your website's favicon will automatically
              be included as well.
            </Typography>
          </SubtextFlex>

          <Flex
            alignItems="center"
            flexDirection="column"
            maxWidth={450}
            my={32}
          >
            <Controller
              name="source_url"
              control={control}
              render={({ field }) => (
                <Form.TextField
                  {...field}
                  label="Source"
                  helperText="Provide the URL of your footer website to directly import the footer"
                  mb={24}
                  endIcon={<Typography color="grays-mid">URL</Typography>}
                  ref={urlRef}
                />
              )}
            />
            <Controller
              name="source_content_selector"
              control={control}
              render={({ field }) => (
                <Form.TextField
                  {...field}
                  label="Content selector"
                  helperText="Provide the CSS content selector of the footer element to import"
                  endIcon={<Typography color="grays-mid">Selector</Typography>}
                />
              )}
            />
          </Flex>
        </Flex>
      </Form>
    </ModalPageWrapper>
  );
};
