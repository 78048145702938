import styled from '@emotion/styled';
import { useEffect } from 'react';

import { useSaveStoreDiscountSettings } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { StoreDiscountSettings } from '@jane/shared/models';
import {
  Banner,
  Flex,
  InfoIcon,
  Modal,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';

const FORM_ERROR_NAME = 'specials-stacking-error';

const RadioFieldGroupWrapper = styled(Flex)<{ hasBorder?: boolean }>(
  ({ theme, hasBorder }) => ({
    borderColor: theme.colors.grays.light,
    borderBottomWidth: hasBorder ? 1 : 0,
  }),
  {
    borderBottomStyle: 'solid',
    ...spacing({ px: 24, mb: 24 }),
    justifyContent: 'space-between',
  }
);

const stackingText =
  'Can this special be used while other specials are present in the cart?';

const bannerText =
  'Select ‘Yes’ for specials that can be applied in addition to other specials, \
  ‘No’ for specials that can’t be applied when combined with other specials, or \
  "Only with other stackable specials" for specials that can be applied in addition \
  to other specials set to "Yes.” In addition to these settings, Jane will always give \
  the customer the best discount or combination of discounts.';

export const StackingModal = ({
  open,
  setOpen,
  storeDiscountSettings,
  storeId,
}: {
  open: boolean;
  setOpen: (open?: boolean) => void;
  storeDiscountSettings?: StoreDiscountSettings | null;
  storeId: string;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating specials stacking settings. Please try again.'
  );
  const { mutateAsync: saveStoreDiscountSettings, isSuccess: saveSuccess } =
    useSaveStoreDiscountSettings(storeId);

  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const formName = 'Specials stacking settings form';
  const toast = useToast();

  const onSubmit = async (data: any) => {
    const requestData = {
      discount_settings: {
        ...data,
        loyalty_points_stacking: storeDiscountSettings?.loyalty_points_stacking,
        max_specials_per_cart: storeDiscountSettings?.max_specials_per_cart,
      },
    };

    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.Discounts,
        changed_attributes: Object.keys(dirtyFields),
      });
      return saveStoreDiscountSettings(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors['stores'])
        );
      },
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Specials stacking settings updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess, setOpen]);

  const setOptions = [
    { id: 'yes', label: 'Yes', value: 'yes' },
    { id: 'no', label: 'No', value: 'no' },
    {
      id: 'combinable',
      label: 'Only with other stackable specials',
      value: 'combinable',
    },
  ];

  return (
    <ConfirmWrapperWithTracking
      setOpen={setOpen}
      open={open}
      hasChanges={isDirty}
      modalName={ModalNames.SpecialsStacking}
    >
      <Form.BaseForm
        name={formName}
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Specials stacking"
          subtitle={stackingText}
          actions={<Form.SubmitButton label="Save" variant="primary" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <RadioFieldGroupWrapper hasBorder>
            <Typography variant="body-bold">Stack product specials</Typography>
            <Form.RadioFieldGroup
              options={setOptions}
              name="product_specials_stacking_setting"
              defaultChecked={
                storeDiscountSettings?.product_specials_stacking_setting
              }
              row
            />
          </RadioFieldGroupWrapper>
          <RadioFieldGroupWrapper hasBorder>
            <Typography variant="body-bold">Stack bulk specials</Typography>
            <Form.RadioFieldGroup
              options={setOptions}
              name="bulk_pricing_specials_stacking_setting"
              defaultChecked={
                storeDiscountSettings?.bulk_pricing_specials_stacking_setting
              }
              row
            />
          </RadioFieldGroupWrapper>
          <RadioFieldGroupWrapper hasBorder>
            <Typography variant="body-bold">
              Stack cart total specials
            </Typography>
            <Form.RadioFieldGroup
              options={setOptions}
              name="cart_total_specials_stacking_setting"
              defaultChecked={
                storeDiscountSettings?.cart_total_specials_stacking_setting
              }
              row
            />
          </RadioFieldGroupWrapper>
          <RadioFieldGroupWrapper hasBorder>
            <Typography variant="body-bold">
              Stack buy X get Y specials
            </Typography>
            <Form.RadioFieldGroup
              options={setOptions}
              name="bundle_specials_stacking_setting"
              defaultChecked={
                storeDiscountSettings?.bundle_specials_stacking_setting
              }
              row
            />
          </RadioFieldGroupWrapper>
          <RadioFieldGroupWrapper>
            <Typography variant="body-bold">
              Stack qualified group specials
            </Typography>
            <Form.RadioFieldGroup
              options={setOptions}
              name="group_specials_stacking_setting"
              defaultChecked={
                storeDiscountSettings?.group_specials_stacking_setting
              }
              row
            />
          </RadioFieldGroupWrapper>
          <Banner
            label={bannerText}
            icon={
              <Flex alignItems="center" height="100%">
                <InfoIcon />
              </Flex>
            }
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
