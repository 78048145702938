import { useEffect } from 'react';

import { ButtonToggle } from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import type { CartLimitRule } from '../../mockData';

export const RuleTypeToggle = ({ type }: { type?: string }) => {
  const { setValue, watch } = useFormContext<CartLimitRule>();
  const ruleType = watch('rule_type');

  useEffect(() => {
    setValue('rule_type', type ?? 'product');
  }, [setValue, type]);

  return (
    <ButtonToggle
      value={ruleType ?? ''}
      onChange={(value: string | number) =>
        setValue('rule_type', value.toString())
      }
      full={false}
    >
      <ButtonToggle.Button value="product">Product Type</ButtonToggle.Button>

      <ButtonToggle.Button value="equivalency">Equivalency</ButtonToggle.Button>
    </ButtonToggle>
  );
};
