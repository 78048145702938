import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { UpdateFulfillmentParams } from '@jane/business-admin/data-access';
import { useUpdateFulfillmentConfig } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  FulfillmentSettingsProvider,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import type {
  DeleteObject,
  FulfillmentConfig,
  MaxOrderSetting,
  StoreSettingsPayload,
  StoreV2,
} from '@jane/business-admin/types';
import {
  EventNames,
  getStoreDeliveryOptions,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import type { DeliveryZipcode, ReservationModeV2 } from '@jane/shared/models';
import { Modal, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
  useFormContext,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../../../ConfirmWrapperWithTracking';
import { CurbsideInstructionsSection } from './CurbsideInstructionsSection';
import { FreeDeliverySection } from './FreeDeliverySection';
import { MaxOrdersSection } from './MaxOrdersSection';
import { TimingSettingsSection } from './TimingSettingsSection';
import { WindowSettingsSection } from './WindowSettingsSection';
import { DeliveryStrategySection } from './deliveryStrategy/DeliveryStrategySection';

interface FormData extends Partial<FulfillmentConfig> {
  curbside?: boolean;
  delivery?: boolean;
  delivery_strategy?: 'radius' | 'zipcode' | 'geofence';

  delivery_zcodes?: (DeliveryZipcode | DeleteObject)[];
  display?: any;
  display_max_days?: number;
  display_max_hours?: number;
  display_max_minutes?: number;
  display_min_days?: number;
  display_min_hours?: number;

  display_min_minute?: number;
  pickup?: boolean;

  store_reservation_slot_max_order_settings?: MaxOrderSetting[];
}

const TypeEnabledField = ({
  curbsideSetting,
  fulfillmentType,
  storeTypeEnabled,
}: {
  curbsideSetting: 'allowed' | 'not_allowed' | 'mandatory';
  fulfillmentType: ReservationModeV2;
  storeTypeEnabled: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const formFulfillmentEnabled = watch(`${fulfillmentType}`);

  const [confirmChangeModalOpen, setConfirmChangeModalOpen] = useState(false);

  const typeEnabled = useMemo(() => {
    if (fulfillmentType === 'curbside') {
      return curbsideSetting === 'allowed' || curbsideSetting === 'mandatory';
    } else {
      return storeTypeEnabled;
    }
  }, [curbsideSetting, fulfillmentType, storeTypeEnabled]);

  const handleSwitchChange = useCallback(
    (toggled: boolean) => {
      if (
        fulfillmentType === 'pickup' &&
        !toggled &&
        (curbsideSetting === 'allowed' || curbsideSetting === 'mandatory')
      ) {
        setConfirmChangeModalOpen(true);
      }
    },
    [curbsideSetting]
  );

  const onConfirmToggle = () => {
    setConfirmChangeModalOpen(false);
  };
  const onCancelToggle = () => {
    setConfirmChangeModalOpen(false);
    setValue(`${fulfillmentType}`, true);
  };

  return (
    <>
      <Form.SwitchField
        defaultChecked={typeEnabled}
        label={`Enable ${capitalize(fulfillmentType)}`}
        name={`${fulfillmentType}`}
        onChange={handleSwitchChange}
      />

      {fulfillmentType === 'curbside' && (
        <Form.SwitchField
          disabled={
            formFulfillmentEnabled !== undefined && !formFulfillmentEnabled
          }
          mt={24}
          defaultChecked={curbsideSetting === 'mandatory'}
          label="Require curbside fulfillment"
          helperText="This option requires customers to pickup their orders curbside."
          name={'display.curbside_mandatory'}
        />
      )}

      <ConfirmChangeModal
        onConfirm={onConfirmToggle}
        onCancel={onCancelToggle}
        open={confirmChangeModalOpen}
        subtitle="Disabling pickup orders will also disable curbside orders."
      />
    </>
  );
};

export const FulfillmentSettingsModal = ({
  setOpen,
  config,
  storePayload,
}: {
  config: FulfillmentConfig;
  setOpen: (open: boolean) => void;
  storePayload: StoreSettingsPayload;
}) => {
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const toast = useToast();
  const {
    delivery_config: deliveryStrategyConfig,
    store_reservation_slot_max_order_settings: allMaxOrderSettings,
    store_curbside_pickup_setting,
    store,
  } = storePayload;
  const { fulfillment_type } = config;

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error updating ${fulfillment_type} settings. Please try again.`
  );

  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: updateFulfillmentConfig, isSuccess: updateSuccess } =
    useUpdateFulfillmentConfig(storeId, fulfillment_type);

  const formName = `Edit ${fulfillment_type} settings form`;

  const storeDeliveryStrategy = getStoreDeliveryOptions(deliveryStrategyConfig);
  const [deliveryStrategy, setDeliveryStrategy] = useState(
    storeDeliveryStrategy
  );

  const filteredMaxOrderSettings = useMemo(() => {
    if (!allMaxOrderSettings) return [];
    return allMaxOrderSettings.filter(
      ({ reservation_mode }) => reservation_mode === fulfillment_type
    );
  }, [allMaxOrderSettings]);

  useEffect(() => {
    if (updateSuccess) {
      toast.add({
        label: `${capitalize(fulfillment_type)} settings updated.`,
        variant: 'success',
      });
      setOpen(false);
    }
  }, [updateSuccess]);

  const parseRemovedArrayItems = (ogData: any[], formData: any[]) => {
    const formDataIds: Set<string> = formData.reduce((idSet, { id }) => {
      if (id) {
        idSet.add(id.toString());
      }
      return idSet;
    }, new Set());
    const removedSettings: { _destroy: boolean; id: number }[] = ogData.reduce(
      (removed, { id }) => {
        if (id && !formDataIds.has(id.toString())) {
          removed.push({ id, _destroy: true });
        }
        return removed;
      },
      []
    );

    return removedSettings;
  };

  const FORM_ERROR_NAME = `${fulfillment_type}-error`;

  const onSubmit = useCallback(
    (data: FormData) => {
      const removedZipcodes = parseRemovedArrayItems(
        storePayload.delivery_zipcodes || [],
        data.delivery_zcodes || []
      );
      const removedMaxOrderSettings = parseRemovedArrayItems(
        filteredMaxOrderSettings,
        data.store_reservation_slot_max_order_settings || []
      );

      let saveData = omit(data, [
        'display',
        'delivery_zipcodes',
        'delivery_zcodes',
        // TODO: These should be nested in the "display" object and removed that way, but validate has an issue with nested input names https://app.clickup.com/t/8669kqtxf
        'display_min_days',
        'display_max_days',
        'display_max_hours',
        'display_max_minutes',
        'display_min_hours',
        'display_min_minutes',
      ]) as UpdateFulfillmentParams;

      // Number input returns undefined for empty input (infinite) value
      // Backend wants null for "infinite" value
      // Context: https://app.clickup.com/t/866a4k8yw
      if (saveData.max_orders_per_window === undefined) {
        saveData.max_orders_per_window = null;
      }

      if (fulfillment_type === 'curbside') {
        saveData = {
          ...saveData,
          curbside: !data.curbside
            ? 'not_allowed'
            : data.display?.curbside_mandatory
            ? 'mandatory'
            : 'allowed',
        };
      }
      if (data.delivery_strategy === 'zipcode') {
        saveData = {
          ...saveData,
          delivery_zcodes: [
            ...(data.delivery_zcodes || []),
            ...removedZipcodes,
          ],
        };
      }
      const parseDirtyFields = () => {
        if (!dirtyFields['display']) {
          return Object.keys(dirtyFields);
        }
        const baseFields = Object.keys(omit(dirtyFields, 'display'));
        const displayFields = Object.keys(dirtyFields['display']);
        return [...baseFields, ...displayFields];
      };
      const requestData = {
        ...saveData,
        store_reservation_slot_max_order_settings: [
          ...(saveData.store_reservation_slot_max_order_settings || []),
          ...removedMaxOrderSettings,
        ],
      };
      const submitMethod = () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: `${capitalize(fulfillment_type)} Settings`,
          changed_attributes: parseDirtyFields(),
        });

        return updateFulfillmentConfig(requestData);
      };
      return catchSubmitErrors({
        submitMethod,
        requestData,
        onValidationError: (validationErrors: {
          allow_future_day_ordering?: Record<string, unknown>;
          allow_off_hours_ordering?: Record<string, unknown>;
          curbside?: Record<string, unknown>;
          delivery?: Record<string, unknown>;
          pickup?: Record<string, unknown>;
        }) => {
          const pickupErrors = parseValidationErrors(
            validationErrors?.pickup || {}
          );
          const deliveryErrors = parseValidationErrors(
            validationErrors?.delivery || {}
          );
          const futureDayErrors = parseValidationErrors(
            validationErrors?.allow_future_day_ordering || {}
          );
          const offHoursErrors = parseValidationErrors(
            validationErrors?.allow_off_hours_ordering || {}
          );
          const curbsideErrors = parseValidationErrors(
            validationErrors?.curbside || {}
          );
          throw new FormValidationError(FORM_ERROR_NAME, [
            ...pickupErrors,
            ...deliveryErrors,
            ...futureDayErrors,
            ...offHoursErrors,
            ...curbsideErrors,
          ]);
        },
      });
    },
    [dirtyFields]
  );

  return (
    <FulfillmentSettingsProvider
      storePayload={storePayload}
      deliveryStrategy={deliveryStrategy}
      setDeliveryStrategy={setDeliveryStrategy}
    >
      <ConfirmWrapperWithTracking
        variant={'full-screen'}
        open
        setOpen={setOpen}
        hasChanges={isDirty}
        background="grays-white"
        modalName={`${capitalize(fulfillment_type)} Settings`}
      >
        <Form.BaseForm
          name={formName}
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title={`${capitalize(fulfillment_type)} settings`}
            subtitle={store.name}
            actions={<Form.SubmitButton variant="primary" label="Save" />}
          />
          <Modal.Content>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />

            <TypeEnabledField
              fulfillmentType={fulfillment_type}
              curbsideSetting={
                store_curbside_pickup_setting?.setting || 'not_allowed'
              }
              storeTypeEnabled={
                store[fulfillment_type as keyof StoreV2] as boolean
              }
            />

            <Modal.ContentDivider />

            {fulfillment_type === 'delivery' && (
              <>
                <DeliveryStrategySection
                  deliveryMinimum={config.cart_minimum}
                />
                <FreeDeliverySection />
              </>
            )}

            {(fulfillment_type !== 'delivery' ||
              deliveryStrategy.radius ||
              deliveryStrategy.geofence) && (
              <TimingSettingsSection config={config} />
            )}

            <WindowSettingsSection
              windowsByDay={config.display_windows_by_day}
              fulfillmentType={config.fulfillment_type}
              windowMinutes={config.window_minutes}
              intervalMinutes={config.interval_minutes}
            />

            <Modal.ContentDivider />

            {fulfillment_type === 'curbside' && (
              <CurbsideInstructionsSection
                settings={store_curbside_pickup_setting}
              />
            )}

            <MaxOrdersSection
              fulfillmentType={fulfillment_type}
              maxOrdersPerReservationSlot={filteredMaxOrderSettings}
              maxOrdersPerWindow={config.max_orders_per_window}
            />
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>
    </FulfillmentSettingsProvider>
  );
};
