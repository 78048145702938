import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useState } from 'react';
import { z } from 'zod';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import {
  Button,
  DismissIcon,
  Flex,
  Modal,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';

import {
  ConditionType,
  EXCLUDE_TYPES,
  FORM_FIELD_DEFAULT_MARGIN,
} from '../../form';

export const Condition = z.object({
  label: z.string(),
  menu_product_id: z.number().optional(),
  value: z.string(),
});

export type Condition = z.infer<typeof Condition>;

interface Props {
  conditions: Condition[];
  conditionsLoading: boolean;
  disableAddButton?: boolean;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: Condition[]) => void;
  onOpenModal: () => void;
  type:
    | ConditionType.Brands
    | ConditionType.Categories
    | ConditionType.ExcludeBrands
    | ConditionType.ExcludeCategories
    | ConditionType.ExcludeProducts
    | ConditionType.Products
    | ConditionType.WeightAndPrice;
}

/**
 * Component for each condition line item in building conditions for a Special. This particular component can handle Brands, Categories, Products, or Exclude Products
 *
 * @param conditions: Simple list of conditions to display, values from a special should be mapped to match a simple conditions array
 * @param disableAddButton: Disable the "Add Condition" and "Select (condition)" buttons, only allows for removing any existing conditions
 * @param onOpenModal: Callback when user clicks "Add condition" or "Select products/brands/categories" buttons
 * @param onConditionsUpdate: Callback when item is removed by clicking "X" icon, or user clears all by clicking "Remove condition"
 * @param type: Type of condition
 * @param lastCondition: Removes bottom border if true
 */
export const ConditionByModal = ({
  conditions: conditionsProp,
  conditionsLoading,
  disableAddButton,
  onOpenModal,
  onConditionsUpdate,
  type,
  lastCondition,
}: Props) => {
  const [expanded, setExpanded] = useState(!!conditionsProp.length);
  const [conditions, setConditions] = useState(conditionsProp);

  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { isDisabled: globalSpecialDisabled } = useContext(
    GlobalSpecialsModalContext
  );

  useEffect(() => {
    setConditions(conditionsProp);
    setExpanded(!!conditionsProp.length);
  }, [conditionsProp]);

  const removeCondition = (value: string) => {
    const filteredConditions = conditions.filter(
      (condition) => condition.value !== value
    );
    setConditions(filteredConditions);
    setExpanded(!!filteredConditions.length);

    onConditionsUpdate(filteredConditions);
  };

  const removeAllConditions = () => {
    setConditions([]);
    setExpanded(false);

    onConditionsUpdate([]);
  };

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly || globalSpecialDisabled;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeAllConditions() : onOpenModal();
            }}
            variant={
              expanded
                ? 'tertiary'
                : EXCLUDE_TYPES.includes(type)
                ? 'destructive-secondary'
                : 'secondary'
            }
            disabled={(disableAddButton && !expanded) || isDisabled}
          />
          {expanded && (
            <Button
              label={`Select ${
                type === ConditionType.ExcludeProducts
                  ? 'products'
                  : type === ConditionType.ExcludeCategories
                  ? 'categories'
                  : type === ConditionType.ExcludeBrands
                  ? 'brands'
                  : type
              }`}
              variant="tertiary"
              onClick={onOpenModal}
              disabled={disableAddButton || isDisabled}
            />
          )}
        </Flex>
      </Flex>
      {expanded && (
        <>
          <Flex gap={12} flexWrap="wrap">
            {conditionsLoading ? (
              <Skeleton animate direction="row">
                <Skeleton.Bone
                  height={48}
                  width={210}
                  mr={24}
                  borderRadius="sm"
                />
                <Skeleton.Bone
                  height={48}
                  width={210}
                  mr={24}
                  borderRadius="sm"
                />
                <Skeleton.Bone height={48} width={210} borderRadius="sm" />
              </Skeleton>
            ) : (
              conditions.map((item) => (
                <Button
                  key={`key_${type}_${item.value}`}
                  variant={
                    type === ConditionType.ExcludeProducts ||
                    type === ConditionType.ExcludeCategories ||
                    type === ConditionType.ExcludeBrands
                      ? 'destructive-secondary'
                      : 'secondary'
                  }
                  startIcon={<DismissIcon size="sm" />}
                  label={item.label}
                  onClick={() => removeCondition(item.value)}
                  disabled={isDisabled}
                />
              ))
            )}
          </Flex>
          {!lastCondition && <Modal.ContentDivider />}
        </>
      )}
    </>
  );
};
