import { useEffect, useMemo, useState } from 'react';

import {
  useDeleteStoreSubdomain,
  useSaveStoreSubdomain,
} from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { ConfirmDeleteModal } from '@jane/shared/components';
import { Banner, Button, Modal, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';

interface SubdomainFormData {
  home_url?: string;
  hostname?: string;
}

const FORM_ERROR_NAME = 'subdomain-error';

export const SubdomainModal = ({
  open,
  setOpen,
  subtitle,
  settings,
  storeId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  settings: SubdomainFormData;
  storeId: number;
  subtitle: string;
}) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating subdomain. Please try again.'
  );
  const catchDeleteErrors = useCatchErrorsWithManager(
    'Error deleting subdomain. Please try again.'
  );

  const toast = useToast();
  const {
    mutateAsync: saveStoreSubdomain,
    isSuccess: saveSuccess,
    isLoading: isSaving,
  } = useSaveStoreSubdomain(storeId);
  const {
    mutateAsync: deleteStoreSubdomain,
    isSuccess: deleteSuccess,
    isLoading: isDeleting,
    isError: deleteError,
  } = useDeleteStoreSubdomain(storeId);

  const formName = 'Subdomain settings form';

  const hostname_helper_text = useMemo(() => {
    if (!settings || !settings.hostname) {
      return 'Example format: menu.yourstore.com. You will also need to setup the CNAME record with your domain registrar. It should point to www.iheartjane.com. Your subdomain listed with Jane should match your CNAME.';
    } else {
      return 'To make changes to the hostname, please reach out to Jane Partner Success.';
    }
  }, [settings]);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Subdomain updated',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [saveSuccess, setOpen]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.add({
        label: 'Subdomain deleted',
        variant: 'success',
      });
      setOpen(false);
    }
  }, [deleteSuccess, setOpen]);

  const onDeleteConfirm = () => {
    return catchDeleteErrors({
      submitMethod: () => deleteStoreSubdomain(),
      requestData: {},
      onValidationError: () => null,
      callback: () => null,
    });
  };

  const onSubmit = (data: SubdomainFormData) => {
    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.Subdomain,
          changed_attributes: Object.keys(dirtyFields),
        });
        return saveStoreSubdomain(data);
      },
      requestData: data,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  return (
    <>
      <ConfirmWrapperWithTracking
        open={open}
        setOpen={setOpen}
        hasChanges={isDirty}
        modalName={ModalNames.Subdomain}
      >
        <Form.BaseForm
          name={formName}
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title="Subdomain"
            subtitle={subtitle}
            actions={
              <>
                {!!settings.hostname && (
                  <Button
                    type="button"
                    label="Delete"
                    variant="destructive"
                    onClick={() => setConfirmDeleteModalOpen(true)}
                    mr={16}
                    disabled={isDeleting}
                  />
                )}
                <Form.SubmitButton
                  variant="primary"
                  label="Save"
                  loading={isSaving}
                />
              </>
            }
          />
          <Modal.Content>
            {deleteError && (
              <Banner
                variant="error"
                label="Error deleting subdomain. Please try again."
                full
                mb={20}
              />
            )}
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <Form.TextField
              label="Hostname"
              name="hostname"
              width="100%"
              mb={24}
              disabled={!!settings.hostname}
              defaultValue={settings.hostname}
              helperText={hostname_helper_text}
            />
            <Form.TextField
              label="Home URL"
              name="home_url"
              width="100%"
              defaultValue={settings.home_url}
              placeholder="http://www.example.com"
              helperText='Set a custom url for the "Back home" button'
            />
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>
      <ConfirmDeleteModal
        confirmDelete={onDeleteConfirm}
        open={confirmDeleteModalOpen}
        setOpen={setConfirmDeleteModalOpen}
      />
    </>
  );
};
