import { Card, Flex, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const DetailSection = ({ policyName }: { policyName?: string }) => {
  return (
    <Card border="grays-light" width={896} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Typography variant="header-bold" mb={40}>
            Details
          </Typography>

          <Form.TextField
            label="Policy name"
            name="name"
            placeholder="Cart limit policy name"
            defaultValue={policyName}
            required
          />
        </Flex>
      </Card.Content>
    </Card>
  );
};
