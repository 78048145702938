import get from 'lodash/get';
import { useCallback, useMemo } from 'react';

import type { SpecialRulesV2 } from '@jane/business-admin/types';

import { convertRulesToString } from '../convertRulesToString';

export const useUpdateRule = (
  fieldName: string,
  includesOrExcludes: 'includes' | 'excludes',
  rules: SpecialRulesV2,
  setRules: (value: SpecialRulesV2) => void
) => {
  const dataFromRules = useMemo(() => {
    return get(rules?.[includesOrExcludes], `[0].${fieldName}`, []);
  }, [convertRulesToString(rules)]);

  const onUpdate = useCallback(
    (newData: string[]) => {
      // TODO: Also used in ProductConditions, move to reusable thingy
      const currentRules = rules[includesOrExcludes] || [];
      const firstCondition = currentRules[0] || {};

      setRules({
        ...rules,
        [includesOrExcludes]: [{ ...firstCondition, [fieldName]: newData }],
      });
    },
    [convertRulesToString(rules)]
  );

  return { data: dataFromRules, onUpdate };
};
