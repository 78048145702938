import { useContext, useEffect } from 'react';

import { StoreFiltersContext } from '@jane/business-admin/providers';
import { Flex } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const StoreSearch = () => {
  const { searchFilter, setSearchFilter } = useContext(StoreFiltersContext);

  const { setValue } = useFormContext();

  useEffect(() => {
    if (!searchFilter) {
      setValue('search_input', '');
    }
  }, [searchFilter]);

  return (
    <Flex pr={12}>
      <Form.SearchField
        label="Store search"
        name="search_input"
        placeholder="Search name, city or id"
        defaultValue={searchFilter}
        autoFocus={true}
        onChange={(val) => setSearchFilter(val.toLowerCase())}
        isDebounced={false}
        width={300}
      />
    </Flex>
  );
};
