import pluralise from 'pluralise';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useCreateMenuRow,
  useProductTypes,
  useStoreSettings,
  useUpdateMenuRow,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useGetProductDetails,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import type {
  MenuProductForProductsTable,
  MenuRow,
} from '@jane/business-admin/types';
import {
  ErrorReasons,
  EventNames,
  ModalNames,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { JANE_DEFINED_ROWS } from '@jane/search/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Button,
  Card,
  Flex,
  Modal,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import type { ProductSearchSelection } from '../../../../ProductSearchModalUpdated';
import { ProductSearchModalUpdated } from '../../../../ProductSearchModalUpdated';
import { ScheduleCard } from '../../../../shared/specials/modal/schedule/ScheduleCard';
import { convert12HourTimeTo24HourTime } from '../../../../shared/specials/modal/schedule/ScheduleTimeValidation';
import { DetailsCard } from './DetailsCard';
import { MenuRowProductTable } from './MenuRowProductTable';
import { ModalHeader } from './ModalHeader';
import {
  DEFAULT_ROW_SCHEDULE,
  formatSchedule,
  parseSchedule,
} from './scheduleHelpers';

const FORM_ERROR_NAME = 'menu-row-errors';

type MenuRowProps = {
  closeModal: () => void;
  row?: MenuRow;
};

export const MenuRowModal = ({ row, closeModal }: MenuRowProps) => {
  const isCreateMode = !row;

  const [selectedProducts, setSelectedProducts] = useState<number[]>(
    row?.menu_products_ids?.all || []
  );

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${isCreateMode ? 'creating' : 'editing'} menu row. Please try again.`
  );
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const toast = useToast();

  const showMenuRowIdInModal = useFlag(FLAGS.scShowMenuRowIdInModal);
  const schedulingActive = useFlag(FLAGS.scMenuRowSchedulingFe);
  const { mutateAsync: createMenuRow, isSuccess: createSuccess } =
    useCreateMenuRow(id);
  const { mutateAsync: updateMenuRow, isSuccess: updateSuccess } =
    useUpdateMenuRow(id, row?.id || 0);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(id);

  const [totalNumberProductsSelected, setTotalNumberProductsSelected] =
    useState(row?.menu_products_ids?.all.length || 0);

  const {
    modalOpen: productSearchModalOpen,
    openModal: setProductSearchModalOpen,
    closeModal: setProductSearchModalClose,
  } = useModalActionsWithTracking(ModalNames.ProductSearch);

  const { data: allProductTypes, isFetched: productTypesLoaded } =
    useProductTypes();

  const { data: productsForTable, isLoading: loadingProductsForTable } =
    useGetProductDetails(selectedProducts, true);
  const productsForModal = useMemo(
    () =>
      productsForTable?.map<ProductSearchSelection>(({ product_id, id }) => ({
        product_id: product_id.toString(),
        id: id.toString(),
        menu_product_id: id,
      })) || [],
    [productsForTable]
  );

  const rowProductType = useMemo(() => {
    if (
      !productTypesLoaded ||
      isCreateMode ||
      !row?.applicability_rules ||
      !row?.applicability_rules.length
    ) {
      return { display: {} };
    }

    const matchingType =
      row?.applicability_rules[0].filter_type === 'type'
        ? allProductTypes?.find(
            ({ product_type }) =>
              product_type ===
              (row?.applicability_rules
                ? row?.applicability_rules[0].filter_value
                : '')
          )
        : allProductTypes?.find(
            ({ product_subtype }) =>
              product_subtype ===
              (row?.applicability_rules
                ? row?.applicability_rules[0].filter_value
                : '')
          );

    if (matchingType) {
      return {
        display: {
          category: matchingType?.product_type,
          subcategory: matchingType?.product_subtype,
        },
      };
    }
    return {};
  }, [allProductTypes, row?.applicability_rules, productTypesLoaded]);

  const parsedSchedule = parseSchedule(row, isCreateMode);

  const formMethods = useForm({
    defaultValues: {
      ...row,
      ...rowProductType,
      ...parsedSchedule,
      ['custom_display_name' as any]: row?.custom_display_name || row?.row_type,
    },
  });

  const {
    formState: { isDirty, dirtyFields },
    setValue,
    getValues,
  } = formMethods;

  const { display } = getValues();

  useEffect(() => {
    if (row) {
      if (JANE_DEFINED_ROWS.includes(row.row_type))
        setValue('display.type' as any, 'dynamic');
      // In reality we set row_type to the same as custom_display_name,
      // this fake row type is simply to show/hide the category selectors
      else {
        const fakeRowType = row.applicability_rules?.length
          ? 'subcategory'
          : 'custom';
        setValue('display.type' as any, fakeRowType);
      }
    }
  }, [row]);

  const onSelectProducts = (products: MenuProductForProductsTable[]) => {
    track({
      event: EventNames.EditSelectedProducts,
      initial_product_count: totalNumberProductsSelected,
      final_product_count: products.length,
    });

    setSelectedProducts(products.map(({ id }) => id));
    setTotalNumberProductsSelected(products.length);
  };

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      toast.add({
        label: `Menu row ${isCreateMode ? 'created' : 'updated'}!`,
        variant: 'success',
      });
      closeModal();
    }
  }, [createSuccess, updateSuccess, isCreateMode]);

  const onSubmit = (data: any) => {
    const {
      display,
      custom_display_name: name,
      schedule,
      start_date,
      start_time,
      end_date,
      end_time,
      use_store_close_time,
    } = data;

    const includeScheduleOverride = data['has_schedule_overrides'] === true;
    const formattedSchedule = includeScheduleOverride
      ? formatSchedule(schedule)
      : DEFAULT_ROW_SCHEDULE;

    const requestData = {
      ...data,
      applicability_rules:
        display.type === 'subcategory' &&
        display.subcategory !== 'None' &&
        display.subcategory !== ''
          ? [
              {
                filter_type: 'root_subtype',
                filter_value: display.subcategory,
              },
            ]
          : display.type === 'subcategory' &&
            (display.subcategory === 'None' || display.subcategory === '')
          ? [
              {
                filter_type: 'type',
                filter_value: display.category,
              },
            ]
          : [],
      // We use the name as the "row_type" (except for 'best_selling' and 'sale')
      row_type: JANE_DEFINED_ROWS.includes(row?.row_type || name)
        ? row?.row_type
        : name,
      menu_products: selectedProducts,
      schedule: {
        ...formattedSchedule,
        end_date: end_date || null,
        enabled_date_end: convert12HourTimeTo24HourTime(end_time),
        start_date: start_date || null,
        enabled_date_start: convert12HourTimeTo24HourTime(start_time),
        use_store_close_time,
        use_store_close_time_for_end: use_store_close_time,
        enabled: true, // todo: parse this out into an actual conditional
      },
    };

    const trackSubmit = (eventProps = {}) => {
      track({
        event: EventNames.EditedStoreMenuConfig,
        action: isCreateMode ? 'create' : 'update',
        modal_name: isCreateMode
          ? ModalNames.CreateMenuRow
          : ModalNames.EditMenuRow,
        type: display.type,
        changed_attributes: Object.keys(dirtyFields),
        setting_name: 'menu row',
        successful: true,
        url: normalizePath(pathname, id),
        ...eventProps,
      });
    };

    const submitMethod = async () => {
      await (isCreateMode
        ? createMenuRow(requestData)
        : updateMenuRow(requestData));
      trackSubmit();
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.InvalidParams,
        });
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: (errorBody) => {
        if (errorBody?.errors?.error?.includes('duplicate')) {
          // Duplication is based on the name, so show an error for that field if there is a duplicate
          throw new FormValidationError(FORM_ERROR_NAME, [
            {
              name: 'custom_display_name',
              message:
                'A menu row already exists with that name. Please change it and try again.',
            },
          ]);
        }

        trackSubmit({
          successful: false,
          error_reason: ErrorReasons.ServerError,
        });

        throw new Error(
          `Error ${
            isCreateMode ? 'creating' : 'editing'
          } menu row. Please try again.`
        );
      },
    });
  };

  const shouldShowAddProducts =
    display &&
    'type' in display &&
    !['dynamic', 'subcategory'].includes(display?.type as string);

  const shouldShowProductsCard = () => {
    if (!isCreateMode) return row?.row_type !== 'specials';
    else {
      return display && 'type' in display && display?.type !== 'subcategory';
    }
  };

  const shouldShowSchedulingCard =
    schedulingActive &&
    (!row || (row && !JANE_DEFINED_ROWS.includes(row?.row_type)));

  return (
    <>
      <ConfirmWrapperWithTracking
        open
        setOpen={closeModal}
        variant="full-screen"
        hasChanges={isDirty}
        modalName={
          isCreateMode ? ModalNames.CreateMenuRow : ModalNames.EditMenuRow
        }
      >
        <Form.BaseForm
          name={`${isCreateMode ? 'Create' : 'Edit'} menu row form`}
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <ModalHeader
            closeModal={closeModal}
            formErrorName={FORM_ERROR_NAME}
            isCreateMode={isCreateMode}
            showMenuRowId={showMenuRowIdInModal}
            row={row}
            storeId={id}
          />
          <Modal.Content>
            <Flex justifyContent="center">
              <Flex width="866px" flexDirection="column">
                <Form.ErrorBanner name={FORM_ERROR_NAME} />
                <Card width="100%" border="grays-light" mb={40}>
                  <Card.Content>
                    <Flex p={24} flexDirection="column">
                      <DetailsCard
                        isCreateMode={isCreateMode}
                        rowProductType={rowProductType}
                        defaultRowType={row?.row_type}
                        row={row}
                      />
                    </Flex>
                  </Card.Content>
                </Card>
                {shouldShowProductsCard() && (
                  <Card
                    width="100%"
                    border="grays-light"
                    data-testid="products-card"
                    mb={40}
                  >
                    <Card.Content>
                      <Flex p={24} flexDirection="column">
                        <Flex
                          mb={32}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Flex flexDirection="column">
                            <Typography variant="header-bold">
                              Products
                            </Typography>
                            <Typography variant="body" mt={8}>
                              {totalNumberProductsSelected}{' '}
                              {pluralise(
                                totalNumberProductsSelected,
                                'product'
                              )}
                            </Typography>
                          </Flex>
                          {shouldShowAddProducts ? (
                            <Button
                              variant="secondary"
                              label="Add Products"
                              onClick={() => setProductSearchModalOpen()}
                            />
                          ) : null}
                        </Flex>
                        <MenuRowProductTable
                          isLoading={!isCreateMode && loadingProductsForTable}
                          selectedProducts={productsForTable}
                          totalNumberProductsSelected={
                            totalNumberProductsSelected
                          }
                        />
                      </Flex>
                    </Card.Content>
                  </Card>
                )}
                {shouldShowSchedulingCard ? (
                  <ScheduleCard
                    isLoading={storeSettingsLoading || !productTypesLoaded}
                    storePayload={storePayload}
                  />
                ) : null}
              </Flex>
            </Flex>
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>

      {productSearchModalOpen && (
        <ProductSearchModalUpdated
          closeModal={() => setProductSearchModalClose()}
          onSubmit={onSelectProducts}
          selectedProducts={productsForModal}
        />
      )}
    </>
  );
};
