import { useParams } from 'react-router-dom';

import { useUpdateMenuRowOrder } from '@jane/business-admin/data-access';
import type { UpdateMenuRowOrderBody } from '@jane/business-admin/data-access';
import type { MenuRow } from '@jane/business-admin/types';
import { ModalNames, SettingNames } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';

import { ArrangeModal } from './ArrangeModal';

const isCustomMenuRow = (row: MenuRow) => {
  return 'id' in row;
};

export const ArrangeMenuRowsModal = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const { id = '' } = useParams<'id'>();
  const { mutateAsync: updateMenuRowOrder, isSuccess: updateRowOrderSuccess } =
    useUpdateMenuRowOrder(id);
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  return (
    <ArrangeModal<MenuRow, UpdateMenuRowOrderBody>
      allowDisablingForItem={(row: MenuRow) => isCustomMenuRow(row)}
      closeModal={closeModal}
      enabledKey="enabled"
      filterMenuItems={(rows) => {
        return rows.filter((row) => {
          if (myHighMenu) {
            return !row.is_category;
          } else {
            return row.row_type !== 'buy_again_row';
          }
        });
      }}
      formatItem={(row: {
        id: string;
        item: MenuRow;
      }): UpdateMenuRowOrderBody[number] => ({
        id: row.item.id,
        enabled: row.item.enabled,
        product_type: row.item.row_type,
        ranking: row.item.ranking,
      })}
      getItemEnabled={(row) => Boolean(row.enabled)}
      getItemId={(row: MenuRow) =>
        isCustomMenuRow(row) ? `${row.id}` : row.row_type
      }
      menuDataKey="menu_rows"
      modalName={ModalNames.ArrangeMenuRows}
      rankKey="ranking"
      settingName={SettingNames.MenuRowOrder}
      showHiddenIconForItem={(row: MenuRow) =>
        isCustomMenuRow(row) && !row.enabled
      }
      type="row"
      updateMenuOrder={updateMenuRowOrder}
      updateMenuOrderSuccess={updateRowOrderSuccess}
    />
  );
};
