import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoginBanner } from '@jane/business-admin/components';
import { useLogin } from '@jane/business-admin/data-access';
import {
  useNavigateAndTrack,
  useOnlyAnalyticsPermission,
} from '@jane/business-admin/hooks';
import {
  EventNames,
  NavigationSourceIds,
  businessPaths,
  track,
} from '@jane/business-admin/util';
import {
  Card,
  Flex,
  JaneLogo,
  Link,
  Typography,
  simplePasswordRegex,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

interface LoginType {
  email?: string;
  password?: string;
}

export const Login = () => {
  const navigate = useNavigateAndTrack();
  const { mutate, isSuccess, isError } = useLogin();
  const [searchParams, setSearchParams] = useSearchParams();
  const [recordedSearchParams, setRecordedSearchParams] = useState('');
  const handleLogin = ({ email, password }: LoginType) => {
    mutate({
      manager: {
        // can get these from 1Pass
        email,
        password,
      },
    });
  };
  const onlyAnalyticsUser = useOnlyAnalyticsPermission();

  useEffect(() => {
    if (isSuccess) {
      const navigateUrl = businessPaths.loggedInRoot(onlyAnalyticsUser);
      track({
        event: EventNames.LoggedIn,
        to_url: navigateUrl,
      });
      navigate(navigateUrl, NavigationSourceIds.Login);
    }

    if (searchParams.get('requestSent')) {
      setRecordedSearchParams('requestSent');
      setSearchParams('');
    }

    if (searchParams.get('passwordReset')) {
      setRecordedSearchParams('passwordReset');
      setSearchParams('');
    }
  }, [
    onlyAnalyticsUser,
    isSuccess,
    navigate,
    searchParams,
    setSearchParams,
    setRecordedSearchParams,
  ]);

  const onForgotPasswordClick = () => {
    navigate('/forgot-your-password', NavigationSourceIds.ForgotPasswordLink);
  };

  return (
    <Flex flexDirection="column" role="row">
      <Flex justifyContent="center">
        <Card mt={128}>
          <Card.Content background="grays-white">
            <Flex m={96} flexDirection="column" justifyContent="center">
              <Flex role="row" justifyContent="center">
                <JaneLogo size="lg" />
              </Flex>
              <Flex role="row" justifyContent="center" mt={-24} mb={40}>
                <Typography variant="caps-bold">BUSINESS</Typography>
              </Flex>
              <Form name="login" onSubmit={handleLogin}>
                {isError ? (
                  <LoginBanner isError>
                    <Typography color="error" variant="body-bold">
                      Invalid username or password!
                    </Typography>
                  </LoginBanner>
                ) : null}
                {recordedSearchParams === 'requestSent' ? (
                  <LoginBanner>
                    <Typography color="primary" variant="body-bold">
                      We sent you a password reset link. Please check your
                      email!
                    </Typography>
                  </LoginBanner>
                ) : null}
                {recordedSearchParams === 'passwordReset' ? (
                  <LoginBanner>
                    <Typography color="primary" variant="body-bold">
                      Password reset successfully!
                    </Typography>
                  </LoginBanner>
                ) : null}
                <Form.TextField
                  label="Email"
                  name="email"
                  type="email"
                  required
                />
                <Form.TextField
                  label="Password"
                  mt={12}
                  name="password"
                  type="password"
                  width={532}
                  required
                  pattern={simplePasswordRegex}
                  errorMessage="Please enter a password with a minimum of 8 characters."
                />
                <Flex role="row">
                  <Flex role="cell" width="50%" alignContent="left">
                    <Link mt={12} onClick={onForgotPasswordClick}>
                      Forgot password?
                    </Link>
                  </Flex>
                  <Flex role="cell" width="50%" justifyContent="right">
                    <Form.SubmitButton label="Login" mt={12} />
                  </Flex>
                </Flex>
              </Form>
            </Flex>
          </Card.Content>
        </Card>
      </Flex>
      <Flex pt={40} justifyContent="center">
        <Typography variant="body">
          Not a partner with Jane? Get in touch to get started.
        </Typography>
      </Flex>
      <Flex pt={12} justifyContent="center">
        <Link href="mailto:info@iheartjane.com">Contact us</Link>
      </Flex>
    </Flex>
  );
};
