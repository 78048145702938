import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CheckboxField } from '@jane/shared/reefer';
import type { CheckboxFieldProps } from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

const CHECKBOX_STATES = {
  checked: 'checked',
  unchecked: 'unchecked',
  indeterminate: 'indeterminate',
} as const;

const CHECKBOX_PROPS_MAP = {
  [CHECKBOX_STATES.checked]: { checked: true, indeterminate: undefined },
  [CHECKBOX_STATES.unchecked]: { checked: false, indeterminate: undefined },
  [CHECKBOX_STATES.indeterminate]: { checked: undefined, indeterminate: true },
} as const;

type CheckedStateProps = Pick<CheckboxFieldProps, 'checked' | 'indeterminate'>;

type SelectAllCheckboxProps = Pick<
  CheckboxFieldProps,
  'label' | 'name' | 'onChange'
> & {
  applicableIds: string[];
  labelHidden?: boolean;
};

const useUniqueId = () => {
  const [uniqueFallbackId] = useState(() => uuidv4());
  return uniqueFallbackId;
};

export const SelectAllCheckbox = ({
  applicableIds,
  label,
  labelHidden = false,
  name,
  onChange,
}: SelectAllCheckboxProps) => {
  const defaultFallbackId = useUniqueId();

  let checkboxStateProps: CheckedStateProps =
    CHECKBOX_PROPS_MAP[CHECKBOX_STATES.unchecked];

  const { watch, setValue } = useFormContext();

  const values = watch([...applicableIds]);
  const selectAllCheckboxKey =
    applicableIds
      .map((id, index) => {
        const hasRegisteredState = values[index] !== undefined;

        return hasRegisteredState ? id : null;
      })
      .filter(Boolean)
      .join('-') || defaultFallbackId;

  const numberOfIds = applicableIds.length;

  const selectedCount = values.filter(Boolean).length;

  const hasProvidedIds = numberOfIds > 0;
  const allSelected = hasProvidedIds && selectedCount === numberOfIds;
  const noneSelected = hasProvidedIds && selectedCount === 0;
  const hasIndeterminateSelection =
    hasProvidedIds && !allSelected && !noneSelected;

  if (allSelected) {
    checkboxStateProps = CHECKBOX_PROPS_MAP[CHECKBOX_STATES.checked];
  }

  if (hasIndeterminateSelection) {
    checkboxStateProps = CHECKBOX_PROPS_MAP[CHECKBOX_STATES.indeterminate];
  }

  const handleCheckboxChange = (isChecked: boolean) => {
    onChange && onChange(isChecked);

    applicableIds.forEach((fieldId) => {
      setValue(fieldId, isChecked, { shouldDirty: true });
    });
  };

  return (
    <CheckboxField
      key={selectAllCheckboxKey}
      mt={24}
      mb={16}
      onChange={handleCheckboxChange}
      label={label}
      name={name}
      labelHidden={labelHidden}
      {...checkboxStateProps}
    />
  );
};
