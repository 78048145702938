import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';

import type { GoogleFontFamily } from '@jane/shared-ecomm/data-access';
import { ChevronDownIcon, List, TypeAhead } from '@jane/shared/reefer';
import type { LabeledOption, Nullable } from '@jane/shared/types';
import { loadFont } from '@jane/shared/util';

import { TextWithFont } from './TextWithFont';

const ListItemButton = styled('button')(({ theme }) => ({
  height: '100%',
  padding: '12px 8px',
  textAlign: 'left',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.colors.grays.hover,
  },
}));

interface FontListItemProps {
  /** Name of the font family to display */
  displayName?: string;
  /** Underlying font family name to use in CSS rules */
  family: string;
  /** URL to the minimal font face needed to draw this font */
  fontUrl?: string;
  onClick: () => void;
}

const FontListItem = ({
  displayName,
  family,
  fontUrl,
  onClick,
}: FontListItemProps) => {
  return (
    <li>
      <ListItemButton onClick={onClick}>
        <InView
          onChange={(inView) =>
            inView && loadFont({ fontFamily: family, url: fontUrl })
          }
          triggerOnce
        >
          <TextWithFont $fontFamily={family}>
            {displayName ?? family}
          </TextWithFont>
        </InView>
      </ListItemButton>
    </li>
  );
};

const Field = styled('div')<{ $fontFamily?: string }>(
  ({ $fontFamily, theme }) => ({
    display: 'flex',
    fontFamily: $fontFamily,
    padding: '16px 12px',
    borderRadius: 12,
    border: `1px solid ${theme.colors.grays.light}`,
    '&:hover': {
      backgroundColor: theme.colors.grays.hover,
    },
  })
);

interface SelectedFontFieldProps {
  fontFamily: LabeledOption;
}

const SelectedFontField = ({
  fontFamily: { label, value },
}: SelectedFontFieldProps) => {
  return (
    <Field $fontFamily={value}>
      {label}
      <ChevronDownIcon ml="auto" />
    </Field>
  );
};

export interface FontPickerProps {
  activeFontFamily?: Nullable<LabeledOption>;
  defaultFontFamily: LabeledOption;
  fontFamilies: GoogleFontFamily[];
  label: string;
  limit?: number;
  onChange: (activeFontFamily: Nullable<GoogleFontFamily>) => void;
}

export const FontPicker = ({
  activeFontFamily,
  defaultFontFamily,
  fontFamilies,
  label,
  limit = 100,
  onChange,
}: FontPickerProps) => {
  const [query, setQuery] = useState('');

  const queryMatchesDefaultFamily = useMemo(
    () => defaultFontFamily.label.toLowerCase().includes(query.toLowerCase()),
    [defaultFontFamily, query]
  );
  const filteredFontFamilies = useMemo(
    () =>
      fontFamilies.filter(({ family }) =>
        family.toLowerCase().includes(query.toLowerCase())
      ),
    [fontFamilies, query]
  );

  return (
    <TypeAhead
      ariaLabel={label}
      isDebounced={false}
      labelHidden
      listAriaLabel=""
      onChange={setQuery}
      query={query}
      customOptions={({ closePopover }) => (
        <List
          px={16}
          py={0}
          label={`${label}-list`}
          style={{
            maxHeight: '420px',
            overflowY: 'auto',
          }}
        >
          {queryMatchesDefaultFamily && (
            <FontListItem
              onClick={() => {
                onChange(null);
                closePopover();
              }}
              displayName={defaultFontFamily.label}
              family={defaultFontFamily.value}
            />
          )}

          {filteredFontFamilies.slice(0, limit).map((font) => (
            <FontListItem
              key={font.family}
              onClick={() => {
                onChange(font);
                closePopover();
              }}
              family={font.family}
              fontUrl={font.menu}
            />
          ))}
        </List>
      )}
      style={{
        width: '100%',
      }}
      popoverTarget={
        <SelectedFontField fontFamily={activeFontFamily ?? defaultFontFamily} />
      }
    />
  );
};
