import capitalize from 'lodash/capitalize';
import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { useSaveStoreNotifications } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  Banner,
  Flex,
  InfoIcon,
  Modal,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import type { StoreNotificationsFormData } from './form';

interface Props {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  subtitle: string;
  title?: string;
}

const FORM_ERROR_NAME = 'notification-error';

export function NotificationSettingsModal({
  title = 'Notifications',
  subtitle,
  open,
  onClose,
  children,
}: Props) {
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating notifications settings. Please try again.'
  );

  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const {
    mutateAsync: saveNotifications,
    isSuccess: saveNotificationsSuccess,
  } = useSaveStoreNotifications(storeId);

  useEffect(() => {
    if (saveNotificationsSuccess) {
      toast.add({
        label: 'Notifications settings updated',
        variant: 'success',
      });
      onClose();
    }
  }, [saveNotificationsSuccess]);

  // TODO: This is really generic since the field names are not specific, add more context using the input names?
  const parsedDirtyFields = useMemo(() => {
    const parsedFields = new Set();
    if (dirtyFields['status_messages']) {
      dirtyFields['status_messages'].forEach((sm: any) => {
        Object.keys(sm).forEach((key) => {
          parsedFields.add(key);
        });
      });
    }
    if (dirtyFields['dismissal_messages']) {
      dirtyFields['dismissal_messages'].forEach((dm: any) => {
        Object.keys(dm).forEach((key) => {
          parsedFields.add(key);
        });
      });
    }
    return parsedFields;
  }, [JSON.stringify(dirtyFields)]);

  const parseMessageErrors = (
    fieldPrefix: string,
    messageErrors: Record<string, unknown>
  ) => {
    const errors: any[] = [];
    // Status message errors come back in an object where the key is the status_messages index where this error occurred,
    // and the value is any fields with corresponding errors
    for (const messageIndex in messageErrors) {
      Object.entries(messageErrors[messageIndex] as any).forEach(
        ([fieldName, errorArray]) => {
          const name = `${fieldPrefix}.${messageIndex}.${fieldName}`;
          errors.push({ name, message: (errorArray as string[]).join(',') });
        }
      );
    }
    return errors;
  };

  const onSubmit = useCallback(
    (data: StoreNotificationsFormData) => {
      const requestData = {
        status_messages: data.status_messages || [],
        dismissal_messages: data.dismissal_messages || [],
      };
      const submitMethod = () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.Notifications,
          changed_attributes: Array.from(parsedDirtyFields) as string[],
        });

        return saveNotifications(requestData);
      };
      return catchSubmitErrors({
        submitMethod,
        requestData,
        onValidationError: (validationErrors: {
          dismissal_messages?: Record<string, unknown>;
          send_cart_status_updates_via_email?: Record<string, unknown>;
          status_messages?: Record<string, unknown>;
        }) => {
          const emailErrors = parseValidationErrors(
            validationErrors?.send_cart_status_updates_via_email || {}
          );
          const statusErrors = validationErrors?.status_messages
            ? parseMessageErrors(
                'status_messages',
                validationErrors.status_messages
              )
            : [];
          const dismissalErrors = validationErrors?.dismissal_messages
            ? parseMessageErrors(
                'dismissal_messages',
                validationErrors.dismissal_messages
              )
            : [];
          throw new FormValidationError(FORM_ERROR_NAME, [
            ...emailErrors,
            ...statusErrors,
            ...dismissalErrors,
          ]);
        },
      });
    },
    [parsedDirtyFields]
  );

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      modalName={title}
    >
      <Form.BaseForm
        name="notification-settings"
        onSubmit={onSubmit}
        formMethods={formMethods}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={capitalize(title)}
          subtitle={subtitle}
          actions={<Form.SubmitButton label="Save" />}
        />

        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Banner
            icon={<InfoIcon />}
            typography="body-bold"
            mb={12}
            label="Note: To ensure your texting service is not flagged as
              non-compliant by the carriers, Jane will remove the following
              words: cannabis, cbd, weed, marijuana, nugs, nug, joint, sativa, indica,
              hybrid, ganja, mary jane, dispensary, buds, gummies, edibles."
          />
          <Banner
            icon={<InfoIcon />}
            typography="body-bold"
            mb={12}
            label="Note: Including your store's phone number will be flagged as
              non-compliant by the carriers and automatically redacted. Efforts
              to circumvent this rule may result in deactivation of SMS support
              for your store while on the Jane platform."
          />

          <Flex my={24}>
            <Typography variant="body-bold">Notification type</Typography>
          </Flex>

          {children}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
}
