import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import type {
  AbbreviatedMenuRow,
  StoreSettingsPayload,
} from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT, MODAL_CARD_WIDTH } from '@jane/business-admin/util';
import {
  Button,
  Card,
  EditIcon,
  Flex,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { useFormContext } from '@jane/shared/reefer-hook-form';
import { Table } from '@jane/shared/reefer-table';

import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../TableWithBorderSeparator';
import { MenuRowsModal } from './MenuRowsModal';

export const LoadingMenuRowsTable = ({ arraySize }: { arraySize: number }) => (
  <CollapsedBorderTable>
    <Table.Head>
      <Table.Row>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Name
          </Typography>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Typography variant="caps" color="grays-mid">
            Visibility
          </Typography>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Head>
    {Array(arraySize)
      .fill(null)
      .map((_, index) => {
        return (
          <TableRowWithBorder key={`loading_${index}`} hasBorder={true}>
            <td width="75%">
              <Skeleton animate width="80%">
                <Skeleton.Bone height={BODY_LINE_HEIGHT} my={16} width="100%" />
              </Skeleton>
            </td>
            <td>
              <Skeleton animate width="100%">
                <Skeleton.Bone height={BODY_LINE_HEIGHT} my={16} width="80%" />
              </Skeleton>
            </td>
          </TableRowWithBorder>
        );
      })}
  </CollapsedBorderTable>
);

const LoadingMenuRows = () => {
  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={0}>
        <Typography variant="header-bold">Menu rows</Typography>
        <Button.Icon
          ariaLabel="edit menu products product groups"
          icon={<EditIcon />}
          variant="secondary"
          disabled={true}
        />
      </Flex>
      <LoadingMenuRowsTable arraySize={3} />
    </>
  );
};

export const Row = styled.tr<{ hideBottomBorder: boolean }>(
  ({ hideBottomBorder }) => {
    const theme = useTheme();

    return {
      '& td:first-of-type, th:first-of-type': {
        ...spacing({ pl: 24 }),
      },
      '& td:last-of-type, th:last-of-type': {
        ...spacing({ pr: 24 }),
      },
      '& td': {
        borderBottomColor: hideBottomBorder
          ? theme.colors.background
          : theme.colors.grays.light,
      },
      display: 'table-row',
      verticalAlign: 'middle',
      zIndex: 1,
    };
  }
);

export const MenuRowsCard = ({
  isLoading,
  menuRowModalOpen,
  rows,
  setMenuRowModalClose,
  setMenuRowModalOpen,
  store,
}: {
  isLoading: boolean;
  menuRowModalOpen: boolean;
  rows?: AbbreviatedMenuRow[];
  setMenuRowModalClose: (name?: string) => void;
  setMenuRowModalOpen: (name?: string) => void;
  store?: StoreSettingsPayload;
}) => {
  const { setValue } = useFormContext();
  const [selectedMenuRows, setSelectedMenuRows] = useState<
    AbbreviatedMenuRow[]
  >([]);

  useEffect(() => {
    if (rows) {
      setSelectedMenuRows(rows);
    }
  }, [rows]);

  useEffect(() => {
    setValue('custom_rows', selectedMenuRows);
  }, [selectedMenuRows]);

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          {isLoading ? (
            <LoadingMenuRows />
          ) : (
            <>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb={selectedMenuRows.length > 0 ? 40 : 0}
              >
                <Typography variant="header-bold">Menu rows</Typography>
                <Button.Icon
                  ariaLabel="edit menu products product groups"
                  icon={<EditIcon />}
                  variant="secondary"
                  onClick={() => setMenuRowModalOpen()}
                />
              </Flex>
              {selectedMenuRows.length > 0 && (
                <CollapsedBorderTable>
                  <Table.Head>
                    <Table.Row>
                      <Table.HeaderCell>
                        <Typography variant="caps" color="grays-mid">
                          Name
                        </Typography>
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Typography variant="caps" color="grays-mid">
                          Visibility
                        </Typography>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {selectedMenuRows
                      .sort((a, b) => Number(b.enabled) - Number(a.enabled))
                      .map((row, index) => {
                        const isLast = index + 1 === selectedMenuRows.length;

                        return (
                          <Row hideBottomBorder={isLast} key={row.id}>
                            <Table.Cell width="75%">
                              <Typography>{row.custom_display_name}</Typography>
                            </Table.Cell>
                            <Table.Cell>
                              <Typography>
                                {row.enabled ? 'On' : 'Off'}
                              </Typography>
                            </Table.Cell>
                          </Row>
                        );
                      })}
                  </Table.Body>
                </CollapsedBorderTable>
              )}
            </>
          )}

          {menuRowModalOpen && (
            <MenuRowsModal
              initiallySelected={selectedMenuRows}
              open
              store={store}
              setSelected={setSelectedMenuRows}
              onClose={() =>
                setMenuRowModalClose('edit-menu-products-product-groups')
              }
            />
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};
