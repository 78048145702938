import styled from '@emotion/styled';

import { StoreSection } from '@jane/business-admin/components';
import { useFetchCurrentBloomMenu } from '@jane/business-admin/data-access';
import { businessPaths } from '@jane/business-admin/util';
import { useNavigateBack } from '@jane/shared/hooks';
import { ChevronLeftIcon, Flex, Typography } from '@jane/shared/reefer';

const StyledContainer = styled(Flex)(() => ({
  borderRadius: 24,
  marginTop: 60,
  marginBottom: 120,
  boxShadow: `0px 2px 16px 0px rgba(0, 0, 0, 0.05)`,
  overflow: 'hidden',
}));

export const PremiumStores = () => {
  const goBack = useNavigateBack(businessPaths.premium());

  const { data: bloomMenu } = useFetchCurrentBloomMenu();
  const stores = bloomMenu?.stores ?? [];

  return (
    <StyledContainer
      pt={40}
      pb={40}
      width="848px"
      mx="auto"
      flexDirection="column"
    >
      <Flex flexDirection="column" ml={24} mr={24}>
        <Flex
          gap={16}
          flexDirection="row"
          alignItems="center"
          alignContent="center"
        >
          <ChevronLeftIcon onClick={() => goBack()} altText="Go back" />
          <Typography variant="header-bold" branded>
            My stores
          </Typography>
        </Flex>
        {/* todo: add banner and search field within this flexbox */}
      </Flex>
      {stores.map((store) => (
        <StoreSection key={store.id} store={store} />
      ))}
    </StyledContainer>
  );
};
