import { useContext } from 'react';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const SpendingThreshold = ({
  isCreateMode,
  name,
}: SpecialTypeProps['spending_threshold'] & {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);

  const { getValues } = useFormContext();
  const displayValues = getValues('display');

  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      gap={24}
      flexDirection="column"
    >
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <>
          <Flex width="100%" gap={24} mb={24}>
            <Form.NumberField
              width={'100%'}
              name={`display.spending_threshold.threshold_number_of_items_in_cart`}
              label={'Minimum products requirement'}
              min={1}
              required
            />
            <Form.NumberField
              width={'100%'}
              name={`display.spending_threshold.max_number_of_discounted_products`}
              label={'Maximum products discounted'}
              min={1}
              required
            />
          </Flex>
          <Flex width="100%" gap={24} mb={24}>
            <Form.NumberField
              width={'100%'}
              name={`display.spending_threshold.max_applications_per_cart`}
              label={'Maximum uses per order'}
            />
          </Flex>
        </>
      ) : (
        <>
          <Flex gap={24}>
            <Flex width="50%" flexDirection="column">
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-spending-threshold-min-products-required"
              >
                Minimum products requirement
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {displayValues.spending_threshold
                  .threshold_number_of_items_in_cart || 'N/A'}
              </Typography>
            </Flex>
            <Flex width="50%" flexDirection="column">
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-spending-threshold-max-products-discounted"
              >
                Maximum products discounted
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {displayValues.spending_threshold
                  .max_number_of_discounted_products || 'N/A'}
              </Typography>
            </Flex>
          </Flex>
          <Flex gap={24} mb={12}>
            <Flex width="50%" flexDirection="column" mb={12}>
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-spending-threshold-max-uses"
              >
                Maximum uses per order
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {displayValues.spending_threshold.max_applications_per_cart ||
                  'N/A'}
              </Typography>
            </Flex>
            <Flex width="50%" flexDirection="column" mb={12}>
              <Typography
                color="grays-black"
                variant="body-bold"
                data-testid="non-janepos-synced-spending-threshold-allow-discounts"
              >
                Allow qualifying products to be discounted
              </Typography>
              <Typography color="grays-black" variant="body" mt={4}>
                {displayValues.spending_threshold.settings
                  .allow_discounts_on_required_products
                  ? 'Allowed'
                  : 'N/A'}
              </Typography>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
