import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import {
  ExpandableNav,
  ExpandableNavItem,
  MenuSelector,
  NavItem,
} from '@jane/business-admin/components';
import {
  saveCurrentBloomMenuId,
  useFetchCurrentBloomMenu,
} from '@jane/business-admin/data-access';
import { Flex, Typography } from '@jane/shared/reefer';

const StyledFlex = styled(Flex)(() => ({
  borderRadius: 24,
  boxShadow: `0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 2px 16px 0px rgba(0, 0, 0, 0.05)`,
  overflow: 'hidden',
  marginBottom: '160px',
}));

export const Premium = () => {
  const currentMenuQuery = useFetchCurrentBloomMenu();
  const [currentMenuId, setCurrentMenuId] = useState<string | undefined>(
    currentMenuQuery.data?.id.toString()
  );

  const onMenuChange = (newMenuId: string) => {
    setCurrentMenuId(newMenuId);
    saveCurrentBloomMenuId(newMenuId);
  };

  useEffect(() => {
    if (currentMenuQuery.data) {
      setCurrentMenuId(currentMenuQuery.data.id.toString());
    }
  }, [currentMenuQuery.data]);

  return (
    <Flex>
      <StyledFlex mt={96} mx="auto" width="50%" flexDirection="column">
        <Typography variant="header-bold" as="h1" mt={40} ml={40}>
          Welcome to Ecommerce Premium
        </Typography>
        <MenuSelector currentMenuId={currentMenuId} onChange={onMenuChange} />
        <NavItem title="My stores" to={`${currentMenuId}/stores`} />
        <ExpandableNav title="Global style settings">
          <ExpandableNavItem
            title="Fonts"
            to={`${currentMenuId}/fonts`}
            position="first"
          />
          <ExpandableNavItem
            title="Colors"
            to={`${currentMenuId}/colors`}
            position="last"
          />
        </ExpandableNav>
        <ExpandableNav title="Global shop settings">
          <ExpandableNavItem
            title="Logo"
            to={`${currentMenuId}/logo`}
            position="first"
          />
          <ExpandableNavItem
            title="Age gate"
            to={`${currentMenuId}/age-gate`}
          />
          <ExpandableNavItem
            title="Main menu"
            to={`${currentMenuId}/main-menu`}
          />
          <ExpandableNavItem title="Footer" to={`${currentMenuId}/footer`} />
          <ExpandableNavItem
            title="Google SSO"
            to={`${currentMenuId}/google-sso`}
            position="last"
          />
        </ExpandableNav>
      </StyledFlex>
    </Flex>
  );
};
