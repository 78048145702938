import { useCallback, useContext, useEffect, useRef } from 'react';

import { StoreFiltersContext } from '@jane/business-admin/providers';
import { EventNames, FilterNames, track } from '@jane/business-admin/util';
import type { PopoverContextProps } from '@jane/shared/reefer';
import { Button, ChevronDownIcon, Flex, Popover } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const StoreTypeFilter = () => {
  // Only track if user has interacted with the filter
  const hasBeenChanged = useRef(false);
  const { typeFilter, setTypeFilter } = useContext(StoreFiltersContext);

  const changeTypeFilter = useCallback(
    (type: string, closePopover: () => void) => {
      if (type && type !== typeFilter) {
        track({
          all_selected_values: [type.toLowerCase()],
          event: EventNames.SelectedFilter,
          filter_name: FilterNames.StoreType,
          selected_value: type.toLowerCase(),
          url: '/stores',
        });
      }
      hasBeenChanged.current = true;

      setTypeFilter(type);
      closePopover();
    },
    [typeFilter]
  );

  useEffect(() => {
    // Track reset all filters from dropdown or "Clear filters" button
    if (typeFilter === '' && hasBeenChanged.current) {
      track({
        all_selected_values: [],
        deselected_value: 'all',
        event: EventNames.DeselectedFilter,
        filter_name: FilterNames.StoreType,
        url: '/stores',
      });
    }
  }, [typeFilter]);

  return (
    <Popover
      target={
        <Flex>
          <Button
            full
            variant="tertiary"
            label={typeFilter || 'All types'}
            endIcon={<ChevronDownIcon size="sm" />}
          ></Button>
        </Flex>
      }
      mx={16}
      label=""
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="Store type filter" padding={false}>
          <Form.RadioFieldGroup
            name="type-list"
            defaultChecked={typeFilter || 'All types'}
            onChange={(type: string) => changeTypeFilter(type, closePopover)}
            options={[
              {
                id: 'All types',
                label: 'All types',
                value: 'All types',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    ariaLabel={`all-label`}
                    onClick={() => changeTypeFilter('', closePopover)}
                  >
                    {children}
                  </Flex>
                ),
              },
              {
                id: 'Recreational',
                label: 'Recreational',
                value: 'Recreational',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    ariaLabel={`rec-label`}
                    onClick={() =>
                      changeTypeFilter('Recreational', closePopover)
                    }
                  >
                    {children}
                  </Flex>
                ),
              },
              {
                id: 'Medical',
                label: 'Medical',
                value: 'Medical',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    mb={16}
                    ariaLabel={`med-label`}
                    onClick={() => changeTypeFilter('Medical', closePopover)}
                  >
                    {children}
                  </Flex>
                ),
              },
            ]}
          />
        </Popover.Content>
      )}
    </Popover>
  );
};
