import { useContext } from 'react';

import { GlobalSpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const CartTotal = ({ name }: SpecialTypeProps['cart_total']) => {
  const { isDisabled } = useContext(GlobalSpecialsModalContext);

  return (
    <Flex mb={24} width={'100%'} flexDirection="column">
      <>
        <Form.NumberField
          width="50%"
          name={`${name}.threshold`}
          label="Cart total threshold"
          mb={4}
          disabled={isDisabled}
        />
        <Typography color="grays-mid" variant="body" mt={16}>
          Enter the cart total the customer must reach to activate this special.
        </Typography>
      </>
    </Flex>
  );
};
