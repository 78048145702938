import { useState } from 'react';

import { useUpdatePassword } from '@jane/business-admin/data-access';
import {
  EventNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import {
  Flex,
  InfoIcon,
  Modal,
  Typography,
  simplePasswordRegex,
} from '@jane/shared/reefer';
import { Form, FormValidationError } from '@jane/shared/reefer-hook-form';
import { trackError } from '@jane/shared/util';

type ChangePasswordFormData = {
  current_password: string;
  password: string;
  password_confirmation: string;
};

const FORM_ERROR_NAME = 'change-password-error';

export const ChangePasswordModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { mutateAsync: updatePassword } = useUpdatePassword();
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const formName = 'Change password form';

  const handleValidationErrors = (errors: any) => {
    // Catch errors having to do with missing fields
    if (errors.error === 'Validation Error') {
      trackError('Business admin request validation error', errors.validations);
      throw new FormValidationError(
        FORM_ERROR_NAME,
        parseValidationErrors(errors.validations)
      );
    }
    // Catch errors having to do with incorrect current password etc.
    else {
      throw new FormValidationError(
        FORM_ERROR_NAME,
        parseValidationErrors(errors.validations)
      );
    }
  };

  const onSubmit = async (formData: ChangePasswordFormData) => {
    try {
      await updatePassword(formData);
      track({
        event: EventNames.EditedPassword,
      });
    } catch (err: any) {
      if (err.message === 'Api Request Failed') {
        const response = err.response;
        if (response.status === 400 || response.status === 422) {
          const body = await response.json();
          handleValidationErrors(body.errors);
        }
      }
      trackError('Error updating password. Please try again.');
      throw new Error('Error updating password. Please try again.');
    }
  };

  return (
    <ConfirmDiscardWrapper
      open={open}
      setOpen={setOpen}
      hasChanges={formIsDirty}
      appId="root"
      variant="standard-dialogue"
    >
      <Form
        name={formName}
        formErrorName={FORM_ERROR_NAME}
        onSubmit={onSubmit}
        onDirty={setFormIsDirty}
      >
        <Modal.Header
          title="Change password"
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex flexDirection="column">
            <Flex mb={24}>
              <Form.TextField
                label="Current password"
                name="current_password"
                type="password"
                width="calc(50% - 12px)"
                required
                pattern={simplePasswordRegex}
                errorMessage="Please enter a password with a minimum of 8 characters."
              />
            </Flex>
            <Modal.ContentDivider />
            <Flex gap={24}>
              <Form.TextField
                label="New password"
                name="password"
                type="password"
                required
              />
              <Form.TextField
                label="Confirm new password"
                name="password_confirmation"
                type="password"
                required
              />
            </Flex>
            <Flex mt={24}>
              <InfoIcon />
              <Typography ml={12}>
                Password must be at least 10 characters and contain a letter,
                number, and symbol.
              </Typography>
            </Flex>
          </Flex>
        </Modal.Content>
      </Form>
    </ConfirmDiscardWrapper>
  );
};
