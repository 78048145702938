import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginBanner } from '@jane/business-admin/components';
import { useRequestPasswordReset } from '@jane/business-admin/data-access';
import type { RequestPasswordResetData } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { Card, Flex, JaneLogo, Link, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';
import { trackError } from '@jane/shared/util';

export const RequestPasswordReset = () => {
  const navigate = useNavigate();
  const {
    mutateAsync: requestPasswordReset,
    isSuccess,
    isError,
  } = useRequestPasswordReset();
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error requesting password reset.'
  );

  useEffect(() => {
    if (isSuccess) {
      navigate({
        pathname: '/login',
        search: '?requestSent=true',
      });
    }
  }, [isSuccess, navigate]);

  const onSubmit = async (data: RequestPasswordResetData) => {
    try {
      await catchSubmitErrors({
        submitMethod: () => requestPasswordReset(data),
        requestData: data,
        onValidationError: () => null,
      });
    } catch (err: any) {
      if (err.message === 'Api Request Failed') {
        const response = err.response;
        const body = await response.json();
        trackError(
          'Error requesting password reset link. Please try again.',
          body.errors
        );
        throw new Error(
          'Error requesting password reset link. Please try again.'
        );
      }
    }
  };

  return (
    <Flex flexDirection="column" role="row">
      <Flex justifyContent="center">
        <Card mt={128}>
          <Card.Content background="grays-white">
            <Flex m={96} flexDirection="column" justifyContent="center">
              <Flex role="row" justifyContent="center">
                <JaneLogo size="lg" />
              </Flex>
              <Flex role="row" justifyContent="center" mt={-24} mb={40}>
                <Typography variant="caps-bold">BUSINESS</Typography>
              </Flex>
              <Form name="request-password-reset" onSubmit={onSubmit}>
                {isError ? (
                  <LoginBanner isError>
                    <Typography color="error" variant="body-bold">
                      Email could not be sent - please try again!
                    </Typography>
                  </LoginBanner>
                ) : null}
                <>
                  <Typography variant="body-bold">Forgot password?</Typography>
                  <Typography>
                    We’ll send you a link to update your password.
                  </Typography>
                </>
                <Form.TextField
                  mt={20}
                  label="Email"
                  name="email"
                  type="email"
                  required
                  width={532}
                />
                <Flex role="row">
                  <Flex role="cell" width="50%" alignContent="left">
                    <Link
                      mt={20}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      Return to login
                    </Link>
                  </Flex>
                  <Flex role="cell" width="50%" justifyContent="right">
                    <Form.SubmitButton label="Reset password" mt={20} />
                  </Flex>
                </Flex>
              </Form>
            </Flex>
          </Card.Content>
        </Card>
      </Flex>
      <Flex pt={40} justifyContent="center">
        <Typography variant="body">
          Not a partner with Jane? Get in touch to get started.
        </Typography>
      </Flex>
      <Flex pt={12} justifyContent="center">
        <Link href="mailto:info@iheartjane.com">Contact us</Link>
      </Flex>
    </Flex>
  );
};
