import * as t from 'io-ts';

import { tCustomRowSetting, tPhoto } from '@jane/shared/models';

const tFilterResponseOptional = t.partial({
  custom_rows: t.array(tCustomRowSetting),
});
const tFilterResponseRequired = t.type({
  brands: t.array(t.string),
  brand_subtypes: t.array(t.string),
  categories: t.array(t.string),
  subcategories: t.array(t.string),
  lineages: t.array(t.string),
  weights: t.array(t.string),
  content: t.array(t.type({ value: t.string, name: t.string })),
});
export const tFilterResponse = t.type({
  filters: t.intersection([tFilterResponseRequired, tFilterResponseOptional]),
});

export type FilterResponse = t.TypeOf<typeof tFilterResponse>;

const tProductSearchData = t.interface({
  id: t.number,
  brand: t.string,
  brand_subtype: t.string,
  category: t.string,
  description: t.string,
  full_name: t.string,
  image_urls: t.array(t.string),
  is_visible: t.boolean,
  lineage: t.string,
  name: t.string,
  photos: t.array(tPhoto),
  price_each: t.string,
  price_eighth_ounce: t.string,
  price_gram: t.string,
  price_half_gram: t.string,
  price_half_ounce: t.string,
  price_ounce: t.string,
  price_quarter_ounce: t.string,
  price_two_gram: t.string,
  product_id: t.number,
  store_id: t.number,
  subcategory: t.string,
});

export type ProductSearchData = t.TypeOf<typeof tProductSearchData>;

export const tSearchResponse = t.interface({
  products: t.array(t.any),
  meta: t.interface({
    page: t.number,
    page_total: t.number,
    number_of_pages: t.number,
    per_page: t.number,
    total: t.number,
    all_product_ids: t.array(t.any),
  }),
});

export type SearchResponse = t.TypeOf<typeof tSearchResponse>;
