import type { CurbsidePickupSettingObject } from '@jane/shared/models';
import {
  Flex,
  InfoIcon,
  Modal,
  Popover,
  Typography,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const CurbsideInstructionsSection = ({
  settings,
}: {
  settings: CurbsidePickupSettingObject | null;
}) => {
  return (
    <>
      <Form.TextAreaField
        defaultValue={settings?.instructions || undefined}
        label="Note to customer"
        name="instructions"
        mb={24}
      />

      <Flex>
        <Form.SwitchField
          defaultChecked={settings?.form_enabled}
          label={`Allow customers to notify you “I’m here”`}
          name="form_enabled"
        />
        <Popover
          openOn="hover"
          label="More information"
          target={<InfoIcon ml={12} />}
        >
          <Popover.Content label="More information">
            <Typography mb={8}>
              After a curbside order is marked as Ready For Pickup, the customer
              will receive an email with a link to submit a form that they
              complete when they arrive to collect their order.
            </Typography>
            <Typography>
              When the customer's "I'm Here" form is submitted, a receipt will
              print out from your Jane printer to notify your staff of the
              customer's arrival.
            </Typography>
          </Popover.Content>
        </Popover>
      </Flex>

      <Modal.ContentDivider />
    </>
  );
};
