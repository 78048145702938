import styled from '@emotion/styled';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useUpdateManyCheckoutAgreements } from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type { CheckoutAgreementV2 } from '@jane/shared/models';
import {
  Button,
  EditIcon,
  Flex,
  Modal,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import {
  CollapsedBorderTable,
  TableRowWithBorder,
} from '../../../../../TableWithBorderSeparator';
import { AgreementConditions } from './AgreementConditions';
import { AgreementDetails } from './AgreementDetails';
import { AgreementSettingsModal } from './AgreementSettingsModal';

const TableCell = styled.td({
  ...spacing({ py: 24, px: 12 }),
  '&:first-of-type': {
    ...spacing({ pl: 24 }),
  },
  '&:last-of-type': {
    ...spacing({ pr: 24 }),
  },
});

const AgreementRow = ({
  agreement,
  onToggle,
}: {
  agreement: CheckoutAgreementV2;
  onToggle: (enabled: boolean) => void;
}) => {
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.EditCheckoutAgreement
  );

  return (
    <>
      <TableRowWithBorder key={agreement.id} hasBorder>
        <TableCell>
          <Form.CheckboxField
            name={`agreement_${agreement.id}_enabled`}
            label="Enable agreement"
            labelHidden
            checked={agreement.active}
            onChange={onToggle}
          />
        </TableCell>
        <TableCell>
          <AgreementDetails
            external_link={agreement.external_link}
            label={agreement.label}
            truncateLabelTo={180}
          />
        </TableCell>
        <TableCell>
          <AgreementConditions
            consent_requirement={agreement.consent_requirement}
            reservation_mode={agreement.reservation_mode}
            width={180}
          />
        </TableCell>
        <TableCell>
          <Button.Icon
            icon={<EditIcon color="grays-mid" />}
            label="Edit agreement"
            onClick={() => openModal()}
          />
        </TableCell>
      </TableRowWithBorder>
      <AgreementSettingsModal
        open={modalOpen}
        onClose={() => closeModal()}
        agreement={agreement}
      />
    </>
  );
};

type CheckoutAgreementsFormData = CheckoutAgreementV2[];

export interface CheckoutAgreementsModalProps {
  agreements: CheckoutAgreementV2[];
  onClose: () => void;
  open: boolean;
  storeName: string;
}

const FORM_ERROR_NAME = 'checkout-agreements-error';

export const CheckoutAgreementsModal = ({
  open,
  onClose,
  storeName,
  agreements,
}: CheckoutAgreementsModalProps) => {
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const toast = useToast();
  const { storeId } = useContext(StoreDetailsContext);
  const {
    mutateAsync: saveManyCheckoutAgreements,
    isSuccess: saveManySuccess,
  } = useUpdateManyCheckoutAgreements(storeId);
  const [formData, setFormData] = useState<CheckoutAgreementsFormData>([]);
  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.AddCheckoutAgreement
  );
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating checkout agreements. Please try again.'
  );

  useEffect(() => {
    if (open) setFormData(agreements);
  }, [open, agreements]);

  const onSubmit = () => {
    const storeIdsByActive = formData.reduce(
      (
        prevValue: { active: number[]; inactive: number[] },
        newValue: CheckoutAgreementV2
      ) => {
        newValue.active
          ? prevValue['active'].push(newValue.id)
          : prevValue['inactive'].push(newValue.id);
        return prevValue;
      },
      { active: [], inactive: [] }
    );
    return catchSubmitErrors({
      submitMethod: () => {
        track({
          event: EventNames.EditedStoreSettings,
          modal_name: ModalNames.CheckoutAgreements,
          changed_attributes: Object.keys(dirtyFields).length
            ? ['selected_agreements']
            : [],
        });
        return saveManyCheckoutAgreements(storeIdsByActive);
      },
      requestData: storeIdsByActive,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        // Don't think this will ever be hit, we don't have Dry validation on this endpoint
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  useEffect(() => {
    if (saveManySuccess) {
      toast.add({
        label: 'Checkout agreements updated',
        variant: 'success',
      });
      onClose();
    }
  }, [saveManySuccess]);

  const toggleAll = useCallback((active: boolean) => {
    setFormData((prevData) =>
      prevData.map((agreement) => ({
        ...agreement,
        active,
      }))
    );
  }, []);

  const toggleAgreement = useCallback(
    (id: number) => (active: boolean) => {
      setFormData((prevData) => {
        return prevData.map((agreement) => {
          if (agreement.id !== id) return agreement;
          return {
            ...agreement,
            active,
          };
        });
      });
    },
    []
  );

  const active = useMemo(
    () => ({
      all: formData.every(({ active }) => active),
      none: formData.every(({ active }) => !active),
      count: formData.filter(({ active }) => active).length,
    }),
    [formData]
  );

  return (
    <>
      <ConfirmWrapperWithTracking
        open={open}
        setOpen={onClose}
        hasChanges={isDirty}
        parentId="checkout-agreements"
        modalName={ModalNames.CheckoutAgreements}
      >
        <Form.BaseForm
          name="checkout options"
          onSubmit={onSubmit}
          formMethods={formMethods}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title="Checkout agreements"
            subtitle={storeName}
            actions={
              <>
                <Button
                  label="Add checkout agreement"
                  variant="secondary"
                  onClick={() => openModal()}
                  data-testid="add-agreement-button"
                  mr={16}
                />
                {!!agreements.length && <Form.SubmitButton label="Save" />}
              </>
            }
          />
          <Modal.Content>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            {agreements.length ? (
              <>
                <Typography mb={24} data-testid="selected-agreements-count">
                  {active.count} checkout agreements selected
                </Typography>

                <CollapsedBorderTable aria-label="Checkout Agreements">
                  <thead>
                    <TableRowWithBorder hasBorder>
                      <TableCell>
                        <Form.CheckboxField
                          name="enable_all"
                          label="Enable all agreements"
                          labelHidden
                          checked={active.all}
                          indeterminate={!active.all && !active.none}
                          onChange={toggleAll}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="caps" color="grays-mid">
                          Agreement
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caps" color="grays-mid">
                          Conditions
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRowWithBorder>
                  </thead>

                  <tbody>
                    {formData.map((agreement) => (
                      <AgreementRow
                        key={agreement.id}
                        agreement={agreement}
                        onToggle={toggleAgreement(agreement.id)}
                      />
                    ))}
                  </tbody>
                </CollapsedBorderTable>
              </>
            ) : (
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Typography>
                  You have not created any checkout agreements. Click "Add
                  checkout agreement" to set this up.
                </Typography>
              </Flex>
            )}
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmWrapperWithTracking>
      {modalOpen && (
        <AgreementSettingsModal
          open={modalOpen}
          onClose={() => closeModal()}
          create
        />
      )}
    </>
  );
};
