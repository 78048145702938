import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  fetchUnpublishedProductQueryKey,
  useFetchUnpublishedProducts,
  useUpdateUnpublishedProduct,
} from '@jane/business-admin/data-access';
import { useHasPermissions } from '@jane/business-admin/hooks';
import { ProductsTableContext } from '@jane/business-admin/providers';
import {
  EventNames,
  ModalNames,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { useToast } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { BaseProductTable } from './BaseProductTable';
import { EmptyState } from './EmptyStates';
import { filterParams, sortParams } from './PublishedProductTable';
import { buildUnpublishedColumns } from './UnpublishedColumns';

export const UnpublishedProductTable = ({
  bulkEditModalOpen,
  columnFiltersParent,
  isBulkEditTable = false,
  selectedProducts,
  setColumnFiltersParent,
  setFullSelectedProducts,
  setSelectedProducts,
}: {
  bulkEditModalOpen: boolean;
  columnFiltersParent: ColumnFiltersState;
  isBulkEditTable?: boolean;
  selectedProducts: RowSelectionState;
  setColumnFiltersParent?: any;
  setFullSelectedProducts: any;
  setSelectedProducts: any;
}) => {
  const navigate = useNavigate();
  const userCanEditUnpublishedProducts = useHasPermissions([
    Permission.EditUnpublishedProducts,
  ]);
  const { setCanEditProducts } = useContext(ProductsTableContext);

  useEffect(() => {
    setCanEditProducts(userCanEditUnpublishedProducts);
  }, [userCanEditUnpublishedProducts]);

  const formMethods = useForm();
  const { id = '' } = useParams<'id'>();
  const loadNextRef = useRef(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const toast = useToast();

  const {
    mutateAsync: publishProduct,
    isLoading: isPublishing,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateUnpublishedProduct(id);

  const { pathname } = useLocation();
  const openUnpublishedModal = (row: any) => {
    track({
      event: EventNames.OpenedModal,
      modal_name: ModalNames.UpdateMenuProduct,
      url: normalizePath(pathname, id),
    });
    navigate(`${row.unmapped_product_id}`);
  };

  const {
    data,
    isFetched,
    isFetching,
    isFetchingNextPage,
    isSuccess,
    fetchNextPage,
    hasNextPage,
  } = useFetchUnpublishedProducts(id, {
    ...filterParams(columnFiltersParent),
    ...sortParams(sorting),
  });

  const totalRowCount = useMemo(
    () => data?.pages?.[0]?.meta?.total ?? 0,
    [JSON.stringify(data)]
  );

  const columns = useMemo(
    () =>
      buildUnpublishedColumns({
        isBulkEditTable,
        isFetched,
        openUnpublishedModal,
        publishProduct,
        isPublishing,
        totalRowCount,
        sorting,
      }),
    [
      JSON.stringify(data),
      isBulkEditTable,
      isFetched,
      openUnpublishedModal,
      publishProduct,
      isPublishing,
      totalRowCount,
      sorting,
    ]
  );

  const allRows = useMemo(
    () =>
      data?.pages
        ?.flatMap((page) => page.meta.all_product_ids)
        .reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {},
    [JSON.stringify(data)]
  );

  const productData = useMemo(
    () => data?.pages?.flatMap((page) => page.unpublished_products) ?? [],
    [JSON.stringify(data)]
  );

  const queryClient = useQueryClient();
  const totalRowsFromCache =
    queryClient.getQueryData<InfiniteData<any>>(
      fetchUnpublishedProductQueryKey(id, {})
    )?.pages?.[0]?.meta?.total ?? 0;

  const reactTable = useReactTable({
    data: productData,
    columns,
    initialState: {
      columnVisibility: { unmapped_product_id: true },
    },
    state: {
      columnFilters: columnFiltersParent,
      sorting,
      rowSelection: selectedProducts,
    },
    onColumnFiltersChange: setColumnFiltersParent,
    onSortingChange: setSorting,
    onRowSelectionChange: setSelectedProducts,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getRowId: (row) => row.unmapped_product_id.toString(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
  });

  // Initialize infinite scroll observer
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        const lastProduct = entries[0];

        if (lastProduct.isIntersecting) {
          fetchNextPage();
          observer.unobserve(lastProduct.target);
        }
      },
      { rootMargin: '300px' }
    );
  }, [fetchNextPage]);

  // When searchData changes re-observe last product
  useEffect(() => {
    if (loadNextRef.current) {
      observer.observe(loadNextRef.current);
    }
    return () => observer.disconnect();
  }, [observer, productData]);

  useEffect(() => {
    if (!bulkEditModalOpen) {
      setColumnFiltersParent([]);
    }
  }, [id, bulkEditModalOpen]);

  // persist filtering when bulk edit modal is opened
  useEffect(() => {
    if (isBulkEditTable) {
      setColumnFiltersParent([
        ...columnFiltersParent,
        { id: 'status', value: ['unpublished'] },
      ]);
    }
  }, [isBulkEditTable]);

  const handleRowClick = (
    row: any,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (
      row.status === 'publishing' ||
      isBulkEditTable ||
      !userCanEditUnpublishedProducts
    ) {
      e.preventDefault();
      return;
    }

    openUnpublishedModal(row);
  };

  useEffect(() => {
    if (isPublishing) {
      toast.add({ label: 'Publishing...', variant: 'success' });
    }
    if (isUpdateSuccess) {
      toast.add({
        label: 'Product successfully published!',
        variant: 'success',
      });
    }
    if (isUpdateError) {
      toast.add({ label: 'Product could not be published', variant: 'error' });
    }
  }, [isPublishing, isUpdateSuccess, isUpdateError]);

  useEffect(() => {
    const formattedProducts = reactTable
      .getSelectedRowModel()
      .rows.map((row) => ({
        name: row.original.pos_data.name,
        kind: row.original.pos_data.kind,
        unmapped_product_id: row.original.unmapped_product_id,
        category: row.original.pos_data.category,
        brand: row.original.pos_data.brand,
        percent_cbd: row.original.pos_data.lab_results.percent_cbd,
        percent_thc: row.original.pos_data.lab_results.percent_thc,
        cbd_dosage_milligrams: 0,
        thc_dosage_milligrams: 0,
        description: row.original.pos_data.description,
        image_urls: row.original.pos_data.image_urls,
      }));

    setFullSelectedProducts({ products: formattedProducts });
  }, [selectedProducts]);

  return isFetched && totalRowsFromCache === 0 ? (
    <EmptyState variant="unpublished" />
  ) : (
    <Form.BaseForm formMethods={formMethods} onSubmit={() => null} name="test">
      <BaseProductTable
        allIds={allRows}
        table={reactTable}
        productData={productData}
        fetchNextPage={fetchNextPage}
        isBulkEditTable={isBulkEditTable}
        isFetched={isFetched}
        isFetching={isFetching && !isFetchingNextPage}
        isSuccess={isSuccess}
        handleRowClick={handleRowClick}
        hasNextPage={hasNextPage || false}
        tableType="unpublished"
      />
    </Form.BaseForm>
  );
};
