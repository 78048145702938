import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { MenuProductForProductsTable } from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  SettingNames,
  VALIDATION_OPTIONS,
  normalizePath,
  track,
} from '@jane/business-admin/util';
import { ImageEditor } from '@jane/shared-b2b/components';
import { validateImageFile } from '@jane/shared-b2b/util';
import {
  Banner,
  Card,
  Flex,
  Grid,
  Image,
  InfoIcon,
  Link,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';
import { ImagePreview, defaultProductPhotoUrl } from '@jane/shared/util';

import { BannerText, ErrorBanner } from '../../../../shared/images/ErrorBanner';

export const BANNER_TEXT = [
  'PNG or JPEG file up to 10MB\n',
  'By uploading photos, you represent that you own or otherwise control the ',
  'copyrights to the photos',
].join('');

const LoadingImages = () => {
  return (
    <>
      {Array(3)
        .fill(null)
        .map((_, index) => {
          return (
            <Skeleton animate width="25%" key={`loading_${index}`}>
              <Skeleton.Bone
                height={'214px'}
                my={16}
                width="100%"
                borderRadius="xs"
              />
            </Skeleton>
          );
        })}
    </>
  );
};

type DefaultProductImageData = {
  imageUrls: string[];
};

interface ImagesProps {
  isLoading: boolean;
  menuProduct?: MenuProductForProductsTable | null;
}

export const ImagesCard = ({ isLoading, menuProduct }: ImagesProps) => {
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();

  const [uploadCount, setUploadCount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [enableCustomPhotos, setEnableCustomPhotos] = useState<boolean>(
    !!menuProduct?.photos?.length
  );
  const debouncedTrack = useDebouncedTrack(1000);
  const { watch } = useFormContext();

  const formImagesRadioField = watch('images_radio_field');
  const isUsingCustomPhotos = formImagesRadioField === 'custom';

  const stockPhoto = defaultProductPhotoUrl({
    category: menuProduct?.lineage,
    kind: menuProduct?.category || 'flower',
    productRootSubtype: menuProduct?.subcategory,
    productType: menuProduct?.type,
  });

  // Our primary backup are menu_product.product.image_urls which are assigned to default_product_image_urls
  // If those don't exist, our failsafe is constructing an image url for the product based on the product's kindy, category, type, and root_subtyp
  const defaultImageValuesFromProduct = (): DefaultProductImageData => {
    if (!menuProduct) return { imageUrls: [stockPhoto] };
    return {
      imageUrls: (menuProduct.default_product_image_urls.length &&
        menuProduct.default_product_image_urls.map(
          (image) => image.urls.original
        )) || [stockPhoto],
    };
  };

  const defaultImageValues = defaultImageValuesFromProduct();

  const makeOnFilesAdded = (onChange: (newState: string[]) => void) => {
    return async (fileList: FileList | null) => {
      setErrorMessages([]);

      if (fileList) {
        setUploadCount(fileList.length);

        Array.from(fileList).forEach(async (file) => {
          // add VALIDATION_OPTIONS as second param if you want any image validations run
          const error = await validateImageFile(file, VALIDATION_OPTIONS);
          if (!error) {
            try {
              const image = await ImagePreview.parse(file);

              setUploadCount((count) => count - 1);
              const formPhotos = watch('photos');

              onChange([...formPhotos, image.url]);
              track({
                event: EventNames.EditedPhoto,
                action: 'upload',
                file_size: `${file.size} bytes`,
                file_type: file.type,
                settings_name: 'product images',
                url: normalizePath(pathname, id),
              });
            } catch (error) {
              setUploadCount((count) => count - 1);

              setErrorMessages((oldState) => [
                ...oldState,
                `${file.name} - Encountered an error while uploading, please try again.`,
              ]);
            }
          } else {
            // update error messages
            const { filename, errors } = error;
            const messages = errors.map((e) => `${filename} - ${e.message}`);

            setErrorMessages((oldState) => [...oldState, ...messages]);
            setUploadCount((c) => c - 1);
          }
        });
      }
    };
  };

  const getProductImages = ({
    defaultValue,
    isUsingCustomPhotos,
    onChange,
    uploadCount,
    value,
  }: {
    defaultValue: any;
    isUsingCustomPhotos: boolean;
    onChange: () => void;
    uploadCount: number;
    value: any;
  }) => {
    return isUsingCustomPhotos ? (
      <ImageEditor
        images={value}
        onChange={onChange}
        isUploading={uploadCount > 0}
        onFilesAdded={makeOnFilesAdded(onChange)}
        showUploadGridItem={isUsingCustomPhotos}
      />
    ) : (
      <Grid.Container direction="row" spacing={16}>
        {defaultValue.map((imageUrl: string) => (
          <Grid.Item xs={4}>
            <Image
              altText="product image"
              border
              borderRadius="rounded"
              src={imageUrl}
            />
          </Grid.Item>
        ))}
      </Grid.Container>
    );
  };

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex flexDirection="column" p={24} gap={16}>
          <Flex justifyContent="space-between">
            <Typography variant="header-bold" mb={40}>
              Images
            </Typography>
            <RevertImages
              productImageURLs={defaultImageValues.imageUrls}
              isUsingCustomPhotos={isUsingCustomPhotos}
            />
          </Flex>
          <Form.RadioFieldGroup
            row
            defaultChecked={enableCustomPhotos ? 'custom' : 'jane-catalog'}
            name="images_radio_field"
            onChange={(val) => {
              setEnableCustomPhotos(!!val);
              debouncedTrack({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.EditProduct,
                revert: !val || val === enableCustomPhotos,
                setting_name: SettingNames.ProductImages,
                action: val as string,
              });
            }}
            options={[
              {
                id: 'jane-catalog',
                // TODO: Revisit to set Jane Catalog vs Stock Photo
                label: 'Jane Catalog',
                value: 'jane-catalog',
              },
              {
                id: 'custom',
                label: 'Custom',
                value: 'custom',
              },
            ]}
          />
          {errorMessages.length > 0 ? (
            <ErrorBanner
              closeBanner={() => setErrorMessages([])}
              messages={errorMessages}
            />
          ) : null}
          {uploadCount ? (
            <Banner
              icon={<InfoIcon />}
              typography="body"
              variant="info"
              label={`Uploading ${uploadCount} files...`}
              full
            />
          ) : null}
          <Controller
            defaultValue={defaultImageValues.imageUrls}
            name="photos"
            render={({ field: { onChange, value } }) => {
              return isLoading ? (
                <Flex justifyContent={'space-around'} width={'calc(100% + 3%)'}>
                  <LoadingImages />
                </Flex>
              ) : (
                getProductImages({
                  isUsingCustomPhotos,
                  onChange,
                  value,
                  uploadCount,
                  defaultValue: defaultImageValues.imageUrls,
                })
              );
            }}
          />
          {isUsingCustomPhotos && (
            <Banner
              icon={<InfoIcon />}
              typography="body"
              variant="info"
              label={<BannerText>{BANNER_TEXT}</BannerText>}
              full
            />
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};

const RevertImages = ({
  productImageURLs,
  isUsingCustomPhotos,
}: {
  isUsingCustomPhotos: boolean;
  productImageURLs?: string[];
}) => {
  const { setValue, watch } = useFormContext();

  if (
    isUsingCustomPhotos &&
    productImageURLs &&
    JSON.stringify(watch('photos')) !== JSON.stringify(productImageURLs)
  ) {
    return (
      <Link
        onClick={() => {
          setValue('images_radio_field', 'jane-catalog', {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
      >
        Revert images
      </Link>
    );
  }
  return null;
};
