import { useContext, useEffect, useMemo } from 'react';

import { FulfillmentSettingsContext } from '@jane/business-admin/providers';
import {
  GEOFENCE_DISCLAIMER_TEXT,
  StrategyOptions,
  getStoreDeliveryOptions,
} from '@jane/business-admin/util';
import type { DeliveryConfigStrategy } from '@jane/shared/models';
import { Banner, Flex, InfoIcon, Modal, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { GeofenceSettings } from './GeofenceSettings';
import { RadiusSettings } from './RadiusSettings';
import { ZipcodeSettings } from './ZipcodeSettings';

const deliveryOptions = [
  {
    label: 'Radius from store',
    id: 'isRadiusDelivery',
    value: StrategyOptions.isRadiusDelivery,
  },
  {
    label: 'Zipcodes',
    id: 'isZipcodeDelivery',
    value: StrategyOptions.isZipcodeDelivery,
  },
  {
    label: 'Geofence',
    id: 'isGeofenceDelivery',
    value: StrategyOptions.isGeofenceDelivery,
  },
];

export const DeliveryStrategySection = ({
  deliveryMinimum,
}: {
  deliveryMinimum: number;
}) => {
  const { deliveryStrategy, setDeliveryStrategy } = useContext(
    FulfillmentSettingsContext
  );

  const { watch } = useFormContext();
  const formDeliveryStrategy = watch('delivery_strategy');

  useEffect(() => {
    if (formDeliveryStrategy !== undefined) {
      setDeliveryStrategy(
        getStoreDeliveryOptions({
          delivery_strategy: formDeliveryStrategy,
        } as DeliveryConfigStrategy)
      );
    }
  }, [formDeliveryStrategy]);

  const defaultChecked = useMemo(() => {
    return Object.keys(deliveryStrategy).find(
      (i) => deliveryStrategy[i as keyof typeof deliveryStrategy] === true
    );
  }, [JSON.stringify(deliveryStrategy)]);

  return (
    <>
      <Form.RadioFieldGroup
        defaultChecked={defaultChecked}
        row
        name="delivery_strategy"
        options={deliveryOptions}
        legend={
          <Typography variant="body-bold">
            Accept deliveries based on
          </Typography>
        }
      />
      {(defaultChecked || formDeliveryStrategy) ===
        StrategyOptions.isRadiusDelivery && (
        <RadiusSettings minimum={deliveryMinimum} />
      )}

      {(defaultChecked || formDeliveryStrategy) ===
        StrategyOptions.isZipcodeDelivery && <ZipcodeSettings />}

      {(defaultChecked || formDeliveryStrategy) ===
        StrategyOptions.isGeofenceDelivery && (
        <>
          <Banner
            label={GEOFENCE_DISCLAIMER_TEXT}
            icon={
              <Flex alignItems="center" height="100%">
                <InfoIcon />
              </Flex>
            }
            variant={'info'}
            full
          />
          <GeofenceSettings />
        </>
      )}

      <Modal.ContentDivider />
    </>
  );
};
