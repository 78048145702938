import styled from '@emotion/styled';
import capitalize from 'lodash/capitalize';
import { useEffect, useMemo } from 'react';

import type { StoreSchedule } from '@jane/business-admin/types';
import { getTwentyFourHours } from '@jane/business-admin/util';
import { Flex } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import {
  storeTimeForEndDateTime,
  validateEndDateAndTime,
  validateStartDateAndTime,
} from './ScheduleTimeValidation';

const Hidable = styled.div<{ visible: boolean }>(({ visible }) => ({
  display: visible ? 'block' : 'none',
}));

const StyledSelectField = styled(Form.SelectField)<{ visible: boolean }>(
  ({ visible }) => ({
    visibility: visible ? 'visible' : 'hidden',
  })
);

export const DateAndTimePicker = ({
  disabled,
  storeRetailSchedule,
  timezone,
  variant,
}: {
  disabled: boolean;
  storeRetailSchedule: StoreSchedule | undefined | null;
  timezone: string | undefined;
  variant: 'start' | 'end';
}) => {
  const { clearErrors, watch, setValue } = useFormContext();
  const hourOptions = useMemo(() => {
    return getTwentyFourHours();
  }, []);

  const [endOnStoreClose, isEnabled] = watch([
    'use_store_close_time',
    `enabled_date_${variant}_checked`,
  ]);
  const endOnStoreCloseLabel =
    'On selected schedule End Date, special should end when the store closes\
     (rather than using the daily schedule).';

  const [startDate, endDate, startTime, endTime] = watch([
    'start_date',
    'end_date',
    'start_time',
    'end_time',
  ]);

  const validate = (val: any) => {
    if (isEnabled && !val) {
      return 'This field is required.';
    }

    return variant === 'start'
      ? validateStartDateAndTime({
          endDate,
          endOnStoreClose,
          endTime,
          startDate,
          startTime,
          timezone,
        })
      : validateEndDateAndTime({
          endDate,
          endOnStoreClose,
          endTime,
          startDate,
          startTime,
          timezone,
          storeRetailSchedule,
        });
  };

  useEffect(() => {
    if (endDate && endOnStoreClose) {
      const retailEndTime = storeTimeForEndDateTime(
        endDate,
        storeRetailSchedule,
        timezone
      );

      setValue('end_time', retailEndTime, { shouldDirty: false });
    }
  }, [endOnStoreClose, endDate]);

  return (
    <Hidable visible={isEnabled} aria-hidden={!isEnabled}>
      <Flex width="100%" gap={16} mt={24}>
        <Form.DateTimeField
          label={`${capitalize(variant)} date`}
          name={`${variant}_date`}
          width="50%"
          onChange={() => {
            clearErrors();
          }}
          validate={validate}
          shouldUnregister={false}
          disabled={disabled}
        />
        <StyledSelectField
          label={`${capitalize(variant)} time`}
          name={`${variant}_time`}
          options={hourOptions}
          width="50%"
          onChange={() => {
            clearErrors();
          }}
          validate={validate}
          required={isEnabled && !endOnStoreClose}
          shouldUnregister={false}
          visible={variant === 'start' || !endOnStoreClose}
          disabled={disabled}
        />
      </Flex>
      {variant === 'end' ? (
        <Form.CheckboxField
          label={endOnStoreCloseLabel}
          name="use_store_close_time"
          mt={16}
          disabled={disabled}
        />
      ) : null}
    </Hidable>
  );
};
