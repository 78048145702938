import { useContext, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import {
  BODY_LINE_HEIGHT,
  MODAL_CARD_WIDTH,
  VALIDATION_OPTIONS,
} from '@jane/business-admin/util';
import { ImageEditor } from '@jane/shared-b2b/components';
import { validateImageFile } from '@jane/shared-b2b/util';
import {
  Banner,
  Card,
  Flex,
  InfoIcon,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';
import { ImagePreview } from '@jane/shared/util';

import { BannerText, ErrorBanner } from '../../images/ErrorBanner';

export const BANNER_IMAGE_SIZE = 'One image allowed. Image cannot exceed 10MB.';
export const BANNER_IMAGE_DUPLICATE =
  'Images cannot be copied when duplicating a Special.';

interface Props {
  isConnectedToGlobal?: boolean;
  isLoading: boolean;
}

const LoadingImages = () => {
  return (
    <Skeleton animate width="25%" key={`loading_special_image`}>
      <Skeleton.Bone height={'214px'} my={16} width="100%" borderRadius="xs" />
    </Skeleton>
  );
};

export const ImagesCard = ({
  isConnectedToGlobal = false,
  isLoading,
}: Props) => {
  const { isReadOnly } = useContext(SpecialsModalContext);
  const { isDisabled } = useContext(GlobalSpecialsModalContext);

  const [uploadCount, setUploadCount] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { setError, trigger } = useFormContext();

  // DEV NOTE(adam): imagesRef is used when duplicating specials
  const imagesRef = useRef<string[]>([]);

  const { pathname } = useLocation();
  const pathIncludesDuplicate = pathname.includes('duplicate');

  const makeOnFilesAdded = (onChange: (newState: string[]) => void) => {
    return async (fileList: FileList | null) => {
      setErrorMessages([]);

      if (fileList) {
        setUploadCount(fileList.length);

        // if multiple photos are selected, upload the first one
        const file = Array.from(fileList)[0];

        // add VALIDATION_OPTIONS as second param if you want any image validations run
        const error = await validateImageFile(file, VALIDATION_OPTIONS);
        if (!error) {
          try {
            const image = await ImagePreview.parse(file);

            setUploadCount((count: number) => count - 1);
            imagesRef.current = [image.url];
            // ImageEditor expects an array of url strings
            onChange([image.url]);
          } catch (error) {
            setError('special-image-upload-error', {
              message: 'Error uploading special image',
            });
            setUploadCount((count: number) => count - 1);

            setErrorMessages((oldState: string[]) => [
              ...oldState,
              `${file.name} - Encountered an error while uploading, please try again.`,
            ]);
            // manually force form validation since this isn't a <Form> component
            trigger('photo');
          }
        } else {
          setError('special-image-upload-error', {
            message: 'Error uploading special image',
          });
          // update error messages
          const { filename, errors } = error;
          const messages = errors.map((e) => `${filename} - ${e.message}`);

          setErrorMessages((oldState: string[]) => [...oldState, ...messages]);
          setUploadCount((count: number) => count - 1);

          trigger('photo');
        }
      }
    };
  };

  const handleOnChange = ({ images, onChange }: any) => {
    imagesRef.current = images;
    onChange(images);
  };

  const getSpecialImages = ({ value, onChange }: any) => {
    return (
      <ImageEditor
        images={pathIncludesDuplicate ? imagesRef.current : value}
        onChange={(images) => handleOnChange({ images, onChange })}
        isUploading={!!errorMessages.length && uploadCount > 1}
        onFilesAdded={makeOnFilesAdded(onChange)}
        showUploadGridItem={
          isReadOnly || isDisabled
            ? false
            : pathIncludesDuplicate
            ? !imagesRef.current.length
            : !value.length
        }
      />
    );
  };
  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <>
            <Flex data-testid="special-image-header-text">
              <Typography variant="header-bold" mb={40}>
                Image
              </Typography>
              <Typography ml={4} variant="header" color="grays-mid">
                (Optional)
              </Typography>
            </Flex>
            {isLoading ? (
              <Skeleton animate>
                <Skeleton.Bone width="30%" height={BODY_LINE_HEIGHT} />
              </Skeleton>
            ) : (
              <>
                {errorMessages.length > 0 ? (
                  <Flex mb={24}>
                    <ErrorBanner
                      closeBanner={() => setErrorMessages([])}
                      messages={errorMessages}
                    />
                  </Flex>
                ) : null}
                <Controller
                  defaultValue={[]}
                  name="photo"
                  render={({ field: { onChange, value } }) => {
                    return isLoading ? (
                      <Flex
                        justifyContent={'space-around'}
                        width={'calc(100% + 3%)'}
                      >
                        <LoadingImages />
                      </Flex>
                    ) : (
                      getSpecialImages({
                        onChange,
                        value,
                      })
                    );
                  }}
                />
                <Flex borderRadius="sm" mt={24} p={8} flexDirection="column">
                  <Banner
                    icon={<InfoIcon />}
                    typography="body"
                    variant="info"
                    label={
                      <BannerText>
                        {isConnectedToGlobal && isReadOnly
                          ? 'Edit image in global special'
                          : BANNER_IMAGE_SIZE}
                      </BannerText>
                    }
                    full
                  />
                  {pathIncludesDuplicate && (
                    <Banner
                      mt={12}
                      icon={<InfoIcon />}
                      typography="body"
                      variant="info"
                      label={<BannerText>{BANNER_IMAGE_DUPLICATE}</BannerText>}
                      full
                    />
                  )}
                </Flex>
              </>
            )}
          </>
        </Flex>
      </Card.Content>
    </Card>
  );
};
