import styled from '@emotion/styled';
import startCase from 'lodash/startCase';
import { useEffect, useMemo } from 'react';

import { DEFAULT_STATUS_MESSAGES } from '@jane/business-admin/util';
import type { StatusMessage } from '@jane/shared/models';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { StatusMessagePreview } from '../StatusMessagePreview';
import type { NotificationSettingsForm } from './form';
import { MAX_MESSAGE_LENGTH, STATUS_MESSAGES_FIELD } from './form';

const useFieldNames = (index: number) =>
  useMemo(
    () => ({
      enabled: `${STATUS_MESSAGES_FIELD}.${index}.enabled` as const,
      use_default: `${STATUS_MESSAGES_FIELD}.${index}.use_default` as const,
      message_text: `${STATUS_MESSAGES_FIELD}.${index}.message_text` as const,
    }),
    [index]
  );

const Fieldset = styled(Flex)<{ enabled?: boolean }>(({ enabled }) =>
  enabled
    ? undefined
    : {
        opacity: 0.3,
        pointerEvents: 'none',
        userSelect: 'none',
      }
);

interface Props {
  index: number;
  message: Omit<StatusMessage, 'store_id' | 'pickup' | 'delivery' | 'kiosk'>;
}

export const StatusMessageFormFields = ({ message, index }: Props) => {
  const { watch, setValue } = useFormContext<NotificationSettingsForm>();
  const fields = useFieldNames(index);
  const enabled = watch(fields.enabled);
  const useDefault = watch(fields.use_default);
  const messageText = watch(fields.message_text);

  useEffect(() => {
    if (useDefault) {
      // Explicitly give the message_text field a null value to prevent it from being
      // omitted in the PATCH request payload and triggering validation errors.
      setValue(fields.message_text, null);
    } else if (message.message_text) {
      // Explicitly set the message_text field back to whatever it was when toggling from
      // default to custom, if it had a value before:
      setValue(fields.message_text, message.message_text);
    }
  }, [useDefault]);

  const messageLength = useMemo(
    () => (messageText?.length || message.message_text?.length) ?? 0,
    [messageText, message.message_text]
  );

  const useDefaultFieldOptions = [
    {
      label: 'Jane default',
      id: `${fields.use_default}-true`,
      value: true,
    },
    {
      label: 'Custom',
      id: `${fields.use_default}-false`,
      value: false,
    },
  ];

  return (
    <Flex data-testid="status-message-fields">
      <Flex width="40%">
        <Form.SwitchField
          label={startCase(message.cart_status)}
          name={fields.enabled}
          defaultChecked={message.enabled}
        />
      </Flex>

      <Fieldset flexDirection="column" width="60%" enabled={enabled}>
        <Typography color="grays-mid" mb={24}>
          Message{' '}
          {!useDefault && (
            <>
              ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
            </>
          )}
        </Typography>

        <Flex>
          <Form.RadioFieldGroup
            defaultChecked={
              fields.use_default
                ? useDefaultFieldOptions[0].id
                : useDefaultFieldOptions[1].id
            }
            name={fields.use_default}
            options={useDefaultFieldOptions}
            row={true}
          />
        </Flex>

        {useDefault ? (
          <StatusMessagePreview
            status={message.cart_status}
            message={DEFAULT_STATUS_MESSAGES[message.cart_status]}
          />
        ) : (
          <Form.TextAreaField
            label="Message text"
            labelHidden
            name={fields.message_text}
            width="100%"
            defaultValue={message.message_text ?? undefined}
            resize={false}
            required
            rows={3}
            maxLength={MAX_MESSAGE_LENGTH}
          />
        )}
      </Fieldset>
    </Flex>
  );
};
