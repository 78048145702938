import { TipVariant } from '@jane/business-admin/util';
import { Form } from '@jane/shared/reefer-hook-form';

interface Props
  extends Omit<React.ComponentProps<typeof Form.NumberField>, 'label'> {
  onChange?: (value: number) => void;
  variant?: TipVariant;
}

export const TippingAmountFormField = ({
  name,
  defaultValue,
  onChange,
  variant = TipVariant.Dollar,
  ...numberFieldProps
}: Props) => {
  const usePercentage = variant === TipVariant.Percentage;

  return (
    <Form.NumberField
      {...numberFieldProps}
      width="100%"
      label={`Pre-defined ${usePercentage ? 'percentage' : 'dollar amount'}`}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      startUnit={usePercentage ? undefined : '$'}
      endUnit={usePercentage ? '%' : undefined}
      min={1}
      max={100}
      placeholder={'1'}
    />
  );
};
