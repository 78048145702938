import styled from '@emotion/styled';
import { useContext } from 'react';

import { StoreDetailsContext } from '@jane/business-admin/providers';
import { Flex, Typography } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { useFormContext } from '@jane/shared/reefer-hook-form';
import { formatWeight } from '@jane/shared/util';

import type { CartLimitRule } from '../../mockData';

const PreviewWrapper = styled(Flex)(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.colors.brand.ember.main,
  ...spacing({ mx: -40, px: 40, py: 24 }),
}));

export const RulePreview = () => {
  const { watch } = useFormContext<CartLimitRule>();
  const name = watch('product_group_name');
  const value = watch('limit_value');
  const unit = watch('limit_unit');
  const { storeName } = useContext(StoreDetailsContext);

  return (
    <PreviewWrapper>
      <Typography variant="body-bold" color="grays-white" mb={8}>
        Cart limit exceeded
      </Typography>
      {value !== undefined ? (
        <Typography variant="body-bold" color="grays-white">
          {`Your bag has ${
            unit === 'mg' ? 'a total dosage of' : ''
          } ${formatWeight(
            Number(value + 1)
          )}${unit} of ${name}  products, but ${storeName} only allows ${formatWeight(
            Number(value)
          )}${unit} per order.`}
        </Typography>
      ) : null}
    </PreviewWrapper>
  );
};
