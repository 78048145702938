import capitalize from 'lodash/capitalize';
import { useCallback, useMemo } from 'react';

import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { CustomLineageLabelsV2 } from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  SettingNames,
} from '@jane/business-admin/util';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

interface LineageFieldProps {
  customLabels: CustomLineageLabelsV2;
  isUnpublished?: boolean;
  lineageAttribute: any;
  tableType: string;
}
export const LineageField = ({
  customLabels,
  isUnpublished,
  lineageAttribute,
  tableType,
}: LineageFieldProps) => {
  const { watch, clearErrors } = useFormContext();
  const debouncedTrack = useDebouncedTrack(1000);

  const lineageWatch = watch('lineage');
  const categoryWatch = watch('category');

  const requireLineage = useMemo(
    () => categoryWatch === 'flower',
    [categoryWatch]
  );

  const showDefaultLineage =
    isUnpublished && lineageAttribute && lineageAttribute !== lineageWatch;

  // Format lineage options for select
  const lineageOptions = (custom_lineage_labels: CustomLineageLabelsV2) => {
    const {
      indica = 'Indica',
      sativa = 'Sativa',
      hybrid = 'Hybrid',
      cbd = 'CBD',
    } = custom_lineage_labels;
    const baseLineageOptions = [
      {
        value: 'cbd',
        label: cbd,
      },
      {
        value: 'indica',
        label: indica,
      },
      {
        value: 'sativa',
        label: sativa,
      },
      {
        value: 'hybrid',
        label: hybrid,
      },
    ];

    return baseLineageOptions;
  };

  const lineageValidation = useCallback(
    (value: string | null) => {
      return !requireLineage ||
        categoryWatch === 'gear' ||
        categoryWatch === 'merch' ||
        (value !== 'none' && value !== '' && value !== null)
        ? true
        : 'Must choose a valid lineage';
    },
    [categoryWatch]
  );

  if (
    !isUnpublished &&
    (categoryWatch === 'gear' || categoryWatch === 'merch')
  ) {
    return null;
  }

  return (
    <Flex flexDirection="column">
      <Form.SelectField
        defaultValue={
          lineageAttribute || categoryWatch === 'flower' ? 'cbd' : 'none'
        }
        label="Lineage"
        name="lineage"
        options={lineageOptions(customLabels)}
        width={'100%'}
        validate={(v: any) => {
          clearErrors();
          return lineageValidation(v);
        }}
        onChange={(value) => {
          debouncedTrack({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.EditProduct,
            revert: !value || value === lineageAttribute,
            setting_name:
              SettingNames[
                `${capitalize(tableType)}Lineage` as keyof typeof SettingNames
              ],
          });
        }}
      />
      {showDefaultLineage && (
        <Typography mt={12} color="grays-mid">
          {`POS Default: ${lineageAttribute}`}
        </Typography>
      )}
    </Flex>
  );
};
