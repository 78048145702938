import type { StoreDismissalMessage } from '@jane/business-admin/types';
import { Button, Flex, TrashIcon, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { DISMISSAL_MESSAGES_FIELD, MAX_MESSAGE_LENGTH } from './form';
import type { NotificationSettingsForm } from './form';

interface Props {
  index: number;
  message: StoreDismissalMessage;
  onDelete: (message: StoreDismissalMessage, index: number) => void;
}

export const DismissalMessageFormFields = ({
  message: providedMessage,
  index,
  onDelete,
}: Props) => {
  const field = `${DISMISSAL_MESSAGES_FIELD}.${index}.message` as const;
  const { watch } = useFormContext<NotificationSettingsForm>();
  const messageText = watch(field);
  const messageLength =
    (messageText?.length || providedMessage?.message?.length) ?? 0;

  return (
    <Flex data-testid="dismissal-message-fields">
      <Flex width="30%">Additional dismissal</Flex>
      <Flex width="70%">
        <Flex flexDirection="column" width="80%" grow={1}>
          <Form.TextAreaField
            label="Message"
            labelHidden
            name={`${DISMISSAL_MESSAGES_FIELD}.${index}.message`}
            width="100%"
            defaultValue={providedMessage.message ?? undefined}
            resize={false}
            required
            rows={3}
            maxLength={MAX_MESSAGE_LENGTH}
          />
          <Typography color="grays-mid" mb={24}>
            ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
          </Typography>
        </Flex>
        <Flex ml={16}>
          <Button.Icon
            icon={<TrashIcon />}
            label="Delete message"
            onClick={() => onDelete(providedMessage, index)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
