import isString from 'lodash/isString';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';

interface CategorySelectInputModalProviderProps {
  children: ReactNode;
  onDirty?: (dirtyState: boolean) => void;
  rules?: any;
}

interface CategorySelectInputModal {
  expandAll: boolean;
  hideSubItems: Record<string, boolean>;
  indeterminates: Set<string>;
  onDirty?: (dirtyState: boolean) => void;
  selectionMap: Map<string, boolean>;
  setExpandAll: React.Dispatch<React.SetStateAction<boolean>>;
  setHideSubItems: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setIndeterminates: (indeterminateList: Set<string>) => void;
  setSelectionMap: (newSelectionMap: Map<string, boolean>) => void;
}

export const CategorySelectInputModalContext =
  React.createContext<CategorySelectInputModal>({
    indeterminates: new Set(),
    hideSubItems: {},
    onDirty: noop,
    selectionMap: new Map(),
    setHideSubItems: noop,
    setIndeterminates: noop,
    setSelectionMap: noop,
    expandAll: false,
    setExpandAll: noop,
  });

export const getDefaultSelectionMap = (rules = []) => {
  rules = rules || [];

  const defaultSelectionMap = new Map();

  rules.forEach((rule) => {
    const kind = rule['kind'] || '';
    const root_subtype = rule['root_subtype'] ? `/${rule['root_subtype']}` : '';
    const brand_subtype = rule['brand_subtype']
      ? `/${rule['brand_subtype']}`
      : '';
    defaultSelectionMap.set(`${kind}${root_subtype}${brand_subtype}`, true);
  });

  return defaultSelectionMap;
};

const defaultSelectionMap = (rules: any) =>
  getDefaultSelectionMap(isString(rules) ? [] : rules);

export const CategorySelectInputModalProvider = ({
  children,
  onDirty,
  rules,
}: CategorySelectInputModalProviderProps) => {
  const [expandAll, setExpandAll] = useState(false);
  const [hideSubItems, setHideSubItems] = useState<Record<string, boolean>>({});
  const [indeterminates, setIndeterminates] = useState(new Set<string>());
  const [selectionMap, setSelectionMap] = useState(defaultSelectionMap(rules));

  const contextValue = useMemo(
    () => ({
      expandAll,
      hideSubItems,
      indeterminates,
      onDirty,
      selectionMap,
      setExpandAll,
      setHideSubItems,
      setIndeterminates,
      setSelectionMap,
    }),
    [
      expandAll,
      hideSubItems,
      indeterminates,
      onDirty,
      selectionMap,
      setExpandAll,
      setHideSubItems,
      setIndeterminates,
      setSelectionMap,
    ]
  );

  return (
    <CategorySelectInputModalContext.Provider value={contextValue}>
      {children}
    </CategorySelectInputModalContext.Provider>
  );
};
