import { useCallback, useEffect, useState } from 'react';

import { useSaveStoreCheckoutOptions } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import type { DeleteObject, StoreV2 } from '@jane/business-admin/types';
import {
  DEFAULT_TIP_AMOUNTS,
  EventNames,
  ModalNames,
  TipEnabledFor,
  TipVariant,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import type {
  DocumentationSetting,
  MedicalStoreDocumentationRequirement,
  TipSetting,
} from '@jane/shared/models';
import { Flex, Modal, Typography, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { TippingAmountFormField } from './TippingAmountFormField';

const FORM_ERROR_NAME = 'checkout-options-error';
interface FormData {
  birth_date_required?: boolean;
  customer_paperwork?: string;

  delivery_id_required?: boolean;
  id_required?: boolean;
  medical_required?: boolean;
  pickup_id_required?: boolean;
  require_medical_number?: boolean;
  require_medical_photo?: boolean;

  show_paperwork?: boolean;

  tip_amount_option_1?: number;
  tip_amount_option_2?: number;
  tip_amount_option_3?: number;
  tipping_enabled?: boolean;
  tipping_enabled_for?: 'delivery' | 'pickup';
  tipping_variant?: TipVariant;
}

export interface CheckoutOptionsModalProps {
  id_upload_prohibited?: boolean;
  medicalDocumentationSettings?: MedicalStoreDocumentationRequirement | null;
  onClose: () => void;
  open: boolean;
  store?: StoreV2;
  tipSettings?: TipSetting & DeleteObject;
  tippingAllowed?: boolean;
}

const tippingOptions = [
  { label: 'Percentage Amount', id: 'percentage', value: 'percentage' },
  { label: 'Dollar Amount', id: 'dollar', value: 'dollar' },
];

export const CheckoutOptionsModal = ({
  open,
  onClose,
  store,
  tipSettings,
  medicalDocumentationSettings,
  id_upload_prohibited,
  tippingAllowed = true,
}: CheckoutOptionsModalProps) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating checkout options settings. Please try again.'
  );
  const formMethods = useForm();
  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const toast = useToast();
  const [formData, setFormData] = useState<FormData>();

  const { mutateAsync: saveStoreCheckoutOptions, isSuccess: saveSuccess } =
    useSaveStoreCheckoutOptions(store?.id ?? 0);

  useEffect(() => {
    if (open) {
      if (!store) return;
      setFormData({
        id_required: store.id_required,
        delivery_id_required: store.delivery_id_required,
        pickup_id_required: store.pickup_id_required,
        birth_date_required: store.birth_date_required,

        medical_required:
          medicalDocumentationSettings?.require_medical_id !== 'none',
        require_medical_photo:
          medicalDocumentationSettings?.require_medical_id === 'photo' ||
          medicalDocumentationSettings?.require_medical_id ===
            'photo_and_number',
        require_medical_number:
          medicalDocumentationSettings?.require_medical_id === 'number' ||
          medicalDocumentationSettings?.require_medical_id ===
            'photo_and_number',

        customer_paperwork: store.customer_paperwork ?? undefined,
        show_paperwork: store.show_paperwork,

        tipping_enabled: !!tipSettings,
        tipping_enabled_for: tipSettings?.enabled_for || TipEnabledFor.Delivery,
        tipping_variant:
          (tipSettings?.variant as TipVariant) || TipVariant.Dollar,
        tip_amount_option_1:
          tipSettings?.custom_amounts[0] ||
          DEFAULT_TIP_AMOUNTS[TipVariant.Dollar][0],
        tip_amount_option_2:
          tipSettings?.custom_amounts[1] ||
          DEFAULT_TIP_AMOUNTS[TipVariant.Dollar][1],
        tip_amount_option_3:
          tipSettings?.custom_amounts[2] ||
          DEFAULT_TIP_AMOUNTS[TipVariant.Dollar][2],
      });
    }
  }, [medicalDocumentationSettings, open, store, tipSettings]);

  useEffect(() => {
    if (saveSuccess) {
      toast.add({
        label: 'Checkout options updated',
        variant: 'success',
      });
      onClose();
    }
  }, [saveSuccess]);

  const onSubmit = async (data: Required<FormData>) => {
    let require_medical_id: DocumentationSetting = 'none';
    if (formData?.require_medical_number) require_medical_id = 'number';
    if (formData?.require_medical_photo) require_medical_id = 'photo';
    if (formData?.require_medical_number && formData?.require_medical_photo)
      require_medical_id = 'photo_and_number';

    const updateOrDestroyTipSettings = () => {
      if (formData?.tipping_enabled) {
        // if tipping enabled, update settings
        return [
          {
            id: tipSettings?.id,
            variant: formData.tipping_variant,
            enabled_for: formData.tipping_enabled_for,
            custom_amounts: [
              formData.tip_amount_option_1,
              formData.tip_amount_option_2,
              formData.tip_amount_option_3,
            ],
          },
        ];
      } else if (tipSettings?.id) {
        // if settings exist but are being disabled, tell the backend to delete the record
        return [
          {
            id: tipSettings.id,
            _destroy: true,
          },
        ];
      }

      // otherwise just return an empty array to avoid persisting anything
      return [];
    };

    const requestData = {
      store: {
        id_required: !!formData?.id_required,
        delivery_id_required: !!formData?.delivery_id_required,
        pickup_id_required: !!formData?.pickup_id_required,
        birth_date_required: formData?.birth_date_required,
        customer_paperwork: formData?.customer_paperwork,
        show_paperwork: !!formData?.show_paperwork,
      },
      medical_store_documentation_requirement: {
        id: medicalDocumentationSettings?.id,
        require_medical_id,
      },
      tip_settings: updateOrDestroyTipSettings(),
    };

    const submitMethod = () => {
      track({
        event: EventNames.EditedStoreSettings,
        modal_name: ModalNames.CheckoutOptions,
        changed_attributes: Object.keys(dirtyFields),
      });
      return saveStoreCheckoutOptions(requestData as any);
    };
    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: {
        medical_store_documentation_requirement?: Record<string, unknown>;
        store?: Record<string, unknown>;
        tip_settings?: Record<string, unknown>;
      }) => {
        const storeErrors = parseValidationErrors(
          validationErrors?.store || {}
        );
        const medicalErrors = parseValidationErrors(
          validationErrors?.medical_store_documentation_requirement || {}
        );
        const tipErrors = parseValidationErrors(
          validationErrors?.tip_settings || {}
        );
        throw new FormValidationError(FORM_ERROR_NAME, [
          ...storeErrors,
          ...medicalErrors,
          ...tipErrors,
        ]);
      },
    });
  };

  const setDataOnChange = useCallback(
    (field: string) => (value: string | boolean | number) => {
      setFormData((formData) => ({
        ...formData,
        [field]: value,
      }));
    },
    []
  );

  const requireGovernmentChecked =
    formData?.delivery_id_required && formData?.pickup_id_required;

  const requireGovernmentIndeterminante =
    !requireGovernmentChecked &&
    [formData?.delivery_id_required || formData?.pickup_id_required].some(
      Boolean
    );

  const requireMedicalChecked =
    formData?.require_medical_photo && formData?.require_medical_number;

  const requireMedicalIndeterminate =
    !requireMedicalChecked &&
    [formData?.require_medical_photo, formData?.require_medical_number].some(
      Boolean
    );

  const setRequireGovernment = useCallback(
    (checked: boolean) => {
      setDataOnChange('delivery_id_required')(checked);
      setDataOnChange('pickup_id_required')(checked);
    },
    [setDataOnChange]
  );

  const setRequireMedical = useCallback(
    (checked: boolean) => {
      setDataOnChange('require_medical_photo')(checked);
      setDataOnChange('require_medical_number')(checked);
    },
    [setDataOnChange]
  );

  const handleTipVariantChange = useCallback(
    (variant: TipVariant) => {
      setDataOnChange('tipping_variant')(variant);
      setDataOnChange('tip_amount_option_1')(DEFAULT_TIP_AMOUNTS[variant][0]);
      setDataOnChange('tip_amount_option_2')(DEFAULT_TIP_AMOUNTS[variant][1]);
      setDataOnChange('tip_amount_option_3')(DEFAULT_TIP_AMOUNTS[variant][2]);
    },
    [setDataOnChange]
  );

  return (
    <ConfirmWrapperWithTracking
      open={open}
      setOpen={onClose}
      hasChanges={isDirty}
      modalName={ModalNames.CheckoutOptions}
    >
      <Form.BaseForm
        name="checkout options"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Checkout Options"
          subtitle={store?.name}
          actions={<Form.SubmitButton label="Save" />}
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Typography variant="body-bold" mb={24}>
            Requirements
          </Typography>
          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={requireGovernmentChecked}
              indeterminate={requireGovernmentIndeterminante}
              label="Government ID"
              name="government_required"
              onChange={setRequireGovernment}
              mb={24}
            />
          )}

          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={formData?.pickup_id_required}
              label="Pickup/Curbside"
              name="pickup_id_required"
              onChange={setDataOnChange('pickup_id_required')}
              mb={24}
              ml={40}
            />
          )}

          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={formData?.delivery_id_required}
              label="Delivery"
              name="delivery_id_required"
              onChange={setDataOnChange('delivery_id_required')}
              mb={24}
              ml={40}
            />
          )}

          <Form.CheckboxField
            checked={formData?.birth_date_required}
            label="Birthdate"
            name="birth_date_required"
            onChange={setDataOnChange('birth_date_required')}
            mb={24}
          />

          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={requireMedicalChecked}
              indeterminate={requireMedicalIndeterminate}
              label="Medical Store documentation"
              name="medical_required"
              onChange={setRequireMedical}
              mb={24}
            />
          )}

          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={formData?.require_medical_photo}
              label="Photo"
              name="require_medical_photo"
              onChange={setDataOnChange('require_medical_photo')}
              mb={24}
              ml={40}
            />
          )}

          {!id_upload_prohibited && (
            <Form.CheckboxField
              checked={formData?.require_medical_number}
              label="Number"
              name="require_medical_number"
              onChange={setDataOnChange('require_medical_number')}
              mb={24}
              ml={40}
            />
          )}

          <Modal.ContentDivider />
          <Form.SwitchField
            key={formData?.show_paperwork?.toString()}
            label={
              <Typography variant="body-bold">Checkout Reminder</Typography>
            }
            name="show_paperwork"
            defaultChecked={store?.show_paperwork}
            onChange={setDataOnChange('show_paperwork')}
            mb={32}
          />

          {formData?.show_paperwork && (
            <Form.TextAreaField
              label="Reminder to customer"
              name="customer_paperwork"
              defaultValue={store?.customer_paperwork || ''}
              placeholder="Add an optional message that will be displayed on the checkout screen."
              onChange={setDataOnChange('customer_paperwork')}
              mt={32}
            />
          )}

          {tippingAllowed && (
            <>
              <Modal.ContentDivider />

              <Form.SwitchField
                key={formData?.tipping_enabled?.toString()}
                label={<Typography variant="body-bold">Tipping</Typography>}
                name="tipping_enabled"
                defaultChecked={!!tipSettings}
                onChange={setDataOnChange('tipping_enabled')}
                mb={32}
              />

              {formData?.tipping_enabled && (
                <>
                  <Form.RadioFieldGroup
                    options={tippingOptions}
                    name="tipping_variant"
                    defaultChecked={
                      formData?.tipping_variant
                        ? formData?.tipping_variant
                        : 'dollar'
                    }
                    onChange={handleTipVariantChange}
                    legend={
                      <Typography variant="body-bold">
                        Accepts tips in
                      </Typography>
                    }
                  />
                  <Flex
                    justifyContent="space-between"
                    my={24}
                    key={formData?.tipping_variant}
                  >
                    <TippingAmountFormField
                      name="tip_amount_option_1"
                      defaultValue={formData?.tip_amount_option_1}
                      onChange={setDataOnChange('tip_amount_option_1')}
                      variant={formData?.tipping_variant}
                      validate={() =>
                        formData?.tip_amount_option_1 ===
                          formData?.tip_amount_option_2 ||
                        formData?.tip_amount_option_1 ===
                          formData?.tip_amount_option_3
                          ? 'Please use a unique amount'
                          : true
                      }
                    />

                    <TippingAmountFormField
                      name="tip_amount_option_2"
                      defaultValue={formData?.tip_amount_option_2}
                      onChange={setDataOnChange('tip_amount_option_2')}
                      variant={formData?.tipping_variant}
                      mx={16}
                      validate={() =>
                        formData?.tip_amount_option_2 ===
                          formData?.tip_amount_option_1 ||
                        formData?.tip_amount_option_2 ===
                          formData?.tip_amount_option_3
                          ? 'Please use a unique amount'
                          : true
                      }
                    />

                    <TippingAmountFormField
                      name="tip_amount_option_3"
                      defaultValue={formData?.tip_amount_option_3}
                      onChange={setDataOnChange('tip_amount_option_3')}
                      variant={formData?.tipping_variant}
                      validate={() =>
                        formData?.tip_amount_option_3 ===
                          formData?.tip_amount_option_1 ||
                        formData?.tip_amount_option_3 ===
                          formData?.tip_amount_option_2
                          ? 'Please use a unique amount'
                          : true
                      }
                    />
                  </Flex>

                  <Typography color="grays-mid">
                    Customers will have the option to leave a custom tip amount.
                  </Typography>
                </>
              )}
            </>
          )}
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
