import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import type { CustomLineageLabelsV2 } from '@jane/business-admin/types';
import {
  Button,
  Flex,
  Modal,
  RadioFieldGroup,
  Typography,
} from '@jane/shared/reefer';

import type { ConditionType } from '../../form';
import { EXCLUDE_TYPES, FORM_FIELD_DEFAULT_MARGIN } from '../../form';

interface Props {
  conditions: string[];
  disableAddButton?: boolean;
  fieldPrefix: string;
  lastCondition?: boolean;
  onConditionsUpdate: (conditions: string[], type?: any) => void;
  type: ConditionType.Lineage;
}

/**
 * Component for each condition line item that only allows one selection in building conditions for a Special.
 * This particular component can handle Lineage
 *
 * @param conditions: Simple list of conditions to display, values from a special should be mapped to match a simple conditions array
 * @param disableAddButton: Disable the "Add Condition" and "Select (condition)" buttons, only allows for removing any existing conditions
 * @param fieldPrefix: Used for input name fields, to prevent duplicates when there are two conditions cards in the case of BOGO specials
 * @param onConditionsUpdate: Callback when item is removed by clicking "X" icon, or user clears all by clicking "Remove condition"
 * @param type: Type of condition
 * @param lastCondition: Removes bottom border if true
 */
export const ConditionByRadio = ({
  conditions: conditionsProp,
  disableAddButton,
  fieldPrefix,
  onConditionsUpdate,
  type,
  lastCondition,
}: Props) => {
  const {
    storeSettings,
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { isDisabled: globalSpecialDisabled } = useContext(
    GlobalSpecialsModalContext
  );
  const [expanded, setExpanded] = useState(!!conditionsProp.length);
  const [conditions, setConditions] = useState(conditionsProp);

  useEffect(() => {
    setExpanded(!!conditionsProp.length);
    setConditions(conditionsProp);
  }, [conditionsProp]);

  // TODO: Move to utils since Product DetailsCard also uses it
  const lineageOptions = (custom_lineage_labels: CustomLineageLabelsV2) => {
    const {
      indica = 'Indica',
      sativa = 'Sativa',
      hybrid = 'Hybrid',
      cbd = 'CBD',
    } = custom_lineage_labels;
    const baseLineageOptions = [
      {
        id: 'indica',
        value: 'indica',
        label: indica,
      },
      {
        id: 'sativa',
        value: 'sativa',
        label: sativa,
      },
      {
        id: 'hybrid',
        value: 'hybrid',
        label: hybrid,
      },
      {
        id: 'cbd',
        value: 'cbd',
        label: cbd,
      },
    ];

    return baseLineageOptions;
  };
  const options = useMemo(() => {
    return lineageOptions(
      storeSettings?.custom_labels || {
        indica: 'Indica',
        sativa: 'Sativa',
        hybrid: 'Hybrid',
        cbd: 'CBD',
        none: 'None',
      }
    );
  }, [type, storeSettings?.custom_labels]);

  useEffect(() => {
    setConditions(conditionsProp);
  }, [conditionsProp]);

  const removeCondition = () => {
    setConditions([]);
    setExpanded(false);

    onConditionsUpdate([]);
  };

  const onRadioChange = (value: any) => {
    setConditions([value]);
    onConditionsUpdate([value]);
  };

  const isAnyPosSynced = isJanePosSynced || posSynced;
  const isDisabled = isAnyPosSynced || isReadOnly || globalSpecialDisabled;

  const conditionButtonTestId = `${expanded ? 'remove' : 'add'}-${type.replace(
    / /g,
    '-'
  )}-radio-condition`;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb={FORM_FIELD_DEFAULT_MARGIN}
      >
        <Typography variant="header">{capitalize(type)}</Typography>
        <Flex gap={24}>
          <Button
            label={expanded ? 'Remove condition' : 'Add condition'}
            onClick={() => {
              expanded ? removeCondition() : setExpanded(true);
            }}
            variant={
              expanded
                ? 'tertiary'
                : EXCLUDE_TYPES.includes(type)
                ? 'destructive-secondary'
                : 'secondary'
            }
            disabled={(disableAddButton && !expanded) || isDisabled}
            data-testid={conditionButtonTestId}
          />
        </Flex>
      </Flex>
      {expanded && (
        <>
          <Flex gap={12} flexWrap="wrap">
            <RadioFieldGroup
              name={`${fieldPrefix}.includes.lineage`}
              options={options}
              onChange={(value) => onRadioChange(value)}
              row
              defaultChecked={
                options.find((option) => option.value === conditions[0])?.id
              }
            />
          </Flex>
          {!lastCondition && <Modal.ContentDivider />}
        </>
      )}
    </>
  );
};
