import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

export const DiscountTypeAndDiscountAmountField = ({
  isCreateMode = false,
}: {
  isCreateMode: boolean;
}) => {
  const {
    posSyncMap: { isJanePosSynced, posSynced },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { getValues, trigger } = useFormContext();
  const discountTypeValue = getValues('discount_type');
  const specialTypeValue = getValues('special_type');
  const discountAmountValue = getValues(`discount_${discountTypeValue}`);

  const specialTypeWatch = useWatch({ name: 'special_type' });

  useEffect(() => {
    // Need to run validation if the user selects Dollars or Target Price first
    // then enters in a value > 100 and switches to Percentage
    if (discountTypeValue === 'percent') trigger('discount_percent');
  }, [discountTypeValue]);

  const discountTypes = () => {
    const commonValues = [
      { label: 'Percentage (%)', value: 'percent' },
      { label: 'Dollars ($)', value: 'dollar_amount' },
    ];

    const targetPriceValue = { label: 'Target Price', value: 'target_price' };

    const specialType = specialTypeWatch || specialTypeValue;

    if (['product', 'bundle', 'bulk_pricing'].includes(specialType)) {
      return commonValues.concat([targetPriceValue]);
    }

    return commonValues;
  };
  const showInputs = (isJanePosSynced || !posSynced) && !isReadOnly;

  return (
    <Flex gap={24} justifyContent="space-between" width="100%">
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <Form.SelectField
          name="discount_type"
          label="Discount type"
          options={discountTypes()}
          required
          width="50%"
        />
      ) : (
        <Flex width="50%" flexDirection="column">
          <Typography color="grays-black" variant="body-bold">
            Discount type
          </Typography>
          <Typography color="grays-black" variant="body" mt={4} as="p">
            {
              discountTypes().find((type) => type.value === discountTypeValue)
                ?.label
            }
          </Typography>
        </Flex>
      )}
      {isCreateMode || (!isCreateMode && showInputs) ? (
        <Form.NumberField
          name={`discount_${discountTypeValue}`}
          label="Discount amount"
          required
          width="50%"
          min={0}
          startUnit={
            ['dollar_amount', 'target_price'].includes(discountTypeValue)
              ? '$'
              : undefined
          }
          endUnit={discountTypeValue === 'percent' ? '%' : undefined}
          validate={(value: any) => {
            return discountTypeValue === 'percent' && value > 100
              ? 'Discount amount cannot exceed 100'
              : true;
          }}
        />
      ) : (
        <Flex width="50%" flexDirection="column">
          <Typography color="grays-black" variant="body-bold">
            Discount amount
          </Typography>
          <Typography color="grays-black" variant="body" mt={4}>
            {discountTypeValue === 'percent'
              ? `${discountAmountValue}%`
              : `$${discountAmountValue}`}
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};
