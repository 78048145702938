import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { StoreFiltersContext } from '@jane/business-admin/providers';
import type { AbbreviatedStoreV2 } from '@jane/business-admin/types';
import { EventNames, FilterNames, track } from '@jane/business-admin/util';
import type { PopoverContextProps } from '@jane/shared/reefer';
import { Button, ChevronDownIcon, Flex, Popover } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

export const StoreStateFilter = ({
  setStateSearch,
  stateSearch,
}: {
  setStateSearch: (state: string) => void;
  stateSearch: string;
}) => {
  // Only track if user has interacted with the filter
  const hasBeenChanged = useRef(false);
  const { filteredStores, isFetchingStores, stateFilter, setStateFilter } =
    useContext(StoreFiltersContext);

  const initialStores = useMemo(() => {
    if (!isFetchingStores) {
      return filteredStores;
    } else {
      return [];
    }
  }, [isFetchingStores]);

  const changeStateFilter = useCallback(
    (state: string, closePopover: () => void) => {
      if (state && state !== stateFilter) {
        track({
          all_selected_values: [state.toLowerCase()],
          event: EventNames.SelectedFilter,
          filter_name: FilterNames.StoreState,
          selected_value: state.toLowerCase(),
          url: '/stores',
        });
      }
      hasBeenChanged.current = true;

      setStateFilter(state);
      closePopover();
    },
    [stateFilter]
  );

  useEffect(() => {
    // Track reset all filters from "Clear filters" button
    if (stateFilter === '' && hasBeenChanged.current) {
      track({
        all_selected_values: [],
        deselected_value: 'all',
        event: EventNames.DeselectedFilter,
        filter_name: FilterNames.StoreState,
        url: '/stores',
      });
    }
  }, [stateFilter]);

  const states: string[] = useMemo(() => {
    const states = initialStores
      .map((store: AbbreviatedStoreV2) => store.state)
      .filter(
        (state: string | null) =>
          state?.toLowerCase().indexOf(stateSearch) !== -1
      );
    return compact(uniq<string | null>(states)).sort();
  }, [initialStores, stateSearch]);

  return (
    <Popover
      target={
        <Flex>
          <Button
            full
            variant="tertiary"
            label={stateFilter || 'All states'}
            endIcon={<ChevronDownIcon size="sm" />}
          ></Button>
        </Flex>
      }
      ml={16}
      mr={24}
      label="All states"
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="Store state filter" padding={false}>
          <Form.TextField
            autoFocus={true}
            labelHidden={true}
            placeholder="Search"
            label="Search"
            name="state-search"
            type="search"
            onChange={setStateSearch}
            mx={16}
            mt={16}
          />
          <Form.RadioFieldGroup
            name="state-list"
            defaultChecked={stateFilter}
            onChange={(state: string) => changeStateFilter(state, closePopover)}
            options={states.map((state, i) => ({
              id: state,
              label: state,
              value: state,
              wrapper: (children: React.ReactNode) => (
                <Flex
                  mx={16}
                  mt={16}
                  mb={i === states.length - 1 ? 16 : undefined}
                  ariaLabel={`${state}-label`}
                  onClick={() => changeStateFilter(state || '', closePopover)}
                >
                  {children}
                </Flex>
              ),
            }))}
          />
        </Popover.Content>
      )}
    </Popover>
  );
};
