import { Fragment, useEffect } from 'react';

import type { StatusMessageV2 } from '@jane/shared/models';
import { Modal } from '@jane/shared/reefer';
import { useFieldArray, useFormContext } from '@jane/shared/reefer-hook-form';

import { StatusMessageFormFields } from './StatusMessageFormFields';
import type { NotificationSettingsForm } from './form';
import { STATUS_MESSAGES_FIELD } from './form';

interface Props {
  messages: StatusMessageV2[];
}

export const StatusMessageSettings = ({ messages }: Props) => {
  const { control, setValue } = useFormContext<NotificationSettingsForm>();
  const { fields: statusMessages } = useFieldArray({
    control,
    name: STATUS_MESSAGES_FIELD,
  });

  useEffect(() => {
    setValue(STATUS_MESSAGES_FIELD, messages);
  }, [setValue, messages]);

  return (
    <>
      {statusMessages.map((message, index) => (
        <Fragment key={message.id}>
          <StatusMessageFormFields message={message} index={index} />
          {index !== statusMessages.length - 1 && <Modal.ContentDivider />}
        </Fragment>
      ))}
    </>
  );
};
