import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
  useCreateJanePrinter,
  useDeleteJanePrinter,
  useStores,
  useUpdateJanePrinter,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useModalActionsWithTracking,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type {
  JanePrinterStore,
  JanePrinterV2,
} from '@jane/business-admin/types';
import {
  EventNames,
  ModalNames,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  ConfirmDeleteModal,
  ConfirmDiscardWrapper,
} from '@jane/shared/components';
import { Button, Flex, Modal, useToast } from '@jane/shared/reefer';
import {
  Form,
  FormValidationError,
  useForm,
} from '@jane/shared/reefer-hook-form';

import { DetailSection } from './DetailSection';
import { StoreSection } from './StoreSection';

const FORM_ERROR_NAME = 'printers-error';
const FORM_NAME = 'Printers form';

export const PrinterModal = ({
  janePrinter,
  open,
  onClose,
}: {
  janePrinter?: JanePrinterV2 | null;
  onClose: () => void;
  open: boolean;
}) => {
  const isCreateMode = !janePrinter;
  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error ${isCreateMode ? 'creating' : 'updating'} printer. Please try again.`
  );

  const { modalOpen, openModal, closeModal } = useModalActionsWithTracking(
    ModalNames.PrinterStoreSelector
  );

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [updatedJanePrinterStores, setUpdatedJanePrinterStores] = useState(
    janePrinter?.store_jane_printers?.map(
      (printerStore) => printerStore.store
    ) || []
  );
  const { storeId } = useContext(StoreDetailsContext);
  const toast = useToast();
  const formMethods = useForm();
  const {
    clearErrors,
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const {
    mutateAsync: updateJanePrinter,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    isLoading: isSavingUpdate,
  } = useUpdateJanePrinter(storeId, janePrinter?.id || '');
  const {
    mutateAsync: createJanePrinter,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    isLoading: isSavingCreate,
  } = useCreateJanePrinter(storeId);
  const {
    mutateAsync: deleteJanePrinter,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isLoading: isDeleting,
  } = useDeleteJanePrinter(storeId);

  useMutationStatusToasts({
    isMutating: isDeleting,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    successMessage: 'Printer deleted',
    errorMessage: 'Error deleting printer. Please try again.',
  });
  useMutationStatusToasts({
    isMutating: isSavingCreate,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    successMessage: 'Printer created',
    errorMessage: 'Error creating printer. Please try again.',
  });
  useMutationStatusToasts({
    isMutating: isSavingUpdate,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    successMessage: 'Printer updated',
    errorMessage: 'Error updating printer. Please try again.',
  });

  const {
    data: storesData,
    isFetching: isFetchingStores,
    isLoading: isLoadingStores,
  } = useStores();

  const selectedStoreIds = useMemo(() => {
    return updatedJanePrinterStores.map((store) => store.id.toString());
  }, [updatedJanePrinterStores]);

  useEffect(() => {
    if (isUpdateSuccess || isUpdateError || isDeleteSuccess) {
      closeModal();
    }
  }, [isUpdateSuccess, isUpdateError, isDeleteSuccess]);

  const isSuccess = useMemo(
    () =>
      (isCreateMode && isCreateSuccess) ||
      (!isCreateMode && isUpdateSuccess) ||
      (!isCreateMode && isDeleteSuccess),
    [isUpdateSuccess, isCreateSuccess, isCreateMode, isDeleteSuccess]
  );

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = useCallback(
    (formData: JanePrinterV2) => {
      const updatedStoreIds = updatedJanePrinterStores.map((store) => store.id);
      clearErrors(FORM_ERROR_NAME);

      const requestData = {
        ...formData,
        store_ids: updatedStoreIds as number[],
      };

      const trackSubmit = (eventProps = {}) => {
        track({
          event: EventNames.EditedPrinter,
          action: isCreateMode ? 'create' : 'update',
          modal_name: isCreateMode
            ? ModalNames.AddPrinter
            : ModalNames.EditPrinter,
          changed_attributes: Object.keys(dirtyFields),
          ...eventProps,
        });
      };

      const submitMethod = async () => {
        await (isCreateMode
          ? createJanePrinter(requestData)
          : updateJanePrinter(requestData));
        trackSubmit();
      };

      return catchSubmitErrors({
        submitMethod,
        requestData: formData,
        onValidationError: (validationErrors: Record<string, unknown>) => {
          throw new FormValidationError(
            FORM_ERROR_NAME,
            parseValidationErrors(validationErrors)
          );
        },
        callback: () => {
          toast.add({
            label: `Error ${
              isCreateMode ? 'creating' : 'updating'
            } printer. Please try again.`,
            variant: 'error',
            time: 2500,
          });
        },
      });
    },
    [dirtyFields, updatedJanePrinterStores]
  );

  const onDelete = () => {
    setConfirmDeleteModalOpen(true);
  };

  const onDeleteConfirm = () => {
    setConfirmDeleteModalOpen(false);

    track({
      action: 'delete',
      event: EventNames.EditedPrinter,
      changed_attributes: [],
      modal_name: ModalNames.EditPrinter,
    });

    return catchSubmitErrors({
      submitMethod: () => deleteJanePrinter(janePrinter?.id || ''),
      requestData: {},
      onValidationError: () => null,
      callback: () => {
        toast.add({
          label: 'Error deleting printer. Please try again.',
          variant: 'error',
          time: 2500,
        });
      },
    });
  };

  const onUpdateStoresSubmit = async (updatedStoreIds: number[]) => {
    const updatedStores = storesData?.filter((store) =>
      updatedStoreIds.includes(store.id as number)
    );
    setUpdatedJanePrinterStores(updatedStores as JanePrinterStore[]);
    closeModal();
  };

  return (
    <>
      <ConfirmDiscardWrapper
        variant="full-screen"
        open={open}
        setOpen={onClose}
        hasChanges={isDirty}
      >
        <Form.BaseForm
          name={FORM_NAME}
          formMethods={formMethods}
          onSubmit={onSubmit}
          formErrorName={FORM_ERROR_NAME}
        >
          <Modal.Header
            title={`${isCreateMode ? 'Create' : 'Edit'} printer`}
            actions={
              <>
                {!isCreateMode && (
                  <Button
                    label="Delete"
                    variant="destructive"
                    onClick={() => onDelete()}
                    mr={16}
                  />
                )}
                <Form.SubmitButton
                  variant="primary"
                  label="Save"
                  loading={isSavingCreate || isSavingUpdate}
                />
              </>
            }
          />
          <Modal.Content>
            <Form.ErrorBanner name={FORM_ERROR_NAME} />
            <Flex alignItems="center" flexDirection="column">
              <DetailSection janePrinter={janePrinter} />
              <StoreSection
                stores={updatedJanePrinterStores}
                openSaveToModal={() => openModal()}
              />
            </Flex>
          </Modal.Content>
        </Form.BaseForm>
      </ConfirmDiscardWrapper>

      {modalOpen && (
        <StoreSelectModal
          storesData={storesData || []}
          isFetchingStores={isFetchingStores}
          isLoadingStores={isLoadingStores}
          onSubmit={onUpdateStoresSubmit}
          closeModal={() => closeModal()}
          submitButtonType={SUBMIT_BUTTON_VARIANTS['save']}
          selectedStoreIds={selectedStoreIds}
        />
      )}

      {confirmDeleteModalOpen && (
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          setOpen={setConfirmDeleteModalOpen}
          confirmDelete={onDeleteConfirm}
        />
      )}
    </>
  );
};
