import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';

import { Flex, Grid } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { HourAndMinuteSelector } from './HourAndMinuteSelector';

export const WindowSettingsSection = ({
  windowsByDay,
  fulfillmentType,
  windowMinutes,
  intervalMinutes,
}: {
  fulfillmentType: string;
  intervalMinutes: number;
  windowMinutes: number;
  windowsByDay: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const fulfillmentWindowOptions = [
    { label: 'minutes', value: 'minutes' },
    { label: 'day', value: 'day' },
  ];
  const defaultWindowBy = windowsByDay ? 'day' : 'minutes';

  const formWindowBy = watch('display.display_windows_by_day');

  useEffect(() => {
    // Basically a hidden input to track this boolean
    setValue('display_windows_by_day', windowsByDay);
  }, [windowsByDay]);

  const updateDisplayWindowsByDay = (selectedOption: string) => {
    setValue('display_windows_by_day', selectedOption === 'day');
  };

  useEffect(() => {
    // Hidden input since there are two dropdowns that control one field
    setValue('window_minutes', windowMinutes);
  }, [windowMinutes]);

  const onWindowChange = (value: number) => {
    setValue('window_minutes', value);
  };

  useEffect(() => {
    // Hidden input since there are two dropdowns that control one field
    setValue('interval_minutes', intervalMinutes);
  }, [intervalMinutes]);

  const onIntervalChange = (value: number) => {
    setValue('interval_minutes', value);
  };

  return (
    <Flex mb={24}>
      <Grid.Container spacing={40}>
        <Grid.Item xs={4}>
          <Form.SelectField
            defaultValue={defaultWindowBy}
            options={fulfillmentWindowOptions}
            width="100%"
            name="display.display_windows_by_day"
            label={`${capitalize(fulfillmentType)} window by`}
            onChange={updateDisplayWindowsByDay}
            required
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <HourAndMinuteSelector
            defaultValue={intervalMinutes}
            onDurationChange={onIntervalChange}
            fulfillmentType={fulfillmentType}
            disabled={formWindowBy === 'day'}
            window={false}
          />
        </Grid.Item>
        <Grid.Item xs={4}>
          <HourAndMinuteSelector
            defaultValue={windowMinutes}
            onDurationChange={onWindowChange}
            fulfillmentType={fulfillmentType}
            disabled={formWindowBy === 'day'}
            window={true}
          />
        </Grid.Item>
      </Grid.Container>
    </Flex>
  );
};
