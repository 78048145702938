import capitalize from 'lodash/capitalize';
import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { SpecialsModalContext } from '@jane/business-admin/providers';
import type { StoreSpecialV2, StoreV2 } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT, businessPaths } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  Button,
  ExternalIcon,
  Flex,
  GlobeIcon,
  Modal,
  PosDeviceIcon,
  Skeleton,
  Typography,
} from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { RevertableInputWrapper } from '../../../../RevertableInputWrapper';
import {
  FORM_FIELD_DEFAULT_MARGIN,
  MAX_CUSTOM_BADGE_LENGTH,
  MAX_MESSAGE_LENGTH,
  SPECIAL_TYPES,
  formatDiscountPlaceholder,
} from '../../../../shared/specials/modal/form';
import { DiscountTypeAndDiscountAmountField } from './DiscountTypeAndDiscountAmountField';
import { SpecialTypeCriteria } from './SpecialTypeCriteria';

const gtiStoreIds = [725, 756, 874, 1341, 1531, 6011, 6012, 6013, 6014, 6015];

export const LoadingDetailsCard = () => {
  return (
    <Flex flexDirection="column">
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_status`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_name_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={48} width="100%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_select`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_criteria`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        {Array(2)
          .fill(null)
          .map((_, index) => {
            return (
              <Skeleton animate width="100%" key={`loading_2${index}`}>
                <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="60%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
              </Skeleton>
            );
          })}
      </Flex>
      <Flex mb={20} width="100%">
        <Skeleton animate width="100%" key={`loading_description_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>

      <Modal.ContentDivider />

      <Flex mt={20} mb={40} gap={24} width="100%">
        <Skeleton animate width="100%" key={`loading_reservation_modes`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_terms_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export const DetailsCard = ({
  special,
  isCreateMode,
  isLoading,
}: {
  isCreateMode: boolean;
  isLoading: boolean;
  special?: StoreSpecialV2 | null;
}) => {
  const { storeSettings } = useContext(SpecialsModalContext);
  const store = storeSettings?.store;
  const {
    posSyncMap: { isJanePosSynced, posSynced, posSource },
    isReadOnly,
  } = useContext(SpecialsModalContext);
  const { setValue, watch } = useFormContext();

  const { pathname } = useLocation();
  const pathIncludesDuplicate = pathname.includes('duplicate');

  const hasGlobalSpecialsAccess = useFlag(FLAGS.scGlobalSpecialsBeta);
  const storeLevelStackingEnabled = useFlag(FLAGS.scStackingSettingsPosStores);
  const specialsCustomBadge = useFlag(FLAGS.scSpecialsCustomBadge);
  const spendingThresholdSpecialsEnabled = useFlag(
    FLAGS.scSpendThresholdSpecialsFrontend
  );

  const {
    custom_badge,
    description = '',
    reservation_modes = { delivery: false, pickup: false },
    terms = '',
    special_type = '',
  } = {
    ...special,
  };

  // TODO: maybe update to useWatch()?
  const customBadgeWatch = watch('custom_badge');
  const descriptionWatch = watch('description');
  const specialTypeWatch = watch('special_type');
  const stackingSettingWatch = watch('stacking_setting');

  const {
    discount_dollar_amount,
    discount_percent,
    discount_target_price,
    discount_type,
  } = watch();
  const badgePlaceholder = formatDiscountPlaceholder({
    discount_amount: discount_dollar_amount,
    discount_percent,
    discount_type,
    discount_target_price,
  });

  useEffect(() => {
    const posStackingEnabled =
      storeLevelStackingEnabled && storeSettings?.has_pos_integration;
    const changedDuringEditing =
      !isLoading &&
      stackingSettingWatch !== undefined &&
      stackingSettingWatch !== special?.stacking_setting;
    if (posStackingEnabled && (changedDuringEditing || isCreateMode)) {
      setValue('use_store_stacking_setting', false);
    }
  }, [
    isLoading,
    special,
    stackingSettingWatch,
    storeLevelStackingEnabled,
    storeSettings,
  ]);

  const messageLength = useMemo(
    () =>
      descriptionWatch === ''
        ? 0
        : descriptionWatch?.length ||
          (!isCreateMode && description?.length) ||
          0,
    [descriptionWatch, description]
  );

  const customBadgeLength = useMemo(
    () =>
      customBadgeWatch === ''
        ? 0
        : customBadgeWatch?.length ||
          (!isCreateMode && custom_badge?.length) ||
          0,
    [customBadgeWatch, custom_badge]
  );

  const canStoreUseQualifiedGroups = (store?: StoreV2) => {
    return store?.state !== 'Ohio' || gtiStoreIds.includes(store?.id);
  };

  const specialTypeOptions = (store?: StoreV2) => {
    const commonValues = [
      {
        value: SPECIAL_TYPES.BulkPricing,
        label: 'Bulk Pricing',
      },
      {
        value: SPECIAL_TYPES.Bundle,
        label: 'Buy X Get Y',
      },
      {
        value: SPECIAL_TYPES.Product,
        label: 'Product',
      },
      {
        value: SPECIAL_TYPES.CartTotal,
        label: 'Cart Total',
      },
    ];

    if (spendingThresholdSpecialsEnabled) {
      commonValues.push({
        value: SPECIAL_TYPES.SpendingThreshold,
        label: 'Spend X Get Y',
      });
    }

    if (canStoreUseQualifiedGroups(store) || isReadOnly) {
      commonValues.push({
        value: SPECIAL_TYPES.QualifiedGroup,
        label: 'Qualified Group',
      });
    }

    const selectValue = { value: 'select', label: 'Select' };

    if (isCreateMode) {
      return [selectValue].concat(commonValues);
    }

    return commonValues;
  };

  const formatReservationModes = () => {
    const modes =
      reservation_modes &&
      ['delivery', 'pickup']
        .filter(
          (key) => reservation_modes[key as keyof typeof reservation_modes]
        )
        .map((mode) => capitalize(mode));

    return modes?.join(' and ') || '';
  };

  const showSyncedBanner =
    hasGlobalSpecialsAccess &&
    !isCreateMode &&
    !pathIncludesDuplicate &&
    !special?.store_specific;

  return (
    <>
      <Typography variant="header-bold" mb={FORM_FIELD_DEFAULT_MARGIN}>
        Details
      </Typography>
      {!isCreateMode && posSynced && (
        <Flex mb={FORM_FIELD_DEFAULT_MARGIN} justifyContent="space-between">
          <div>
            <Typography variant="body-bold">Status</Typography>
            <Flex alignItems="center">
              <PosDeviceIcon
                color="purple"
                size="sm"
                mr={4}
                title="Synced from POS"
              />
              <Typography variant="body">
                Synced from {isJanePosSynced ? 'Jane' : posSource} POS
              </Typography>
            </Flex>
          </div>
          {special?.pos_special_link && (
            <Button
              endIcon={<ExternalIcon />}
              variant="tertiary"
              href={special.pos_special_link}
            />
          )}
        </Flex>
      )}
      {isLoading ? (
        <LoadingDetailsCard />
      ) : (
        <>
          {showSyncedBanner && (
            <Flex justifyContent="space-between" mb={FORM_FIELD_DEFAULT_MARGIN}>
              <div>
                <Typography color="grays-black" variant="body-bold">
                  Status
                </Typography>
                <Flex alignItems="center">
                  <GlobeIcon size="sm" mr={4} color="purple" />
                  <Typography>Synced from Global Special</Typography>
                </Flex>
              </div>
              <Button
                label="Edit Global Special"
                endIcon={<ExternalIcon />}
                variant="tertiary"
                href={businessPaths.globalSpecialDetail(
                  special?.id.toString() || ''
                )}
              />
            </Flex>
          )}
          <Typography color="grays-black" variant="body-bold">
            Name
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            {!isReadOnly ? (
              <Form.TextField
                label="Name"
                name="name"
                required
                labelHidden
                // Apply character limit if special is not POS synced
                maxLength={!posSynced ? 40 : undefined}
              />
            ) : (
              <Typography>{special?.title || ''}</Typography>
            )}
          </Flex>
          <Typography color="grays-black" variant="body-bold">
            Special type
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            {(isCreateMode || pathIncludesDuplicate) && !isReadOnly ? (
              <>
                <Form.SelectField
                  required
                  label="Special type"
                  labelHidden
                  name="special_type"
                  options={specialTypeOptions(store)}
                  width={'100%'}
                  validate={(value: string) => {
                    return value !== 'select'
                      ? true
                      : 'Must choose a valid special type';
                  }}
                />

                {/* Needed to get the spacing right */}
                <Flex width="100%">&nbsp;</Flex>
              </>
            ) : (
              <Flex width="50%" flexDirection="column">
                <Typography color="grays-black" variant="body" mt={4} as="p">
                  {
                    specialTypeOptions(store).find(
                      (type) =>
                        type.value === (specialTypeWatch || special_type)
                    )?.label
                  }
                </Typography>
              </Flex>
            )}
          </Flex>
          <Flex gap={24}>
            <SpecialTypeCriteria isCreateMode={isCreateMode} />
          </Flex>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            <DiscountTypeAndDiscountAmountField isCreateMode={isCreateMode} />
          </Flex>
          {specialsCustomBadge && specialTypeWatch === SPECIAL_TYPES.Product && (
            <Flex
              mb={FORM_FIELD_DEFAULT_MARGIN}
              width="100%"
              flexDirection="column"
            >
              <Flex gap={4}>
                <Typography color="grays-black" variant="body-bold">
                  Custom badge title
                </Typography>
                {!isReadOnly && (
                  <Typography color="grays-mid" variant="body">
                    ({customBadgeLength}/{MAX_CUSTOM_BADGE_LENGTH} characters
                    used)
                  </Typography>
                )}
              </Flex>
              {!isReadOnly ? (
                <RevertableInputWrapper
                  name="custom_badge"
                  defaultValue={''}
                  initialValue={special?.custom_badge || customBadgeWatch}
                >
                  <Form.TextField
                    label="Custom badge title"
                    labelHidden
                    name="custom_badge"
                    maxLength={MAX_CUSTOM_BADGE_LENGTH}
                    data-testid="special-custom-badge"
                    placeholder={badgePlaceholder}
                  />
                </RevertableInputWrapper>
              ) : (
                <Typography>{special?.custom_badge || 'N/A'}</Typography>
              )}
            </Flex>
          )}
          <Flex width="100%" flexDirection="column">
            <Flex gap={4}>
              <Typography color="grays-black" variant="body-bold">
                Description
              </Typography>
              {!isReadOnly && (
                <Typography color="grays-mid" variant="body">
                  ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
                </Typography>
              )}
            </Flex>
            {!isReadOnly ? (
              <Form.TextAreaField
                label="Description"
                labelHidden
                name="description"
                width="100%"
                maxLength={MAX_MESSAGE_LENGTH}
                rows={4}
                data-testid="special-description"
              />
            ) : (
              <Typography>{special?.description || 'N/A'}</Typography>
            )}
          </Flex>

          <Modal.ContentDivider />

          <Flex
            width="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography color="grays-black" variant="body-bold">
              Apply to reservation
            </Typography>
            <Flex mb={FORM_FIELD_DEFAULT_MARGIN}>
              {isCreateMode ||
              (!isCreateMode &&
                (isJanePosSynced || !posSynced) &&
                !isReadOnly) ? (
                <Flex gap={12} mt={12}>
                  <Form.CheckboxField
                    name="reservation_modes.delivery"
                    onChange={() => null}
                    label="Delivery"
                  />
                  <Form.CheckboxField
                    name="reservation_modes.pickup"
                    onChange={() => null}
                    label="Pickup"
                  />
                </Flex>
              ) : (
                <Typography>{formatReservationModes() || 'N/A'}</Typography>
              )}
            </Flex>

            <Flex
              width="100%"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Typography color="grays-black" variant="body-bold">
                Can this special be used while other specials are present in the
                cart?
              </Typography>
              {!isReadOnly ? (
                <Flex mt={12} flexDirection="row">
                  <Form.RadioFieldGroup
                    row
                    name="stacking_setting"
                    options={[
                      {
                        id: 'stacking-setting-yes',
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        id: 'stacking-setting-no',
                        label: 'No',
                        value: 'no',
                      },
                      {
                        id: 'stacking-setting-combinable',
                        label: 'Only with other stackable specials',
                        value: 'combinable',
                      },
                    ]}
                  />
                </Flex>
              ) : (
                <Typography mb={24}>
                  {special?.stacking_setting === 'combinable'
                    ? 'Only with other stackable specials'
                    : capitalize(special?.stacking_setting)}
                </Typography>
              )}
            </Flex>

            <Typography color="grays-black" variant="body-bold">
              Terms and conditions
            </Typography>
            <Flex flexDirection="column" width={'100%'}>
              {isCreateMode ||
              (!isCreateMode &&
                (isJanePosSynced || !posSynced) &&
                !isReadOnly) ? (
                <Form.TextAreaField
                  label="Terms and conditions"
                  labelHidden
                  name="terms"
                  width="100%"
                  rows={4}
                />
              ) : (
                <Typography>{terms || 'N/A'}</Typography>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
