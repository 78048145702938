import type { MenuRow } from '@jane/business-admin/types';
import type { SpecialSchedule } from '@jane/shared/models';

import {
  convert12HourTimeTo24HourTime,
  convert24HourTimeTo12HourTime,
} from '../../../../shared/specials/modal/schedule/ScheduleTimeValidation';

export const DEFAULT_ROW_SCHEDULE = {
  enabled_sunday: true,
  enabled_monday: true,
  enabled_tuesday: true,
  enabled_wednesday: true,
  enabled_thursday: true,
  enabled_friday: true,
  enabled_saturday: true,
  enabled_sunday_all_day: true,
  enabled_monday_all_day: true,
  enabled_tuesday_all_day: true,
  enabled_wednesday_all_day: true,
  enabled_thursday_all_day: true,
  enabled_friday_all_day: true,
  enabled_saturday_all_day: true,
  end_time_sunday: null,
  start_time_sunday: null,
  end_time_monday: null,
  start_time_monday: null,
  end_time_tuesday: null,
  start_time_tuesday: null,
  end_time_wednesday: null,
  start_time_wednesday: null,
  end_time_thursday: null,
  start_time_thursday: null,
  end_time_friday: null,
  start_time_friday: null,
  end_time_saturday: null,
  start_time_saturday: null,
};

export const formatSchedule = (schedule: SpecialSchedule) => {
  const formatted = Object.entries(schedule).reduce((acc, [key, value]) => {
    if (key.startsWith('start_time') || key.startsWith('end_time')) {
      acc[key] = convert12HourTimeTo24HourTime(value);
    } else {
      acc[key] = value;
    }

    return acc;
  }, {} as any);

  return formatted;
};

export const hasScheduleOverrides = (
  schedule: SpecialSchedule,
  isCreateMode?: boolean
) => {
  if (isCreateMode) {
    return false;
  }

  const allDayEveryDay =
    schedule.enabled_sunday &&
    schedule.enabled_sunday_all_day &&
    schedule.enabled_monday &&
    schedule.enabled_monday_all_day &&
    schedule.enabled_tuesday &&
    schedule.enabled_tuesday_all_day &&
    schedule.enabled_wednesday &&
    schedule.enabled_wednesday_all_day &&
    schedule.enabled_thursday &&
    schedule.enabled_thursday_all_day &&
    schedule.enabled_friday &&
    schedule.enabled_friday_all_day &&
    schedule.enabled_saturday &&
    schedule.enabled_saturday_all_day;

  const anyScheduleOverrides =
    schedule.end_time_sunday ||
    schedule.end_time_monday ||
    schedule.end_time_tuesday ||
    schedule.end_time_wednesday ||
    schedule.end_time_thursday ||
    schedule.end_time_friday ||
    schedule.end_time_saturday ||
    schedule.start_time_sunday ||
    schedule.start_time_monday ||
    schedule.start_time_tuesday ||
    schedule.start_time_wednesday ||
    schedule.start_time_thursday ||
    schedule.start_time_friday ||
    schedule.start_time_saturday;

  return !allDayEveryDay || anyScheduleOverrides;
};

export const parseSchedule = (row?: MenuRow, isCreateMode?: boolean) => {
  if (isCreateMode) {
    const schedule = {
      enabled_sunday: true,
      enabled_monday: true,
      enabled_tuesday: true,
      enabled_wednesday: true,
      enabled_thursday: true,
      enabled_friday: true,
      enabled_saturday: true,
      enabled_sunday_all_day: true,
      enabled_monday_all_day: true,
      enabled_tuesday_all_day: true,
      enabled_wednesday_all_day: true,
      enabled_thursday_all_day: true,
      enabled_friday_all_day: true,
      enabled_saturday_all_day: true,
      end_time_sunday: '',
      start_time_sunday: '',
      end_time_monday: '',
      start_time_monday: '',
      end_time_tuesday: '',
      start_time_tuesday: '',
      end_time_wednesday: '',
      start_time_wednesday: '',
      end_time_thursday: '',
      start_time_thursday: '',
      end_time_friday: '',
      start_time_friday: '',
      end_time_saturday: '',
      start_time_saturday: '',
      use_store_close_time_for_end: false,
      enabled_date_end_checked: false,
      enabled_date_start_checked: false,
      enabled_date_end: '',
      enabled_date_start: '',
      end_date: '',
      end_time: convert24HourTimeTo12HourTime('00:00'),
      start_date: '',
      start_time: convert24HourTimeTo12HourTime('00:00'),
      use_store_close_time: false,
    };
    return {
      schedule,
      has_schedule_overrides: hasScheduleOverrides(schedule, true),
    };
  }

  const fullEndDate = row?.schedule?.end_time
    ? row?.schedule?.end_time.split(' ')
    : [];
  const fullStartDate = row?.schedule?.start_time
    ? row?.schedule?.start_time.split(' ')
    : [];
  const [endDate, endTime] = fullEndDate;
  const [startDate, startTime] = fullStartDate;

  const schedule = {
    enabled_sunday: row?.schedule?.enabled_sunday || false,
    enabled_monday: row?.schedule?.enabled_monday || false,
    enabled_tuesday: row?.schedule?.enabled_tuesday || false,
    enabled_wednesday: row?.schedule?.enabled_wednesday || false,
    enabled_thursday: row?.schedule?.enabled_thursday || false,
    enabled_friday: row?.schedule?.enabled_friday || false,
    enabled_saturday: row?.schedule?.enabled_saturday || false,
    enabled_sunday_all_day:
      row?.schedule?.enabled_sunday_all_day ||
      (!row?.schedule?.end_time_sunday && !row?.schedule?.start_time_sunday) ||
      false,
    enabled_monday_all_day:
      row?.schedule?.enabled_monday_all_day ||
      (!row?.schedule?.end_time_monday && !row?.schedule?.start_time_monday) ||
      false,
    enabled_tuesday_all_day:
      row?.schedule?.enabled_tuesday_all_day ||
      (!row?.schedule?.end_time_tuesday &&
        !row?.schedule?.start_time_tuesday) ||
      false,
    enabled_wednesday_all_day:
      row?.schedule?.enabled_wednesday_all_day ||
      (!row?.schedule?.end_time_wednesday &&
        !row?.schedule?.start_time_wednesday) ||
      false,
    enabled_thursday_all_day:
      row?.schedule?.enabled_thursday_all_day ||
      (!row?.schedule?.end_time_thursday &&
        !row?.schedule?.start_time_thursday) ||
      false,
    enabled_friday_all_day:
      row?.schedule?.enabled_friday_all_day ||
      (!row?.schedule?.end_time_friday && !row?.schedule?.start_time_friday) ||
      false,
    enabled_saturday_all_day:
      row?.schedule?.enabled_saturday_all_day ||
      (!row?.schedule?.end_time_saturday &&
        !row?.schedule?.start_time_saturday) ||
      false,
    end_time_sunday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_sunday) || '',
    end_time_monday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_monday) || '',
    end_time_tuesday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_tuesday) || '',
    end_time_wednesday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_wednesday) || '',
    end_time_thursday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_thursday) || '',
    end_time_friday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_friday) || '',
    end_time_saturday:
      convert24HourTimeTo12HourTime(row?.schedule?.end_time_saturday) || '',
    start_time_sunday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_sunday) || '',
    start_time_monday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_monday) || '',
    start_time_tuesday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_tuesday) || '',
    start_time_wednesday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_wednesday) || '',
    start_time_thursday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_thursday) || '',
    start_time_friday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_friday) || '',
    start_time_saturday:
      convert24HourTimeTo12HourTime(row?.schedule?.start_time_saturday) || '',
    use_store_close_time_for_end:
      row?.schedule?.use_store_close_time_for_end || false,
  };
  return {
    schedule,
    has_schedule_overrides: hasScheduleOverrides(schedule, false),
    enabled_date_start_checked: fullStartDate.filter(Boolean).length > 1,
    enabled_date_end_checked: fullEndDate.filter(Boolean).length > 1,
    end_date: endDate,
    end_time: convert24HourTimeTo12HourTime(endTime),
    start_date: startDate,
    start_time: convert24HourTimeTo12HourTime(startTime),
    enabled_date_end: '',
    enabled_date_start: '',
    use_store_close_time: row?.schedule?.use_store_close_time_for_end,
  };
};
