import capitalize from 'lodash/capitalize';
import { useMemo, useState } from 'react';

import type { LeadTimeUnit } from '@jane/business-admin/util';
import { updateDurationOnChange } from '@jane/business-admin/util';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';
import { duration } from '@jane/shared/util';

export const HourAndMinuteSelector = ({
  defaultValue,
  onDurationChange,
  handleWindowIntervalTracking,
  fulfillmentType,
  disabled,
  window,
  canSetZeroValue,
}: {
  canSetZeroValue?: boolean;
  defaultValue?: number;
  disabled?: boolean;
  fulfillmentType: string;
  handleWindowIntervalTracking?: (
    settingUnit: string,
    windowOrInterval: string
  ) => void;
  onDurationChange?: (newDurationInMinutes: number) => void;
  window?: boolean;
}) => {
  const {
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const [durationValue, setDurationValue] = useState(
    duration(defaultValue || 0, 'minutes')
  );
  const windowOrInterval = window ? 'window' : 'interval';
  const errorFieldName = `${fulfillmentType}_${windowOrInterval}`;

  const hourOptions = useMemo(
    () =>
      Array(24)
        .fill(null)
        .map((_, index) => ({
          label: `${index} hour${index === 1 ? '' : 's'}`,
          value: index,
        })),
    []
  );
  const minuteOptions = useMemo(
    () =>
      [0, 15, 30, 45].map((option) => ({
        label: `${option} minutes`,
        value: option,
      })),
    []
  );

  const onChange = (unitToUpdate: LeadTimeUnit) => (value: string) => {
    clearErrors(errorFieldName);
    const newDurationInMinutes = updateDurationOnChange(
      unitToUpdate,
      parseInt(value, 10),
      durationValue
    );

    if (!canSetZeroValue && newDurationInMinutes === 0) {
      setError(errorFieldName, {
        message: `Please pick a${
          windowOrInterval === 'window' ? '' : 'n'
        } ${windowOrInterval}.`,
      });
    }
    setDurationValue(duration(newDurationInMinutes, 'minutes'));
    if (onDurationChange) onDurationChange(newDurationInMinutes);

    handleWindowIntervalTracking &&
      handleWindowIntervalTracking(unitToUpdate, windowOrInterval);
  };

  return (
    <>
      <Typography mb={16} color={disabled ? 'grays-light' : 'text-main'}>
        {`${capitalize(fulfillmentType)} ${windowOrInterval}`}
      </Typography>
      <Flex gap={24}>
        <Form.SelectField
          defaultValue={durationValue.hours().toString()}
          label={`${capitalize(fulfillmentType)} ${windowOrInterval} hours`}
          labelHidden
          options={hourOptions}
          disabled={disabled}
          name={`display.${windowOrInterval}_hours`}
          onChange={onChange('hours')}
          width="100%"
          required
        />
        <Form.SelectField
          defaultValue={durationValue.minutes().toString()}
          label={`${capitalize(fulfillmentType)} ${windowOrInterval} minutes`}
          labelHidden
          options={minuteOptions}
          disabled={disabled}
          name={`display.${windowOrInterval}_minutes`}
          onChange={onChange('minutes')}
          width="100%"
          required
        />
      </Flex>
      {errors[errorFieldName] && (
        <Typography color="error" mt={8}>
          {errors[errorFieldName]?.message as string}
        </Typography>
      )}
    </>
  );
};
